import { IonButton, IonCard, IonCardContent, IonCardTitle, IonIcon, IonLabel, IonSegment, IonSegmentButton, IonSelect, IonSelectOption } from "@ionic/react";
import { BASE_URL, pathLiveFaults, districtsPath, faultAreasEndpoint } from '../mapContentComponent';
import { ACCESS_CREDENTIALS } from "../../../pages/Home";
import './toggleComponent.css';
import 'mapbox-gl/dist/mapbox-gl.css';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import * as turf from '@turf/turf'
import { useRef, useEffect } from 'react';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import _ from 'lodash';
import { closeCircle, funnel } from "ionicons/icons";


type toggleProps = {


    selectedArea: any;

    setUpdateNotes: any;

    userDistrictIdArray: any;

    markerArray: any;

    MapThing: any;

    noteToggle: any;

    setPreviousNoteToggle: any;

    previousNoteToggle: any;

    faultToggle: any;

    setPreviousFaultToggle: any;

    setFaultMarkerArray: any;

    previousFaultToggle: any;

    faultMarkerArray: any;

    RLBToggle: any;

    setPreviousRLBToggle: any;

    setOverlayArray: any;

    setCurrentBoundaries: any;

    setBoundariesArray: any;

    previousRLBToggle: any;

    setpropertyNumbers: any;
    setCustomerNumbers: any;

    setVisibleOrHiddenFilter: any;

    setRLBToggle: any;
    setNoteToggle: any;
    setFaultToggle: any;
}


const ToggleComponent: React.FC<toggleProps> = ({


    selectedArea,

    setUpdateNotes,

    userDistrictIdArray,

    markerArray,

    MapThing,

    noteToggle,

    setPreviousNoteToggle,

    previousNoteToggle,

    faultToggle,

    setPreviousFaultToggle,

    setFaultMarkerArray,

    previousFaultToggle,

    faultMarkerArray,

    RLBToggle,

    setPreviousRLBToggle,

    setOverlayArray,

    setCurrentBoundaries,

    setBoundariesArray,

    previousRLBToggle,

    setpropertyNumbers,
    setCustomerNumbers,

    setVisibleOrHiddenFilter,
    setRLBToggle,
    setNoteToggle,
    setFaultToggle

}) => {


    let mapMarkers: any[] = [];
    let faultMarkers: any[] = [];
    

    let key_Container: any;



    const llb = new mapboxgl.LngLatBounds();


    useEffect(() => {
        if (noteToggle === "ON") {
            setPreviousNoteToggle("ON")
        } else if (noteToggle === "OFF") {
            if (previousNoteToggle === "ON") {
                markerArray.forEach((marker: { remove: () => any; }) => marker.remove())
                mapMarkers = []

            }
            setPreviousNoteToggle("OFF")

        }
    }, [noteToggle])


    useEffect(() => {
        if (faultToggle === "ON") {
            setPreviousFaultToggle("ON")
            //Show faults
            setUpdateNotes("Update");
            let currTime = new Date().getTime();
            
            let networks = ["com.sse.ssepd.sepd", "com.sse.ssepd.shepd"]
            let outageTypes = ["unplanned", "planned", "onsite", "resolved"]

            networks.forEach(network => {
                let netId = network.replace(/\./g, '-');
                outageTypes.forEach(type => {
                    if (MapThing.getSource('powertrack-faults-raster-' + netId + '-' + type)) {
                        //map.removeSource('powertrack-faults-raster-' + netId + '-' + type);
                        MapThing.getSource('powertrack-faults-raster-' + netId + '-' + type).tiles = [faultAreasEndpoint + '/gridiview/reporter/info/faultareas/' + network + '/' + type + '/{z}/{x}/{y}.png?ts=' + currTime];
                    }
                    else {
                        MapThing.addSource('powertrack-faults-raster-' + netId + '-' + type, {
                            type: 'raster',
                            tiles: [faultAreasEndpoint + '/gridiview/reporter/info/faultareas/' + network + '/' + type + '/{z}/{x}/{y}.png?ts=' + currTime],
                            tileSize: 512,
                            maxzoom: 7,
                            minzoom: 4
                        });
                    }

                    if (!MapThing.getLayer('powertrack-faults-areas-raster-' + netId + '-' + type)) {
                        MapThing.addLayer({
                            'id': 'powertrack-faults-areas-raster-' + netId + '-' + type,
                            'type': 'raster',
                            'source': 'powertrack-faults-raster-' + netId + '-' + type,
                            'minzoom': 0,
                            'maxzoom': 8
                        });
                    }
                });
            });
            if (!MapThing.getSource('powertrack-faults-vector')) {
                //     map.removeSource('powertrack-faults-vector');
                //     // console.log("firing vector source")
                //     //map.getSource('powertrack-faults-vector').tiles = [faultAreasEndpoint + '/gridiview/reporter/info/faultareas/{z}/{x}/{y}.pbf?ts=' + currTime];
                // }
                // else {
                MapThing.addSource('powertrack-faults-vector', {
                    type: 'vector',
                    tiles: [faultAreasEndpoint + '/gridiview/reporter/info/faultareas/{z}/{x}/{y}.pbf?ts=' + currTime],
                    tileSize: 512,
                    maxzoom: 8,
                    minzoom: 8
                });
            }

            networks.forEach(network => {
                let netId = network.replace(/\./g, '-');
                outageTypes.forEach(type => {

                    let filter = "";
                    let paintOptions = {};

                    switch (type) {
                        case "unplanned":
                            paintOptions = {
                                'fill-color': [
                                    'match',
                                    ['concat',
                                        ['get', 'network'],
                                        '-',
                                        ['get', 'classification']
                                    ],
                                    network + '-' + 'pending',
                                    '#ea4c42', // This means a fault but minimal reports
                                    network + '-' + 'min',
                                    '#f10001', // This means a fault with more reports
                                    network + '-' + 'low',
                                    '#df0001', // Additional reports
                                    network + '-' + 'med',
                                    '#b00002', // Very many
                                    network + '-' + 'high',
                                    '#8a0000', // Mass uptake!
                                    '#000000' // Default
                                ],
                                'fill-opacity': [
                                    'match',
                                    ['concat',
                                        ['get', 'network'],
                                        '-',
                                        ['get', 'classification']
                                    ],
                                    network + '-' + 'pending',
                                    0.5, // This means a fault but minimal reports
                                    network + '-' + 'min',
                                    0.5, // This means a fault with more reports
                                    network + '-' + 'low',
                                    0.5, // Additional reports
                                    network + '-' + 'med',
                                    0.5, // Very many
                                    network + '-' + 'high',
                                    0.5, // Mass uptake!
                                    0 // Default
                                ],
                            }
                            break;
                        case "planned":
                            filter = network + '-' + 'planned';
                            paintOptions = {
                                'fill-color': [
                                    'match',
                                    ['concat',
                                        ['get', 'network'],
                                        '-',
                                        ['get', 'classification']
                                    ],
                                    filter,
                                    '#428aea', //  This means a planned outage
                                    '#000000' // Default
                                ],
                                'fill-opacity': [
                                    'match',
                                    ['concat',
                                        ['get', 'network'],
                                        '-',
                                        ['get', 'classification']
                                    ],
                                    filter,
                                    0.5, //  This means a fault with staff on-site
                                    0 // Default
                                ],
                            }
                            break;
                        case "onsite":
                            filter = network + '-' + 'onsite';
                            paintOptions = {
                                'fill-color': [
                                    'match',
                                    ['concat',
                                        ['get', 'network'],
                                        '-',
                                        ['get', 'classification']
                                    ],
                                    filter,
                                    '#A677A6', //  This means a fault with staff on-site
                                    '#000000' // Default                                   
                                ],
                                'fill-opacity': [
                                    'match',
                                    ['concat',
                                        ['get', 'network'],
                                        '-',
                                        ['get', 'classification']
                                    ],
                                    filter,
                                    0.5, //  This means a fault with staff on-site
                                    0 // Default
                                ],
                            }
                            break;
                        default:
                            paintOptions = {
                                'fill-color': '#000000',
                                'fill-opacity': 0,
                            }
                            break;
                    }

                    if (!MapThing.getLayer('powertrack-faults-areas-vector-' + netId + '-' + type)) {
                        MapThing.addLayer({
                            'id': 'powertrack-faults-areas-vector-' + netId + '-' + type,
                            'type': 'fill',
                            'source': 'powertrack-faults-vector',
                            'source-layer': "postcodes",
                            'paint': paintOptions
                        });
                    }
                });
            });


          

            fetch(pathLiveFaults, {})
                .then(function (response) { return response.json(); })
                .then(function (json) {

                    for (let i = 0; i < json.Faults.length; i++) {

                        const popup = new mapboxgl.Popup({ offset: 25, closeOnClick: true })
                            .setHTML(
                                `
                    <h3>Fault Information</h3>
                    
                
                    <ion-card class='note-Card-Reference' onclick='window.open(&quot;https://powertrack.ssen.co.uk/powertrack#&quot; + &quot;${json.Faults[i].reference}&quot ,&quot;_blank&quot;)'>
                    <h4>Reference</h4> <h4> - </h4> <h4>${json.Faults[i].reference}</h4>
                    </ion-card>
                    
                    
                    

                    <ion-card class='note-Card-Fault'>
                    <h4>Postcode Area</h4> <h4> - </h4> <h4>${json.Faults[i].name}</h4>
                    </ion-card>

                    <ion-card class='note-Card-Fault'>
                    <h4>Estimated Customers Affected</h4> <h4> - </h4> <h4> ${json.Faults[i].affectedCustomerCount} </h4> 
                    </ion-card>

                    <ion-card class='note-Card-Fault'>
                    <h4>Affected Postcodes</h4>  <h4> - </h4>  <h4> ${json.Faults[i].affectedAreas.length} </h4> 
                    </ion-card>
                    `
                            )


                        const el = document.createElement('div');


                        if (typeof json.Faults[i].estimatedArrivalOnSiteTime !== 'undefined') {

                            const date = json.Faults[i].estimatedArrivalOnSiteTime.split("T");

                            let time: any;
                            let dateTimeTemp = date[1];

                            if (dateTimeTemp.includes("+")) {
                                time = dateTimeTemp.split("+")

                            } else if (dateTimeTemp.includes("-")) {
                                time = dateTimeTemp.split("-")
                            }


                            const YMD = date[0].split("-")
                            const HMS = time[0].split(":")

                            let dateTest: any;
                            dateTest = new Date();

                            let dText: any;
                            dText = new Date(Number(YMD[0]), (Number(YMD[1]) - 1), Number(YMD[2]), Number(HMS[0]), Number(HMS[1]), Number(HMS[2]));


                            if (dText.getTime() < dateTest.getTime()) {
                                el.className = 'engineerOnSite';
                            } else if (typeof json.Faults[i].engineerOnSiteTime !== 'undefined') {
                                el.className = 'engineerOnSite';
                            } else {
                                if (json.Faults[i].type == "PSI") {
                                    el.className = 'plannedFaultMarker';
                                } else if (json.Faults[i].type == "HV" || json.Faults[i].type == "LV") {
                                    el.className = 'unplannedFaultMarker';
                                }
                            }
                        } else if (typeof json.Faults[i].engineerOnSiteTime !== 'undefined') {
                            el.className = 'engineerOnSite';
                        } else {
                            if (json.Faults[i].type == "PSI") {
                                el.className = 'plannedFaultMarker';
                            } else if (json.Faults[i].type == "HV" || json.Faults[i].type == "LV") {
                                el.className = 'unplannedFaultMarker';
                            }
                        }



                        let lngLat: any;

                        let faultMarkerVar: any;
                        lngLat = [String(json.Faults[i].longitude), String(json.Faults[i].latitude)];
                        faultMarkerVar = new mapboxgl.Marker({
                            element: el,
                            anchor: 'bottom'
                        })
                            .setLngLat(lngLat)
                            .addTo(MapThing)
                            .setPopup(popup);
                        faultMarkers.push(faultMarkerVar);
                    }
                    setFaultMarkerArray(faultMarkers);
                })


        } else if (faultToggle === "OFF") {
            if (previousFaultToggle === "ON") {
                //Get rid of faults
                let networks = ["com.sse.ssepd.sepd", "com.sse.ssepd.shepd"]
                let outageTypes = ["unplanned", "planned", "onsite", "resolved"]

                faultMarkerArray.forEach((marker: { remove: () => any; }) => marker.remove())

                networks.forEach(network => {
                    let netId = network.replace(/\./g, '-');
                    outageTypes.forEach(type => {
                        MapThing.removeLayer('powertrack-faults-areas-raster-' + netId + '-' + type);
                        MapThing.removeLayer('powertrack-faults-areas-vector-' + netId + '-' + type);
                    })
                })
                networks.forEach(network => {
                    let netId = network.replace(/\./g, '-');
                    outageTypes.forEach(type => {
                        MapThing.removeSource('powertrack-faults-raster-' + netId + '-' + type);

                    })
                })
                MapThing.removeSource('powertrack-faults-vector');

            }
            setPreviousFaultToggle("OFF")
            setUpdateNotes("Update");
        }
    }, [faultToggle])



    useEffect(() => {
        if (RLBToggle === "ON") {
            setPreviousRLBToggle("ON")
            if (faultToggle === "ON") {
                let networks = ["com.sse.ssepd.sepd", "com.sse.ssepd.shepd"]
                let outageTypes = ["unplanned", "planned", "onsite", "resolved"]

                faultMarkerArray.forEach((marker: { remove: () => any; }) => marker.remove())

                networks.forEach(network => {
                    let netId = network.replace(/\./g, '-');
                    outageTypes.forEach(type => {
                        MapThing.removeLayer('powertrack-faults-areas-raster-' + netId + '-' + type);
                        MapThing.removeLayer('powertrack-faults-areas-vector-' + netId + '-' + type);
                    })
                })
                networks.forEach(network => {
                    let netId = network.replace(/\./g, '-');
                    outageTypes.forEach(type => {
                        MapThing.removeSource('powertrack-faults-raster-' + netId + '-' + type);

                    })
                })
                MapThing.removeSource('powertrack-faults-vector');
            }

            key_Container = document.getElementById('key-Container');
            key_Container.style.display = "block";

            let networks = ["ssen-scotland", "ssen-southern"];
            let colours: any = {};
            let darkColours: any = {};
            let stops: Array<Array<string>> = [];
            let darkStops: Array<Array<string>> = [];
            let colorProps = { property: 'block', type: "categorical", stops: stops };
            let path2: any;
            path2 = BASE_URL;

            let blockSet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'Z'];
            // let networks = ["murphypowerdistribution", "ssen-scotland","enw"];

            let blocks = blockSet.length;
            for (let i = 0; i < blocks; i++) {
                let block: string = blockSet[i];
                let hsl = "hsl(" + (256 * i / blocks).toFixed(0) + ",50%,50%)";
                let dhsl = "hsl(" + (256 * i / blocks).toFixed(0) + ",75%,25%)";
                colours[block] = hsl;
                darkColours[block] = dhsl;
                stops.push([block, hsl]);
                darkStops.push([block, dhsl]);
            }
            // Resize map to fit window


            var dno: string;

            for (let i = 0; i < networks.length; i++) {
                dno = networks[i];

                const loc = window.location;
                let path;
                if (loc.hostname == "localhost" || loc.hostname == "127.0.0.1") {
                    // This needs to explicitly point to S3
                    path = "https://ssen-myrotablock-test.opcld.com";
                } else {
                    // Going to assume that it's always on default port for http/https as it should be in production
                    path = loc.protocol + "//" + loc.hostname;
                }
                path += "/partners/tiles/vector/";
                MapThing.addSource(dno + '-tiles', {
                    'type': 'vector',
                    'tiles': [
                        path + dno + '/{z}/{x}/{y}.pbf'
                    ],
                    'minzoom': 4,
                    'maxzoom': 12
                });

                let areaPaint: any = {
                    'fill-color': colorProps,
                    'fill-opacity': [
                        'interpolate',
                        // Set the exponential rate of change to 0.5
                        ['exponential', 0.5],
                        ['zoom'],
                        // When zoom is 10, buildings will be 100% transparent.
                        9,
                        0.95,
                        // When zoom is 18 or higher, buildings will be 100% opaque.
                        17,
                        0.25
                    ]
                };

                MapThing.addLayer(
                    {
                        'id': dno + '-vector-tiles', // Layer ID
                        'type': 'fill',
                        'source': dno + '-tiles', // ID of the tile source created above
                        // Source has several layers. We visualize the one with name 'sequence'.
                        'source-layer': 'rld-block',
                        'layout': {},
                        'paint': areaPaint
                    }
                );
            }
            if (selectedArea === "All") {
                for (let i = 0; i < userDistrictIdArray.length; i++) {
                    MapThing.removeLayer('Layer-' + i);
                    MapThing.removeLayer('outline' + i);
                    MapThing.removeSource(userDistrictIdArray[i] + '-ID');

                }
            } else {
                MapThing.removeLayer('Layer-' + selectedArea);
                MapThing.removeLayer('outline');
                MapThing.removeSource(selectedArea + '-ID');
            }
            if (selectedArea == 'All') {
                for (let i = 0; i < userDistrictIdArray.length; i++) {

                    MapThing.addSource(userDistrictIdArray[i] + '-ID', {
                        'type': 'geojson',
                        'data': BASE_URL + districtsPath + userDistrictIdArray[i]
                    });

                    let areaPaint: any = {
                        'fill-color': 'black',
                        'fill-opacity': [
                            'interpolate',
                            ['exponential', 0.5],
                            ['zoom'],
                            7,
                            0.25,
                            17,
                            0.25
                        ]
                    };

                    MapThing.addLayer(
                        {
                            'id': 'Layer-' + i,
                            'type': 'fill',
                            'source': userDistrictIdArray[i] + '-ID',
                            'layout': {},
                            'paint': areaPaint
                        }
                    );

                    MapThing.addLayer({
                        'id': 'outline' + i,
                        'type': 'line',
                        'source': userDistrictIdArray[i] + '-ID',
                        'layout': {},
                        'paint': {
                            'line-color': 'white',
                            'line-width': 2
                        }
                    });


                }
            } else {
                path2 += districtsPath + selectedArea;
                MapThing.addSource(selectedArea + '-ID', {
                    'type': 'geojson',
                    'data': path2
                });

                let areaPaint: any = {
                    'fill-color': 'black',
                    'fill-opacity': [
                        'interpolate',
                        ['exponential', 0.5],
                        ['zoom'],
                        7,
                        0.25,
                        17,
                        0.25
                    ]
                };

                MapThing.addLayer(
                    {
                        'id': 'Layer-' + selectedArea,
                        'type': 'fill',
                        'source': selectedArea + '-ID',
                        'layout': {},
                        'paint': areaPaint
                    }
                );

                MapThing.addLayer({
                    'id': 'outline',
                    'type': 'line',
                    'source': selectedArea + '-ID',
                    'layout': {},
                    'paint': {
                        'line-color': 'white',
                        'line-width': 2
                    }
                });
            }
            MapThing.on('render', afterChangeComplete); // warning: this fires many times per second!
            let headers = new Headers();
            headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
            let tempBoundariesArray: any;
            tempBoundariesArray = [];
            if (selectedArea !== "All") {
                fetch(path2, { headers: headers })
                    .then(function (response) { return response.json(); })
                    .then(function (json) {
                        const coordinates = json.features[0].geometry.coordinates;
                        setOverlayArray(coordinates);

                        let featureType = json.features[0].geometry.type.toLowerCase();

                        if (featureType == "polygon") {


                            var polygon = turf.polygon([coordinates[0]]);

                            setCurrentBoundaries(polygon);
                        } else if (featureType == "multipolygon") {

                            for (let i = 0; i < coordinates.length; i++) {
                                for (let j = 0; j < coordinates[i][0].length; j++) {
                                    llb.extend(coordinates[i][0][j]);
                                }
                            }
                            MapThing.fitBounds(llb);
                            var multipolygon = turf.multiPolygon([coordinates]);
                            setCurrentBoundaries(multipolygon);
                        }


                    })
            } else {

                const fetchData = async () => {
                    for (let i = 0; i < userDistrictIdArray.length; i++) {
                        let pathTest: any;
                        pathTest = BASE_URL + districtsPath + userDistrictIdArray[i];
                        const response = await fetch(pathTest, { headers: headers }) as any;
                        const json = await response.json();
                        const coordinates = json.features[0].geometry.coordinates;
                        let featureType = json.features[0].geometry.type.toLowerCase();

                        if (featureType == "polygon") {


                            var polygon = turf.polygon([coordinates[0]]);

                            tempBoundariesArray.push(polygon);

                        } else if (featureType == "multipolygon") {
                            for (let i = 0; i < coordinates.length; i++) {
                                for (let j = 0; j < coordinates[i][0].length; j++) {
                                    llb.extend(coordinates[i][0][j]);
                                }
                            }
                            

                            var multipolygon = turf.multiPolygon([coordinates]);
                            tempBoundariesArray.push(multipolygon);
                        }

                        if (i == (userDistrictIdArray.length - 1)) {
                            setBoundariesArray(tempBoundariesArray);
                        }
                    }
                    MapThing.fitBounds(llb);
                }

                fetchData();
            }
            if (faultToggle === "ON") {
                let currTime = new Date().getTime();
               
                let networks = ["com.sse.ssepd.sepd", "com.sse.ssepd.shepd"]
                let outageTypes = ["unplanned", "planned", "onsite", "resolved"]

                networks.forEach(network => {
                    let netId = network.replace(/\./g, '-');
                    outageTypes.forEach(type => {
                        if (MapThing.getSource('powertrack-faults-raster-' + netId + '-' + type)) {
                            //map.removeSource('powertrack-faults-raster-' + netId + '-' + type);
                            MapThing.getSource('powertrack-faults-raster-' + netId + '-' + type).tiles = [faultAreasEndpoint + '/gridiview/reporter/info/faultareas/' + network + '/' + type + '/{z}/{x}/{y}.png?ts=' + currTime];
                        }
                        else {
                            MapThing.addSource('powertrack-faults-raster-' + netId + '-' + type, {
                                type: 'raster',
                                tiles: [faultAreasEndpoint + '/gridiview/reporter/info/faultareas/' + network + '/' + type + '/{z}/{x}/{y}.png?ts=' + currTime],
                                tileSize: 512,
                                maxzoom: 7,
                                minzoom: 4
                            });
                        }

                        if (!MapThing.getLayer('powertrack-faults-areas-raster-' + netId + '-' + type)) {
                            MapThing.addLayer({
                                'id': 'powertrack-faults-areas-raster-' + netId + '-' + type,
                                'type': 'raster',
                                'source': 'powertrack-faults-raster-' + netId + '-' + type,
                                'minzoom': 0,
                                'maxzoom': 8
                            });
                        }
                    });
                });
                if (!MapThing.getSource('powertrack-faults-vector')) {
                    //     map.removeSource('powertrack-faults-vector');
                    //     // console.log("firing vector source")
                    //     //map.getSource('powertrack-faults-vector').tiles = [faultAreasEndpoint + '/gridiview/reporter/info/faultareas/{z}/{x}/{y}.pbf?ts=' + currTime];
                    // }
                    // else {
                    MapThing.addSource('powertrack-faults-vector', {
                        type: 'vector',
                        tiles: [faultAreasEndpoint + '/gridiview/reporter/info/faultareas/{z}/{x}/{y}.pbf?ts=' + currTime],
                        tileSize: 512,
                        maxzoom: 8,
                        minzoom: 8
                    });
                }

                networks.forEach(network => {
                    let netId = network.replace(/\./g, '-');
                    outageTypes.forEach(type => {

                        let filter = "";
                        let paintOptions = {};

                        switch (type) {
                            case "unplanned":
                                paintOptions = {
                                    'fill-color': [
                                        'match',
                                        ['concat',
                                            ['get', 'network'],
                                            '-',
                                            ['get', 'classification']
                                        ],
                                        network + '-' + 'pending',
                                        '#ea4c42', // This means a fault but minimal reports
                                        network + '-' + 'min',
                                        '#f10001', // This means a fault with more reports
                                        network + '-' + 'low',
                                        '#df0001', // Additional reports
                                        network + '-' + 'med',
                                        '#b00002', // Very many
                                        network + '-' + 'high',
                                        '#8a0000', // Mass uptake!
                                        '#000000' // Default
                                    ],
                                    'fill-opacity': [
                                        'match',
                                        ['concat',
                                            ['get', 'network'],
                                            '-',
                                            ['get', 'classification']
                                        ],
                                        network + '-' + 'pending',
                                        0.5, // This means a fault but minimal reports
                                        network + '-' + 'min',
                                        0.5, // This means a fault with more reports
                                        network + '-' + 'low',
                                        0.5, // Additional reports
                                        network + '-' + 'med',
                                        0.5, // Very many
                                        network + '-' + 'high',
                                        0.5, // Mass uptake!
                                        0 // Default
                                    ],
                                }
                                break;
                            case "planned":
                                filter = network + '-' + 'planned';
                                paintOptions = {
                                    'fill-color': [
                                        'match',
                                        ['concat',
                                            ['get', 'network'],
                                            '-',
                                            ['get', 'classification']
                                        ],
                                        filter,
                                        '#428aea', //  This means a planned outage
                                        '#000000' // Default
                                    ],
                                    'fill-opacity': [
                                        'match',
                                        ['concat',
                                            ['get', 'network'],
                                            '-',
                                            ['get', 'classification']
                                        ],
                                        filter,
                                        0.5, //  This means a fault with staff on-site
                                        0 // Default
                                    ],
                                }
                                break;
                            case "onsite":
                                filter = network + '-' + 'onsite';
                                paintOptions = {
                                    'fill-color': [
                                        'match',
                                        ['concat',
                                            ['get', 'network'],
                                            '-',
                                            ['get', 'classification']
                                        ],
                                        filter,
                                        '#A677A6', //  This means a fault with staff on-site
                                        '#000000' // Default                                   
                                    ],
                                    'fill-opacity': [
                                        'match',
                                        ['concat',
                                            ['get', 'network'],
                                            '-',
                                            ['get', 'classification']
                                        ],
                                        filter,
                                        0.5, //  This means a fault with staff on-site
                                        0 // Default
                                    ],
                                }
                                break;
                            default:
                                paintOptions = {
                                    'fill-color': '#000000',
                                    'fill-opacity': 0,
                                }
                                break;
                        }

                        if (!MapThing.getLayer('powertrack-faults-areas-vector-' + netId + '-' + type)) {
                            MapThing.addLayer({
                                'id': 'powertrack-faults-areas-vector-' + netId + '-' + type,
                                'type': 'fill',
                                'source': 'powertrack-faults-vector',
                                'source-layer': "postcodes",
                                'paint': paintOptions
                            });
                        }
                    });
                });


                

                fetch(pathLiveFaults, {})
                    .then(function (response) { return response.json(); })
                    .then(function (json) {

                        for (let i = 0; i < json.Faults.length; i++) {

                            const popup = new mapboxgl.Popup({ offset: 25, closeOnClick: true })
                                .setHTML(
                                    `
								<h3>Fault Information</h3>
						<div>
								<ion-card class='note-Card-Reference' onclick='window.open(&quot;https://powertrack.ssen.co.uk/powertrack#&quot; + &quot;${json.Faults[i].reference}&quot ,&quot;_blank&quot;)'>
									<h4>Reference</h4> <h4> - </h4> <h4>${json.Faults[i].reference}</h4>
								</ion-card>

								</div>

								<ion-card class='note-Card-Fault'>
									<h4>Postcode Area</h4> <h4> - </h4> <h4>${json.Faults[i].name}</h4>
								</ion-card>

								<ion-card class='note-Card-Fault'>
									<h4>Estimated Customers Affected</h4> <h4> - </h4> <h4> ${json.Faults[i].affectedCustomerCount} </h4> 
								</ion-card>

								<ion-card class='note-Card-Fault'>
									<h4>Affected Postcodes</h4>  <h4> - </h4>  <h4> ${json.Faults[i].affectedAreas.length} </h4> 
								</ion-card>
		
								
						`
                                )


                            const el = document.createElement('div');
                            el.className = 'faultMarker';
                            let lngLat: any;
                            let faultMarkerVar: any;
                            lngLat = [String(json.Faults[i].longitude), String(json.Faults[i].latitude)];
                            faultMarkerVar = new mapboxgl.Marker({
                                element: el,
                                anchor: 'bottom'
                            })
                                .setLngLat(lngLat)
                                .addTo(MapThing)
                                .setPopup(popup);
                            faultMarkers.push(faultMarkerVar);
                        }
                        setFaultMarkerArray(faultMarkers);
                    })

            }
        } else if (RLBToggle === "OFF") {
            if (previousRLBToggle === "ON") {
                let networks = ["ssen-scotland", "ssen-southern"];
                let dnoTest: any;
                for (let i = 0; i < networks.length; i++) {
                    dnoTest = networks[i];
                    MapThing.removeLayer(dnoTest + '-vector-tiles');
                    MapThing.removeSource(dnoTest + '-tiles');
                }
            }
            key_Container = document.getElementById('key-Container');
            key_Container.style.display = "none";
            setPreviousRLBToggle("OFF")
        }
    }, [RLBToggle])

    function afterChangeComplete() {
        if (MapThing.loaded()) { return } // still not loaded; bail out.

        let headers = new Headers();
        headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
        let path2: any;
        path2 = BASE_URL;
        if (selectedArea !== "All") {
            path2 = path2 + districtsPath + selectedArea;
            fetch(path2, { headers: headers })
                .then(function (response) { return response.json(); })
                .then(function (json) {

                    let psrCustomers: any;
                    let properties: any;


                    if (json.features[0].properties.psrCustomers.toString().length < 4) {
                        psrCustomers = json.features[0].properties.psrCustomers.toString();
                    } else if (json.features[0].properties.psrCustomers.toString().length === 4) {
                        const result = json.features[0].properties.psrCustomers.toString().match(/.{1,1}/g) || [];
                        psrCustomers = result[0] + ',' + result[1] + result[2] + result[3];
                    } else if (json.features[0].properties.psrCustomers.toString().length === 5) {
                        const result = json.features[0].properties.psrCustomers.toString().match(/.{1,2}/g) || [];
                        psrCustomers = result[0] + ',' + result[1] + result[2];
                    } else if (json.features[0].properties.psrCustomers.toString().length === 6) {
                        const result = json.features[0].properties.psrCustomers.toString().match(/.{1,3}/g) || [];
                        psrCustomers = result[0] + ',' + result[1];
                    } else if (json.features[0].properties.psrCustomers.toString().length === 7) {
                        const result = json.features[0].properties.psrCustomers.toString().match(/.{1,1}/g) || [];
                        psrCustomers = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
                    }

                    if (json.features[0].properties.properties.toString().length < 4) {
                        properties = json.features[0].properties.properties.toString();
                    } else if (json.features[0].properties.properties.toString().length === 4) {
                        const result = json.features[0].properties.properties.toString().match(/.{1,1}/g) || [];
                        properties = result[0] + ',' + result[1] + result[2] + result[3];
                    } else if (json.features[0].properties.properties.toString().length === 5) {
                        const result = json.features[0].properties.properties.toString().match(/.{1,2}/g) || [];
                        properties = result[0] + ',' + result[1] + result[2];
                    } else if (json.features[0].properties.properties.toString().length === 6) {
                        const result = json.features[0].properties.properties.toString().match(/.{1,3}/g) || [];
                        properties = result[0] + ',' + result[1];
                    } else if (json.features[0].properties.properties.toString().length === 7) {
                        const result = json.features[0].properties.properties.toString().match(/.{1,1}/g) || [];
                        properties = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
                    }

                    setpropertyNumbers(properties);
                    setCustomerNumbers(psrCustomers);

                })
        } else {
            let customerNoAll: any;
            customerNoAll = 0;
            let propertyNoAll: any;
            propertyNoAll = 0;
            let fetchesComplete = 0;
            for (let i = 0; i < userDistrictIdArray.length; i++) {
                let pathTest: any;
                pathTest = BASE_URL + districtsPath + userDistrictIdArray[i];
                fetch(pathTest, { headers: headers })
                    .then(function (response) { return response.json(); })
                    .then(function (json) {
                        customerNoAll = customerNoAll + json.features[0].properties.psrCustomers;
                        propertyNoAll = propertyNoAll + json.features[0].properties.properties;
                        fetchesComplete++;
                        if (fetchesComplete == (userDistrictIdArray.length)) {

                            let psrCustomers: any;
                            let properties: any;


                            if (customerNoAll.toString().length < 4) {
                                psrCustomers = customerNoAll.toString();
                            } else if (customerNoAll.toString().length === 4) {
                                const result = customerNoAll.toString().match(/.{1,1}/g) || [];
                                psrCustomers = result[0] + ',' + result[1] + result[2] + result[3];
                            } else if (customerNoAll.toString().length === 5) {
                                const result = customerNoAll.toString().match(/.{1,2}/g) || [];
                                psrCustomers = result[0] + ',' + result[1] + result[2];
                            } else if (customerNoAll.toString().length === 6) {
                                const result = customerNoAll.toString().match(/.{1,3}/g) || [];
                                psrCustomers = result[0] + ',' + result[1];
                            } else if (customerNoAll.toString().length === 7) {
                                const result = customerNoAll.toString().match(/.{1,1}/g) || [];
                                psrCustomers = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
                            }

                            if (propertyNoAll.toString().length < 4) {
                                properties = propertyNoAll.toString();
                            } else if (propertyNoAll.toString().length === 4) {
                                const result = propertyNoAll.toString().match(/.{1,1}/g) || [];
                                properties = result[0] + ',' + result[1] + result[2] + result[3];
                            } else if (propertyNoAll.toString().length === 5) {
                                const result = propertyNoAll.toString().match(/.{1,2}/g) || [];
                                properties = result[0] + ',' + result[1] + result[2];
                            } else if (propertyNoAll.toString().length === 6) {
                                const result = propertyNoAll.toString().match(/.{1,3}/g) || [];
                                properties = result[0] + ',' + result[1];
                            } else if (propertyNoAll.toString().length === 7) {
                                const result = propertyNoAll.toString().match(/.{1,1}/g) || [];
                                properties = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
                            }

                            setpropertyNumbers(properties);
                            setCustomerNumbers(psrCustomers);
                        }
                    })
            }
        }
        MapThing.off('render', afterChangeComplete); // remove this handler now that we're done.
    }


    let filter_Container: any;
    let filter_Button: any;

    function filterClick(visibleOrHidden: number) {
        if (visibleOrHidden === 0) {
            filter_Container = document.getElementById('filter-Container');
            filter_Container.style.display = 'flex'
            filter_Button = document.getElementById('filter-Button')
            filter_Button.style.display = 'none'
            setVisibleOrHiddenFilter(0);
        } else if (visibleOrHidden === 1) {
            filter_Container = document.getElementById('filter-Container');
            filter_Container.style.display = 'none'
            filter_Button = document.getElementById('filter-Button')
            filter_Button.style.display = 'flex'
            setVisibleOrHiddenFilter(1);
        }

    }



    return (
        <>

            <div id='filter-Button'>
                <div className='map-Button-Container'>
                    <IonButton shape='round' onClick={() => filterClick(0)} ><IonIcon slot='icon-only' icon={funnel} /></IonButton>
                    <label className='map-Button-Label'>Filter</label>
                </div>
            </div>
            <div id='filter-Container' style={{ display: 'none', position: 'fixed', right: '0px', top: '0px', left: '0px', justifyContent: 'center', alignContent: 'center', flexWrap: 'wrap', zIndex: '3', alignItems: 'center' }}>
                <div id="modal-Filter" className="modal-Filter" style={{ display: "flex" }}>
                    <div className="modal_content">
                        <div className='note-Input'>
                            <IonCard style={{ background: '#214E71', borderRadius: '15px 15px 0 0', right: '10px', top: '-10px', width: '100%' }}>
                                <h1 className='filter-Header'>  Filters
                                    <div className='filter-Close-Button' id="filter-Close" onClick={() => filterClick(1)} >
                                        <IonIcon slot='icon-only' className='icon-Only' icon={closeCircle} style={{ color: "white", right: '-13px', position: 'absolute' }} />
                                    </div>
                                </h1></IonCard>
                            <div className='note-Layout-Input'>
                                <div className='filter-Segment-Placement'>
                                    <div>
                                        <div style={{ fontSize: '20px', margin: '12px' }}>Rota Load Blocks</div>
                                        <IonSegment value={RLBToggle} onIonChange={(e: any) => setRLBToggle(e.detail.value)} id="segment-Id-1" mode='ios'>
                                            <IonSegmentButton value="OFF">
                                                <IonLabel>Off</IonLabel>
                                            </IonSegmentButton>
                                            <IonSegmentButton value="ON">
                                                <IonLabel>On</IonLabel>
                                            </IonSegmentButton>
                                        </IonSegment>
                                    </div>
                                    <div>
                                        <div style={{ fontSize: '20px', margin: '12px' }}>Notes</div>
                                        <IonSegment value={noteToggle} onIonChange={(e: any) => setNoteToggle(e.detail.value)} id="segment-Id-2" mode='ios'>
                                            <IonSegmentButton value="OFF">
                                                <IonLabel>Off</IonLabel>
                                            </IonSegmentButton>
                                            <IonSegmentButton value="ON">
                                                <IonLabel>On</IonLabel>
                                            </IonSegmentButton>
                                        </IonSegment>
                                    </div>
                                    <div>
                                        <div style={{ fontSize: '20px', margin: '12px' }}>Faults</div>
                                        <IonSegment value={faultToggle} onIonChange={(e: any) => setFaultToggle(e.detail.value)} id="segment-Id-3" mode='ios'>
                                            <IonSegmentButton value="OFF">
                                                <IonLabel>Off</IonLabel>
                                            </IonSegmentButton>
                                            <IonSegmentButton value="ON">
                                                <IonLabel>On</IonLabel>
                                            </IonSegmentButton>
                                        </IonSegment>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}



export default ToggleComponent
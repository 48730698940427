import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCheckbox, IonDatetime, IonDatetimeButton, IonIcon, IonLabel, IonModal, IonSelect, IonSelectOption, IonTextarea } from "@ionic/react";
import { useEffect } from "react";

import './ShowNoteComponent.css';

import { SidebarState, modalState } from "../../../../enums/enums";
import { create } from "ionicons/icons";



type ShowNoteProps = {
    setUpdateNotes: any;

    setSidebarExpanded: any;

    setModalVisibility: any;

    resiliencePartner: any;
    publicUserNotes: any;
    partnersUserNotes: any;
    SSENUserNotes: any;
    publicNonUserNotes: any;
    partnersNonUserNotes: any;
    SSENNonUserNotes: any;
    organisationArray: any;

    setDeleteNoteReference: any;
    setDeleteDateReference: any;


    setDeleteDistrictReference: any;
    setDeleteIDReference: any;
    setDeleteLocationReferenceX: any;
    setDeleteLocationReferenceY: any;



    deleteDistrictReference: any;
    deleteLocationReferenceX: any;
    deleteLocationReferenceY: any;
    deleteDateReference: any;


    setNoteOrg: any;
    noteOrg: any;

    showPublicNoteButtonClick: any;
    setShowPublicNoteButtonClick: any;

    showPartnerNoteButtonClick: any;
    setShowPartnerNoteButtonClick: any;

    showSSENNoteButtonClick: any;
    setShowSSENNoteButtonClick: any;

    showPublicNonUserNoteButtonClick: any;
    setShowPublicNonUserNoteButtonClick: any;

    showPartnerNonUserNoteButtonClick: any;
    setShowPartnerNonUserNoteButtonClick: any;

    showSSENNonUserNoteButtonClick: any;
    setShowSSENNonUserNoteButtonClick: any;

    readableSelectedArea: any;
    setReadableSelectedArea: any;
    userDistrictIdArray: any;
    userDistrictNameArray: any;

    extendedNotesArray: any;
    setExtendedNotesArray: any;

}


const ShowNoteComponent: React.FC<ShowNoteProps> = ({

    setUpdateNotes, setModalVisibility,
    resiliencePartner,
    publicUserNotes, partnersUserNotes,
    SSENUserNotes, publicNonUserNotes,
    partnersNonUserNotes, SSENNonUserNotes,
    organisationArray,
    setDeleteNoteReference, setDeleteDateReference,
    setDeleteDistrictReference, setDeleteIDReference,
    setDeleteLocationReferenceX, setDeleteLocationReferenceY,
    deleteDistrictReference,
    deleteLocationReferenceX, deleteLocationReferenceY,
    deleteDateReference, setNoteOrg, noteOrg,
    showPublicNoteButtonClick,
    setShowPublicNoteButtonClick,

    showPartnerNoteButtonClick,
    setShowPartnerNoteButtonClick,

    showSSENNoteButtonClick,
    setShowSSENNoteButtonClick,

    showPublicNonUserNoteButtonClick,
    setShowPublicNonUserNoteButtonClick,

    showPartnerNonUserNoteButtonClick,
    setShowPartnerNonUserNoteButtonClick,

    showSSENNonUserNoteButtonClick,
    setShowSSENNonUserNoteButtonClick,

    readableSelectedArea,
    setReadableSelectedArea,
    userDistrictIdArray,
    userDistrictNameArray,
    setSidebarExpanded,

    extendedNotesArray,
    setExtendedNotesArray



}) => {

    let modal_Container: any;

    let modal_Show: any;

    let buttonShow: any;
    let buttonEdit: any;
    let buttonDelete: any;


    let segmentActivation: any;
    let segmentActivation2: any;
    let segmentActivation3: any;
    let ionSelectActive: any;


    let noteExtendActivate: any;
    let modal_Show_Line: any;
    let modal_Show_NonUser: any;
    let modal_Show_Line_NonUser: any;



    useEffect(() => {
        if (showPublicNoteButtonClick != 0) { // Use effect for note toggle coming on and affecting the sidebar by adding the notes
            showPublicUserNote(showPublicNoteButtonClick);
            setShowPublicNoteButtonClick(0);
        }
    }, [showPublicNoteButtonClick])


    useEffect(() => {
        if (showPartnerNoteButtonClick != 0) { // Use effect for note toggle coming on and affecting the sidebar by adding the notes
            showPartnerUserNote(showPartnerNoteButtonClick);
            setShowPartnerNoteButtonClick(0);
        }
    }, [showPartnerNoteButtonClick])


    useEffect(() => {
        if (showSSENNoteButtonClick != 0) { // Use effect for note toggle coming on and affecting the sidebar by adding the notes
            showSSENUserNote(showSSENNoteButtonClick);
            setShowSSENNoteButtonClick(0);
        }
    }, [showSSENNoteButtonClick])


    useEffect(() => {
        if (showPublicNonUserNoteButtonClick != 0) { // Use effect for note toggle coming on and affecting the sidebar by adding the notes
            showPublicNoteNonUser(showPublicNonUserNoteButtonClick);
            setShowPublicNonUserNoteButtonClick(0);
        }
    }, [showPublicNonUserNoteButtonClick])


    useEffect(() => {
        if (showPartnerNonUserNoteButtonClick != 0) { // Use effect for note toggle coming on and affecting the sidebar by adding the notes
            showPartnerNoteNonUser(showPartnerNonUserNoteButtonClick);
            setShowPartnerNonUserNoteButtonClick(0);
        }
    }, [showPartnerNonUserNoteButtonClick])


    useEffect(() => {
        if (showSSENNonUserNoteButtonClick != 0) { // Use effect for note toggle coming on and affecting the sidebar by adding the notes
            showSSENNoteNonUser(showSSENNonUserNoteButtonClick);
            setShowSSENNonUserNoteButtonClick(0);
        }
    }, [showSSENNonUserNoteButtonClick])


    function disableButtons() { //Disables all of the buttons on the sidebar, if we split up into smaller components this will need split up
        publicUserNotes.map((function (publicUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + publicUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + publicUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + publicUserNotes.index);
            buttonShow.disabled = true;
            buttonEdit.disabled = true;
            buttonDelete.disabled = true;
        }))

        partnersUserNotes.map((function (partnersUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + partnersUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + partnersUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + partnersUserNotes.index);
            buttonShow.disabled = true;
            buttonEdit.disabled = true;
            buttonDelete.disabled = true;
        }))

        SSENUserNotes.map((function (SSENUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + SSENUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + SSENUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + SSENUserNotes.index);
            buttonShow.disabled = true;
            buttonEdit.disabled = true;
            buttonDelete.disabled = true;
        }))



        publicNonUserNotes.map((function (publicNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + publicNonUserNotes.index);
            buttonShow.disabled = true;
        }))


        partnersNonUserNotes.map((function (partnersNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + partnersNonUserNotes.index);
            buttonShow.disabled = true;
        }))


        SSENNonUserNotes.map((function (SSENNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + SSENNonUserNotes.index);
            buttonShow.disabled = true;
        }))



        segmentActivation = document.getElementById('segment-Id-1');
        segmentActivation.disabled = true;

        segmentActivation2 = document.getElementById('segment-Id-2');
        segmentActivation2.disabled = true;

        segmentActivation3 = document.getElementById('segment-Id-3');
        segmentActivation3.disabled = true;


        ionSelectActive = document.getElementById('ion-select-ID');
        ionSelectActive.disabled = true;

        noteExtendActivate = document.getElementById('chevronDownCircle');
        noteExtendActivate.style.pointerEvents = 'none';

        organisationArray.map(function (organisation: any, index: number) {
            index++;
            noteExtendActivate = document.getElementById("chevronDownCircle" + index);
            noteExtendActivate.style.pointerEvents = 'none';
        })
    }



    function enableButtons() { // Enables all of the sidebar buttons for use
        publicUserNotes.map((function (publicUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + publicUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + publicUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + publicUserNotes.index);
            buttonShow.disabled = false;
            buttonEdit.disabled = false;
            buttonDelete.disabled = false;
        }))

        partnersUserNotes.map((function (partnersUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + partnersUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + partnersUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + partnersUserNotes.index);
            buttonShow.disabled = false;
            buttonEdit.disabled = false;
            buttonDelete.disabled = false;
        }))

        SSENUserNotes.map((function (SSENUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + SSENUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + SSENUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + SSENUserNotes.index);
            buttonShow.disabled = false;
            buttonEdit.disabled = false;
            buttonDelete.disabled = false;
        }))



        publicNonUserNotes.map((function (publicNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + publicNonUserNotes.index);
            buttonShow.disabled = false;
        }))


        partnersNonUserNotes.map((function (partnersNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + partnersNonUserNotes.index);
            buttonShow.disabled = false;
        }))


        SSENNonUserNotes.map((function (SSENNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + SSENNonUserNotes.index);
            buttonShow.disabled = false;
        }))


        segmentActivation = document.getElementById('segment-Id-1');
        segmentActivation.disabled = false;
        segmentActivation2 = document.getElementById('segment-Id-2');
        segmentActivation2.disabled = false;
        segmentActivation3 = document.getElementById('segment-Id-3');
        segmentActivation3.disabled = false;

        ionSelectActive = document.getElementById('ion-select-ID');
        ionSelectActive.disabled = false;

        noteExtendActivate = document.getElementById('chevronDownCircle');
        noteExtendActivate.style.pointerEvents = 'all';

        organisationArray.map(function (organisation: any, index: number) {
            index++;
            noteExtendActivate = document.getElementById("chevronDownCircle" + index);
            noteExtendActivate.style.pointerEvents = 'all';
        })
    }

























    let noteMinimise: any;
    let noteNonUserMinimise: any;
    let addNoteStyleWidth: any;

    let modal_Container_Show: any;
    let modal_Container_Show_NonUser: any;



    function showPublicUserNote(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
        modal_Show = document.getElementById('modal-Show');
        modal_Show_Line = document.getElementById('note-Show-Line');
        modal_Container = document.getElementById('modal-Container-Show');

        if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
            noteMinimise = document.getElementById('note-Test-Perm-Fix');
            noteMinimise.style.height = "72px";
        }



        if (window.innerWidth == 640 || window.innerWidth < 640) {
            addNoteStyleWidth = document.getElementById('modal-header-container-show');
            addNoteStyleWidth.style.width = '100%'
        }



        setUpdateNotes("Update");
        if (modal_Show.style.display == "block") {
            modal_Show.style.display = "none";
            modal_Container.style.display = 'none';
            setModalVisibility(modalState.HIDDEN);
            enableButtons();

            if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                extendedNotesArray.map(function (noteExtender: any) {
                    noteMinimise = document.getElementById(noteExtender);
                    noteMinimise.style.height = "auto";
                })

            }

        } else if (modal_Show.style.display == "none") {
            modal_Show.style.display = "block";
            modal_Container.style.display = 'flex';

            let portrait = window.matchMedia("(orientation: portrait)");

            portrait.addEventListener("change", function (e) {
                if (e.matches) {
                    modal_Container_Show = document.getElementById('modal-Container-Show');
                    modal_Container_Show.style.top = '0';
                } else {
                    modal_Container_Show = document.getElementById('modal-Container-Show');
                    modal_Container_Show.style.top = '-35px';
                }
            })

            setModalVisibility(modalState.SHOWN);
            disableButtons();

            // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

            publicUserNotes.map((function (notesArrayShow: any) {
                if (notesArrayShow.index == noteNumber) {
                    setDeleteNoteReference(notesArrayShow.note_Content);
                    modal_Show_Line.value = notesArrayShow.note_Content;
                    setDeleteDateReference(notesArrayShow.note_Creation);
                    setDeleteDistrictReference(notesArrayShow.note_District);
                    userDistrictIdArray.map(function (districts: any, index: any) {
                        if (districts == notesArrayShow.note_District) {
                            setReadableSelectedArea(userDistrictNameArray[index])
                        }
                    })
                    setDeleteIDReference(notesArrayShow.note_ID);
                    const myArray = notesArrayShow.note_Location.split(",");
                    setDeleteLocationReferenceX(myArray[0]);
                    setDeleteLocationReferenceY(myArray[1].trim())
                }
            }))
        }
    }

    function showPartnerUserNote(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
        modal_Show = document.getElementById('modal-Show');
        modal_Show_Line = document.getElementById('note-Show-Line');
        modal_Container = document.getElementById('modal-Container-Show');

        if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
            noteMinimise = document.getElementById('note-Test-Perm-Fix');
            noteMinimise.style.height = "72px";
        }

        if (window.innerWidth == 640 || window.innerWidth < 640) {
            addNoteStyleWidth = document.getElementById('modal-header-container-show');
            addNoteStyleWidth.style.width = '100%'

        }

        setUpdateNotes("Update");
        if (modal_Show.style.display == "block") {
            modal_Show.style.display = "none";
            modal_Container.style.display = 'none';
            setModalVisibility(modalState.HIDDEN);
            enableButtons();

            if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                extendedNotesArray.map(function (noteExtender: any) {
                    noteMinimise = document.getElementById(noteExtender);
                    noteMinimise.style.height = "auto";
                })

            }

        } else if (modal_Show.style.display == "none") {
            modal_Show.style.display = "block";
            modal_Container.style.display = 'flex';

            let portrait = window.matchMedia("(orientation: portrait)");

            portrait.addEventListener("change", function (e) {
                if (e.matches) {
                    modal_Container_Show = document.getElementById('modal-Container-Show');
                    modal_Container_Show.style.top = '0';
                } else {
                    modal_Container_Show = document.getElementById('modal-Container-Show');
                    modal_Container_Show.style.top = '-35px';
                }
            })

            setModalVisibility(modalState.SHOWN);
            disableButtons();

            // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

            partnersUserNotes.map((function (notesArrayShow: any) {
                if (notesArrayShow.index == noteNumber) {
                    setDeleteNoteReference(notesArrayShow.note_Content);
                    modal_Show_Line.value = notesArrayShow.note_Content;
                    setDeleteDateReference(notesArrayShow.note_Creation);
                    setDeleteDistrictReference(notesArrayShow.note_District);
                    userDistrictIdArray.map(function (districts: any, index: any) {
                        if (districts == notesArrayShow.note_District) {
                            setReadableSelectedArea(userDistrictNameArray[index])
                        }
                    })
                    setDeleteIDReference(notesArrayShow.note_ID);
                    const myArray = notesArrayShow.note_Location.split(",");
                    setDeleteLocationReferenceX(myArray[0]);
                    setDeleteLocationReferenceY(myArray[1].trim())
                }
            }))
        }
    }

    

    function showSSENUserNote(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
        modal_Show = document.getElementById('modal-Show');
        modal_Show_Line = document.getElementById('note-Show-Line');
        modal_Container = document.getElementById('modal-Container-Show');

        if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
            noteMinimise = document.getElementById('note-Test-Perm-Fix');
            noteMinimise.style.height = "72px";
        }

        if (window.innerWidth == 640 || window.innerWidth < 640) {
            addNoteStyleWidth = document.getElementById('modal-header-container-show');
            addNoteStyleWidth.style.width = '100%'

        }


        setUpdateNotes("Update");
        if (modal_Show.style.display == "block") {
            modal_Show.style.display = "none";
            modal_Container.style.display = 'none';
            setModalVisibility(modalState.HIDDEN);
            enableButtons();

            if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                extendedNotesArray.map(function (noteExtender: any) {
                    noteMinimise = document.getElementById(noteExtender);
                    noteMinimise.style.height = "auto";
                })

            }

          


        } else if (modal_Show.style.display == "none") {
            modal_Show.style.display = "block";
            modal_Container.style.display = 'flex';

            let portrait = window.matchMedia("(orientation: portrait)");

            portrait.addEventListener("change", function (e) {
                if (e.matches) {
                    modal_Container_Show = document.getElementById('modal-Container-Show');
                    modal_Container_Show.style.top = '0';
                } else {
                    modal_Container_Show = document.getElementById('modal-Container-Show');
                    modal_Container_Show.style.top = '-35px';
                }
            })

            setModalVisibility(modalState.SHOWN);
            disableButtons();

            // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

            SSENUserNotes.map((function (notesArrayShow: any) {
                if (notesArrayShow.index == noteNumber) {
                    setDeleteNoteReference(notesArrayShow.note_Content);
                    modal_Show_Line.value = notesArrayShow.note_Content;
                    setDeleteDateReference(notesArrayShow.note_Creation);
                    setDeleteDistrictReference(notesArrayShow.note_District);
                    userDistrictIdArray.map(function (districts: any, index: any) {
                        if (districts == notesArrayShow.note_District) {
                            setReadableSelectedArea(userDistrictNameArray[index])
                        }
                    })
                    setDeleteIDReference(notesArrayShow.note_ID);
                    const myArray = notesArrayShow.note_Location.split(",");
                    setDeleteLocationReferenceX(myArray[0]);
                    setDeleteLocationReferenceY(myArray[1].trim())
                }
            }))
        }
    }















    function showPublicNoteNonUser(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
        modal_Show_NonUser = document.getElementById('modal-Show-NonUser');
        modal_Show_Line_NonUser = document.getElementById('note-Show-Line-NonUser');
        modal_Container = document.getElementById('modal-Container-Show');

        if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
            noteMinimise = document.getElementById('note-Test-Perm-Fix');
            noteMinimise.style.height = "72px";
        }

        if (window.innerWidth == 640 || window.innerWidth < 640) {
            addNoteStyleWidth = document.getElementById('modal-header-container-nonuser');
            addNoteStyleWidth.style.width = '100%'

        }


        for (let i = 0; i < organisationArray.length; i++) {
            noteNonUserMinimise = document.getElementById('note-Non-User-' + (i + 1));
            noteNonUserMinimise.style.height = "72px"
        }

        if (modal_Show_NonUser.style.display == "none") {
            modal_Show_NonUser.style.display = "block";
            modal_Container.style.display = 'flex';

            let portrait = window.matchMedia("(orientation: portrait)");

            portrait.addEventListener("change", function (e) {
                if (e.matches) {
                    modal_Container_Show_NonUser = document.getElementById('modal-Show-NonUser');
                    modal_Container_Show_NonUser.style.top = '0';
                } else {
                    modal_Container_Show_NonUser = document.getElementById('modal-Show-NonUser');
                    modal_Container_Show_NonUser.style.top = '-35px';
                }
            })

            setModalVisibility(modalState.SHOWN);
            disableButtons();

            // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

            publicNonUserNotes.map((function (notesArrayShow: any) {
                if (notesArrayShow.index == noteNumber) {
                    setDeleteNoteReference(notesArrayShow.note_Content);
                    modal_Show_Line_NonUser.value = notesArrayShow.note_Content;
                    setDeleteDateReference(notesArrayShow.note_Creation);
                    setDeleteDistrictReference(notesArrayShow.note_District);
                    userDistrictIdArray.map(function (districts: any, index: any) {
                        if (districts == notesArrayShow.note_District) {
                            setReadableSelectedArea(userDistrictNameArray[index])
                        }
                    })
                    setDeleteIDReference(notesArrayShow.note_ID);
                    setNoteOrg(notesArrayShow.note_Organisation);
                    const myArray = notesArrayShow.note_Location.split(",");
                    setDeleteLocationReferenceX(myArray[0]);
                    setDeleteLocationReferenceY(myArray[1].trim())
                }
            }))
        } else if (modal_Show_NonUser.style.display = "block") {
            modal_Show_NonUser.style.display = "none";
            modal_Container.style.display = 'none';


            setModalVisibility(modalState.HIDDEN);
            enableButtons();

            if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                extendedNotesArray.map(function (noteExtender: any) {
                    noteMinimise = document.getElementById(noteExtender);
                    noteMinimise.style.height = "auto";
                })

            }
        }
    }


    function showPartnerNoteNonUser(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
        modal_Show_NonUser = document.getElementById('modal-Show-NonUser');
        modal_Show_Line_NonUser = document.getElementById('note-Show-Line-NonUser');
        modal_Container = document.getElementById('modal-Container-Show');

        if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
            noteMinimise = document.getElementById('note-Test-Perm-Fix');
            noteMinimise.style.height = "72px";
        }

        if (window.innerWidth == 640 || window.innerWidth < 640) {
            addNoteStyleWidth = document.getElementById('modal-header-container-nonuser');
            addNoteStyleWidth.style.width = '100%'

        }

        for (let i = 0; i < organisationArray.length; i++) {
            noteNonUserMinimise = document.getElementById('note-Non-User-' + (i + 1));
            noteNonUserMinimise.style.height = "72px"
        }

        if (modal_Show_NonUser.style.display == "none") {
            modal_Show_NonUser.style.display = "block";
            modal_Container.style.display = 'flex';

            setModalVisibility(modalState.SHOWN);
            disableButtons();

            // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

            partnersNonUserNotes.map((function (notesArrayShow: any) {
                if (notesArrayShow.index == noteNumber) {
                    setDeleteNoteReference(notesArrayShow.note_Content);
                    modal_Show_Line_NonUser.value = notesArrayShow.note_Content;
                    setDeleteDateReference(notesArrayShow.note_Creation);
                    setDeleteDistrictReference(notesArrayShow.note_District);
                    userDistrictIdArray.map(function (districts: any, index: any) {
                        if (districts == notesArrayShow.note_District) {
                            setReadableSelectedArea(userDistrictNameArray[index])
                        }
                    })
                    setDeleteIDReference(notesArrayShow.note_ID);
                    setNoteOrg(notesArrayShow.note_Organisation);
                    const myArray = notesArrayShow.note_Location.split(",");
                    setDeleteLocationReferenceX(myArray[0]);
                    setDeleteLocationReferenceY(myArray[1].trim())
                }
            }))
        } else if (modal_Show_NonUser.style.display = "block") {
            modal_Show_NonUser.style.display = "none";
            modal_Container.style.display = 'none';
            setModalVisibility(modalState.HIDDEN);
            enableButtons();

            if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                extendedNotesArray.map(function (noteExtender: any) {
                    noteMinimise = document.getElementById(noteExtender);
                    noteMinimise.style.height = "auto";
                })

            }
        }
    }


    function showSSENNoteNonUser(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
        modal_Show_NonUser = document.getElementById('modal-Show-NonUser');
        modal_Show_Line_NonUser = document.getElementById('note-Show-Line-NonUser');
        modal_Container = document.getElementById('modal-Container-Show');

        if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
            noteMinimise = document.getElementById('note-Test-Perm-Fix');
            noteMinimise.style.height = "72px";
        }

        if (window.innerWidth == 640 || window.innerWidth < 640) {
            addNoteStyleWidth = document.getElementById('modal-header-container-nonuser');
            addNoteStyleWidth.style.width = '100%'

        }



        for (let i = 0; i < organisationArray.length; i++) {
            noteNonUserMinimise = document.getElementById('note-Non-User-' + (i + 1));
            noteNonUserMinimise.style.height = "72px"
        }



        if (modal_Show_NonUser.style.display == "none") {
            modal_Show_NonUser.style.display = "block";
            modal_Container.style.display = 'flex';

            setModalVisibility(modalState.SHOWN);
            disableButtons();

            // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

            SSENNonUserNotes.map((function (notesArrayShow: any) {
                if (notesArrayShow.index == noteNumber) {
                    setDeleteNoteReference(notesArrayShow.note_Content);
                    modal_Show_Line_NonUser.value = notesArrayShow.note_Content;
                    setDeleteDateReference(notesArrayShow.note_Creation);
                    setDeleteDistrictReference(notesArrayShow.note_District);
                    userDistrictIdArray.map(function (districts: any, index: any) {
                        if (districts == notesArrayShow.note_District) {
                            setReadableSelectedArea(userDistrictNameArray[index])
                        }
                    })
                    setDeleteIDReference(notesArrayShow.note_ID);
                    setNoteOrg(notesArrayShow.note_Organisation);
                    const myArray = notesArrayShow.note_Location.split(",");
                    setDeleteLocationReferenceX(myArray[0]);
                    setDeleteLocationReferenceY(myArray[1].trim())
                }
            }))
        } else if (modal_Show_NonUser.style.display = "block") {
            modal_Show_NonUser.style.display = "none";
            modal_Container.style.display = 'none';
            setModalVisibility(modalState.HIDDEN);
            enableButtons();

            if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                extendedNotesArray.map(function (noteExtender: any) {
                    noteMinimise = document.getElementById(noteExtender);
                    noteMinimise.style.height = "auto";
                })

            }
        }
    }









    return (
        <>
            <div id='modal-Container-Show' className='modal-Container-Show' style={{ display: "none", top: "0" }}>

                <div id="modal-Show" className="modal-Show" style={{ display: "none" }}>
                    <div className="modal_content">
                        <div className='note-Input'>
                            <IonCard className='add-Note-Style' id='modal-header-container-show'><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={create} style={{ color: "white" }} />  View This Note</h1></IonCard>
                            <div className='note-Layout-Input'>
                                <div style={{ display: 'flex' }}>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Area: {readableSelectedArea}</IonCardContent></IonCard>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Note Coordinates: {deleteLocationReferenceX}, {deleteLocationReferenceY} </IonCardContent></IonCard>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Partner: {resiliencePartner}</IonCardContent></IonCard>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Created:  {deleteDateReference}</IonCardContent></IonCard>
                                </div>
                                <IonTextarea placeholder="Enter text" className='note-Input-Line' id='note-Show-Line' readonly={true}>
                                </ IonTextarea>
                                <div className='note-Button-Placement'>
                                    <IonButton className='note-Button-Red' shape="round" onClick={() => showPublicUserNote(0)}>Close</IonButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-Show-NonUser" className="modal-Show-NonUser" style={{ display: "none", top: "0" }}>
                    <div className="modal_content">
                        <div className='note-Input'>
                            <IonCard className='add-Note-Style' id='modal-header-container-nonuser'><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={create} style={{ color: "white" }} />  Show This Note</h1></IonCard>
                            <div className='note-Layout-Input'>
                                <div style={{ display: 'flex' }}>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Area: {readableSelectedArea}</IonCardContent></IonCard>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Note Coordinates: {deleteLocationReferenceX}, {deleteLocationReferenceY} </IonCardContent></IonCard>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Partner: {noteOrg}</IonCardContent></IonCard>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Created:  {deleteDateReference}</IonCardContent></IonCard>
                                </div>
                                <IonTextarea placeholder="Enter text" className='note-Input-Line' id='note-Show-Line-NonUser' readonly={true}> {/*Review for RESILIENCE 142 */}
                                </ IonTextarea>
                                <div className='note-Button-Placement'>
                                    <IonButton className='note-Button-Red' shape="round" onClick={() => showPublicNoteNonUser(0)}>Close</IonButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}



export default ShowNoteComponent
import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCheckbox, IonContent, IonDatetime, IonDatetimeButton, IonHeader, IonIcon, IonLabel, IonModal, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonTextarea, IonTitle } from '@ionic/react';
import { chevronDown, chevronForwardCircle, chevronUp, chevronDownCircle, closeCircleOutline, create, personCircleOutline, timeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { SidebarState, propertyNumbersState, customerNumberState, deleteButtonNumberState, deleteNoteReferenceState, deleteDateReferenceState, PostcodeConfirmedState, scenarioDayState, scenarioTimeState, scenarioStageState, deleteLocationReferenceState, editButtonNumberState, noteSaveCountState, resiliencePartnerState, deleteIDReferenceState, outsideOrInsideBoundryState, updateNotesState, modalState, mouseState } from '../../enums/enums';
import { BASE_URL, pathLiveFaults } from '../mapContentComponent/mapContentComponent';
import { ACCESS_CREDENTIALS } from '../../pages/Home';
import LRDPicker from './LRDComponent/LRDComponent';
import ScenarioComponent from './ScenarioComponents/ScenarioComponent';
import NoteComponent from './NoteComponents/NoteComponent';

type sideBarProps = {

  refreshButton: any;
  setRefreshButton: any;

  resiliencePartner: resiliencePartnerState;
  setResiliencePartner: any;

  setLastSelectedArea: any;
  lastSelectedArea: any;
  organisationArray: any;
  setOrganisationArray: any;
  setOutsideOrInsideBoundry: any;
  setMarkerClickedLocation: any;
  markerClickedLocation: any;
  propertyNumbers: propertyNumbersState;
  sidebarExpanded: SidebarState;
  customerNumbers: customerNumberState;
  scenarioDay: scenarioDayState;
  scenarioTime: scenarioTimeState;
  scenarioStage: scenarioStageState;
  selectedArea: any;

  setmapOrSidebarClick: any;

  scenarioStageView: any;
  setScenarioStageView: any;
  scenarioDayView: any;
  setScenarioDayView: any;
  scenarioTimeView: any;
  setScenarioTimeView: any;


  customerNumbersScenario: any;
  propertyNumbersScenario: any;


  setEnableButtons: any;
  enableButtonsVal: any;

  noteSaveCount: noteSaveCountState;
  setNoteSaveCount: any;

  outsideOrInsideBoundry: outsideOrInsideBoundryState;
  setMouseAppearance: any

  notesArray: any;
  setNotesArray: any;
  notesArrayNonUser: any;
  setNotesArrayNonUser: any;

  publicUserNotes: any;
  setPublicUserNotes: any;
  partnersUserNotes: any;
  setPartnersUserNotes: any;
  SSENUserNotes: any;
  setSSENUserNotes: any;

  publicNonUserNotes: any;
  setPublicNonUserNotes: any;
  partnersNonUserNotes: any;
  setPartnersNonUserNotes: any;
  SSENNonUserNotes: any;
  setSSENNonUserNotes: any;

  setDeleteMarker: any;
  updateNotes: updateNotesState;
  setUpdateNotes: any;

  highlightLastNote: any;
  setHighlightLastNote: any;

  sendScenarioDataFlag: any;
  setSendScenarioDataFlag: any;

  typeOfNoteAction: any;
  setTypeOfNoteAction: any;


  setNoteCancelled: any;

  setAddMarkerToArray: any;

  userDistrictIdArray: any;
  setUserDistrictIdArray: any;

  userDistrictNameArray: any;
  setUserDistrictNameArray: any;


  editButtonNumber: editButtonNumberState;
  setEditButtonNumber: any;

  deleteButtonNumber: deleteButtonNumberState;
  deleteNoteReference: deleteNoteReferenceState;
  deleteDateReference: deleteDateReferenceState;
  deleteLocationReferenceX: deleteLocationReferenceState;
  deleteLocationReferenceY: deleteLocationReferenceState;
  deleteIDReference: deleteIDReferenceState;

  setDeleteButtonNumber: any;
  setDeleteDateReference: any;
  setDeleteNoteReference: any;
  setDeleteLocationReferenceX: any;
  setDeleteLocationReferenceY: any;
  setDeleteIDReference: any;
  deleteDistrictReference: any;
  setDeleteDistrictReference: any;
  noteMarkerType: any;
  setNoteMarkerType: any;
  addNoteAllLocation: any;

  XCoordConfirmed: PostcodeConfirmedState;
  YCoordConfirmed: PostcodeConfirmedState;
  setNoteLocationSelection: any;
  setScenarioDay: any;
  setScenarioTime: any;
  setScenarioStage: any;
  setSelectedArea: any;

  noteToHighlight: any;
  setNoteToHighlight: any;

  setModalVisibility: any;
  modalVisibility: any;

  noteOrg: any;
  setNoteOrg: any;

  mouseAppearance: any;

  timeAndLevelButtons: any;
  setTimeAndLevelButtons: any

  clearScenario: any;
  setClearScenario: any;
  noteToggle: any;
  RLBToggle: any;


  newNoteVisibility: any;
  setNewNoteVisibility: any;

  linkedToFault: any;
  setLinkedToFault: any;

  permNote: any;
  setPermNote: any;

  noteExpiryDate: any;
  setNoteExpiryDate: any;

  faultReferenceToBeSaved: any;
  setFaultReferenceToBeSaved: any;

  noteMouseActive: any;
  setNoteMouseActive: any;

  faultArrayForSave: any;
  sidebarTimer: any;
  setSidebarTimer: any;

  setUpdateTimerNote: any;
  updateTimerNote: any;

  saveNoteButtonClick: any;
  setSaveNoteButtonClick: any;

  editPublicNoteButtonClick: any;
  setEditPublicNoteButtonClick: any;

  editPartnerNoteButtonClick: any;
  setEditPartnerNoteButtonClick: any;

  editSSENNoteButtonClick: any;
  setEditSSENNoteButtonClick: any;



  deletePublicNoteButtonClick: any;
  setDeletePublicNoteButtonClick: any;

  deletePartnerNoteButtonClick: any;
  setDeletePartnerNoteButtonClick: any;

  deleteSSENNoteButtonClick: any;
  setDeleteSSENNoteButtonClick: any;

  showPublicNoteButtonClick: any;
  setShowPublicNoteButtonClick: any;

  showPartnerNoteButtonClick: any;
  setShowPartnerNoteButtonClick: any;

  showSSENNoteButtonClick: any;
  setShowSSENNoteButtonClick: any;

  showPublicNonUserNoteButtonClick: any;
  setShowPublicNonUserNoteButtonClick: any;

  showPartnerNonUserNoteButtonClick: any;
  setShowPartnerNonUserNoteButtonClick: any;

  showSSENNonUserNoteButtonClick: any;
  setShowSSENNonUserNoteButtonClick: any;

  organisationPublicArrayNo: any;
  setOrganisationPublicArrayNo: any;

  organisationPartnerArrayNo: any;
  setOrganisationPartnerArrayNo: any;

  organisationSSENArrayNo: any;
  setOrganisationSSENArrayNo: any;
  readableSelectedArea: any;
  setReadableSelectedArea: any;
  setLoginCheckState: any;

  extendedNotesArray: any;
  setExtendedNotesArray: any;

  setAddNoteButtonFlag: any;
  addNoteButtonFlag: any;

  publicExpiredUserNotes: any;
  setPublicExpiredUserNotes: any;
  partnersExpiredUserNotes: any;
  setPartnersExpiredUserNotes: any;
  SSENExpiredUserNotes: any;
  setSSENExpiredUserNotes: any;



  publicExpiredNonUserNotes: any;
  setPublicExpiredNonUserNotes: any;
  partnersExpiredNonUserNotes: any;
  setPartnersExpiredNonUserNotes: any;
  SSENExpiredNonUserNotes: any;
  setSSENExpiredNonUserNotes: any;


  setSidebarExpanded: (sidebarExpanded: SidebarState) => void;
}

const SideBar: React.FC<sideBarProps> = ({

  propertyNumbers, scenarioDay, scenarioTime, scenarioStage, setScenarioDay, setScenarioTime, setScenarioStage, sidebarExpanded, XCoordConfirmed, YCoordConfirmed, customerNumbers,

  enableButtonsVal, setEnableButtons, sidebarTimer, setSidebarTimer,

  deleteButtonNumber, deleteDateReference, setDeleteDateReference, setDeleteNoteReference, setDeleteButtonNumber, setSelectedArea, setSidebarExpanded,
  setNoteLocationSelection, setDeleteLocationReferenceX, setDeleteLocationReferenceY, deleteLocationReferenceX, deleteLocationReferenceY, deleteIDReference, setDeleteIDReference, selectedArea,

  resiliencePartner, setResiliencePartner, setLastSelectedArea, lastSelectedArea, setNoteCancelled, outsideOrInsideBoundry, setmapOrSidebarClick,

  notesArray, setNotesArray, notesArrayNonUser, updateNotes, setUpdateNotes, deleteDistrictReference, typeOfNoteAction, setTypeOfNoteAction,
  setDeleteDistrictReference, setNoteToHighlight,

  organisationArray, setOrganisationArray, setOutsideOrInsideBoundry, setDeleteMarker, highlightLastNote, setHighlightLastNote,

  markerClickedLocation, setMarkerClickedLocation, noteMarkerType, setNoteMarkerType, setAddMarkerToArray, customerNumbersScenario, propertyNumbersScenario,
  setNoteSaveCount, noteSaveCount, addNoteAllLocation,
  scenarioStageView, setScenarioStageView, scenarioDayView,
  setScenarioDayView, scenarioTimeView, setScenarioTimeView, setEditButtonNumber,
  setUserDistrictIdArray, setUserDistrictNameArray, userDistrictIdArray, userDistrictNameArray,

  setModalVisibility, modalVisibility, noteOrg, setNoteOrg, setMouseAppearance, mouseAppearance, setSendScenarioDataFlag, timeAndLevelButtons,
  setClearScenario, noteToggle, RLBToggle, newNoteVisibility,
  linkedToFault, setLinkedToFault, permNote, setPermNote, noteExpiryDate, setNoteExpiryDate, faultReferenceToBeSaved, setFaultReferenceToBeSaved,
  publicUserNotes, setPublicUserNotes, partnersUserNotes, setPartnersUserNotes, SSENUserNotes, setSSENUserNotes,

  publicNonUserNotes,
  setPublicNonUserNotes,
  partnersNonUserNotes,
  setPartnersNonUserNotes,
  SSENNonUserNotes,
  setSSENNonUserNotes,

  noteMouseActive,
  setNoteMouseActive,
  faultArrayForSave,
  setUpdateTimerNote, setNewNoteVisibility, editButtonNumber, setRefreshButton, refreshButton,


  saveNoteButtonClick,
  setSaveNoteButtonClick,


  editPublicNoteButtonClick,
  setEditPublicNoteButtonClick,

  editPartnerNoteButtonClick,
  setEditPartnerNoteButtonClick,

  editSSENNoteButtonClick,
  setEditSSENNoteButtonClick,



  deletePublicNoteButtonClick,
  setDeletePublicNoteButtonClick,

  deletePartnerNoteButtonClick,
  setDeletePartnerNoteButtonClick,

  deleteSSENNoteButtonClick,
  setDeleteSSENNoteButtonClick,

  showPublicNoteButtonClick,
  setShowPublicNoteButtonClick,

  showPartnerNoteButtonClick,
  setShowPartnerNoteButtonClick,

  showSSENNoteButtonClick,
  setShowSSENNoteButtonClick,

  showPublicNonUserNoteButtonClick,
  setShowPublicNonUserNoteButtonClick,

  showPartnerNonUserNoteButtonClick,
  setShowPartnerNonUserNoteButtonClick,

  showSSENNonUserNoteButtonClick,
  setShowSSENNonUserNoteButtonClick,

  organisationPublicArrayNo,
  setOrganisationPublicArrayNo,

  organisationPartnerArrayNo,
  setOrganisationPartnerArrayNo,

  organisationSSENArrayNo,
  setOrganisationSSENArrayNo,
  readableSelectedArea,
  setReadableSelectedArea,
  setLoginCheckState,

  extendedNotesArray,
  setExtendedNotesArray,
  setAddNoteButtonFlag,
  addNoteButtonFlag,


  publicExpiredUserNotes,
  setPublicExpiredUserNotes,
  partnersExpiredUserNotes,
  setPartnersExpiredUserNotes,
  SSENExpiredUserNotes,
  setSSENExpiredUserNotes,



  publicExpiredNonUserNotes,
  setPublicExpiredNonUserNotes,
  partnersExpiredNonUserNotes,
  setPartnersExpiredNonUserNotes,
  SSENExpiredNonUserNotes,
  setSSENExpiredNonUserNotes


}) => {

  let segmentActivation: any;
  let segmentActivation2: any;
  let segmentActivation3: any;
  let ionSelectActive: any;
  let testValueForLoginButton: any;
  let hoverEventCancel: any;

  useEffect(() => { //This use effect sets up all necessary variables for basic use such as dsiricts user has access to, only obtainable with a log in 
    let pathLogin: any;
    let headersForLogin = new Headers();
    let tempDistrictIDArray: any;
    let tempDistrictNameArray: any;

    tempDistrictIDArray = [];
    tempDistrictNameArray = [];
    pathLogin = BASE_URL + "/gridim/rotaload/partners?action=getUserAccessDetails";


    headersForLogin.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);

    fetch(pathLogin, { headers: headersForLogin })
      .then(function (response) { return response.json(); })
      .then(async function (userDetailsJson) {

        if (userDetailsJson != "") {
          try {
            const response = await fetch(pathLiveFaults);
            testValueForLoginButton = document.getElementById('logout-Button');
            testValueForLoginButton.style.display = 'none';
            setLoginCheckState("Logged In")
          }
          catch (err) {
            // console.log("Error")
            segmentActivation = document.getElementById('segment-Id-1');
            segmentActivation.disabled = true;

            segmentActivation2 = document.getElementById('segment-Id-2');
            segmentActivation2.disabled = true;

            segmentActivation3 = document.getElementById('segment-Id-3');
            segmentActivation3.disabled = true;


            ionSelectActive = document.getElementById('ion-select-ID');
            ionSelectActive.disabled = true;

            alert("Fault fetching is currently down, please contact support about error.");
          }

        }

        setResiliencePartner(userDetailsJson.organisation);
        for (let i = 0; i < userDetailsJson.districts.length; i++) {
          tempDistrictIDArray.push(userDetailsJson.districts[i].id);
          tempDistrictNameArray.push(userDetailsJson.districts[i].name);
        }
        setUserDistrictIdArray(tempDistrictIDArray.sort());
        setUserDistrictNameArray(tempDistrictNameArray.sort());
      })
    setNoteSaveCount(1);
    setScenarioTime("");
    setScenarioDay("");
    setScenarioStage("");
    setScenarioStageView("")
    setScenarioDayView("")
    setScenarioTimeView("")
    setSidebarExpanded(SidebarState.EXPANDED);
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);

  const [mouseDownPos, setmouseDownPos] = useState(0);
  const [mouseUpPos, setmouseUpPos] = useState(0);

  useEffect(() => {
    const mousePosDiff = mouseDownPos - mouseUpPos;
    console.log(modalVisibility)
    if (modalVisibility != "0") {
      if (mousePosDiff < -50) {
        setSidebarExpanded(SidebarState.COLLAPSED);
      } else if (mousePosDiff > 5) {
        setSidebarExpanded(SidebarState.EXPANDED);
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mouseUpPos]
  );
  //The majority of these are used to enable style changes, the others are used to store values before setting them, can be moved but will results in several local veriables instead of these global ones.

  return (
    <>
      <div id="menu-Container" onTouchStart={(e) => { setmouseDownPos(e.touches[0].clientX) }} onTouchEnd={(e) => { setmouseUpPos(e.changedTouches[0].clientX) }}>
        <div className='menu-Toggle' onClick={() => setSidebarExpanded(sidebarExpanded === SidebarState.EXPANDED ? SidebarState.COLLAPSED : SidebarState.EXPANDED)}>
          <IonIcon src={chevronForwardCircle} className={sidebarExpanded === SidebarState.EXPANDED ? "" : "rotate180deg"}></IonIcon>
        </div>
        <IonHeader className='ion-header-class'>
          <div className="menu-Title-Style">
            <img src="assets/images/SSEN_Logo_White.svg"></img>
            <IonTitle class="menu-Logo-Style">Resilience Partner Dashboard</IonTitle>
          </div>
        </IonHeader>
        <IonContent class="menu-contents">



          <LRDPicker
            setResiliencePartner={setResiliencePartner}
            userDistrictIdArray={userDistrictIdArray}
            setUserDistrictIdArray={setUserDistrictIdArray}
            userDistrictNameArray={userDistrictNameArray}
            setUserDistrictNameArray={setUserDistrictNameArray}
            propertyNumbers={propertyNumbers}
            customerNumbers={customerNumbers}
            setSelectedArea={setSelectedArea}
            selectedArea={selectedArea}
            setLastSelectedArea={setLastSelectedArea}
            noteToggle={noteToggle}
            setUpdateNotes={setUpdateNotes}
            setTypeOfNoteAction={setTypeOfNoteAction}
            sidebarTimer={sidebarTimer}
            setSidebarTimer={setSidebarTimer}
            setUpdateTimerNote={setUpdateTimerNote}
            setReadableSelectedArea={setReadableSelectedArea}
          ></LRDPicker>


          <ScenarioComponent
            setScenarioStage={setScenarioStage}
            setScenarioTime={setScenarioTime}
            setScenarioDay={setScenarioDay}
            scenarioDay={scenarioDay}
            scenarioTime={scenarioTime}
            scenarioStage={scenarioStage}
            timeAndLevelButtons={timeAndLevelButtons}

            scenarioDayView={scenarioDayView}
            setScenarioDayView={setScenarioDayView}
            scenarioTimeView={scenarioTimeView}
            setScenarioTimeView={setScenarioTimeView}
            scenarioStageView={scenarioStageView}
            setScenarioStageView={setScenarioStageView}
            setModalVisibility={setModalVisibility}
            propertyNumbersScenario={propertyNumbersScenario}
            customerNumbersScenario={customerNumbersScenario}
            setClearScenario={setClearScenario}
            setSendScenarioDataFlag={setSendScenarioDataFlag}


            RLBToggle={RLBToggle}

            setUpdateNotes={setUpdateNotes}

          ></ScenarioComponent>

          <NoteComponent

            setRefreshButton={setRefreshButton}
            refreshButton={refreshButton}

            noteToggle={noteToggle}
            setOrganisationArray={setOrganisationArray}
            setUpdateNotes={setUpdateNotes}
            setTypeOfNoteAction={setTypeOfNoteAction}


            noteMouseActive={noteMouseActive}
            setMouseAppearance={setMouseAppearance}
            setNoteMouseActive={setNoteMouseActive}



            notesArray={notesArray}
            setNotesArray={setNotesArray}

            organisationArray={organisationArray}
            publicUserNotes={publicUserNotes}
            setPublicUserNotes={setPublicUserNotes}
            partnersUserNotes={partnersUserNotes}
            setPartnersUserNotes={setPartnersUserNotes}
            SSENUserNotes={SSENUserNotes}
            setSSENUserNotes={setSSENUserNotes}
            setModalVisibility={setModalVisibility}
            setNoteCancelled={setNoteCancelled}

            setNoteLocationSelection={setNoteLocationSelection}
            setOutsideOrInsideBoundry={setOutsideOrInsideBoundry}
            outsideOrInsideBoundry={outsideOrInsideBoundry}
            XCoordConfirmed={XCoordConfirmed}
            YCoordConfirmed={YCoordConfirmed}
            setLinkedToFault={setLinkedToFault}
            linkedToFault={linkedToFault}
            permNote={permNote}
            setPermNote={setPermNote}
            faultReferenceToBeSaved={faultReferenceToBeSaved}
            setFaultReferenceToBeSaved={setFaultReferenceToBeSaved}
            noteExpiryDate={noteExpiryDate}
            setNoteExpiryDate={setNoteExpiryDate}
            newNoteVisibility={newNoteVisibility}

            selectedArea={selectedArea}
            lastSelectedArea={lastSelectedArea}
            addNoteAllLocation={addNoteAllLocation}
            noteSaveCount={noteSaveCount}
            setNoteSaveCount={setNoteSaveCount}
            setSelectedArea={setSelectedArea}
            setAddMarkerToArray={setAddMarkerToArray}
            setEditButtonNumber={setEditButtonNumber}

            setDeleteDateReference={setDeleteDateReference}
            deleteDateReference={deleteDateReference}
            setDeleteNoteReference={setDeleteNoteReference}
            deleteDistrictReference={deleteDistrictReference}
            setDeleteDistrictReference={setDeleteDistrictReference}
            setDeleteLocationReferenceX={setDeleteLocationReferenceX}
            deleteLocationReferenceX={deleteLocationReferenceX}
            setDeleteLocationReferenceY={setDeleteLocationReferenceY}
            deleteLocationReferenceY={deleteLocationReferenceY}
            deleteIDReference={deleteIDReference}
            setDeleteIDReference={setDeleteIDReference}
            setDeleteButtonNumber={setDeleteButtonNumber}
            setDeleteMarker={setDeleteMarker}
            deleteButtonNumber={deleteButtonNumber}
            publicNonUserNotes={publicNonUserNotes}
            setPublicNonUserNotes={setPublicNonUserNotes}
            partnersNonUserNotes={partnersNonUserNotes}
            setPartnersNonUserNotes={setPartnersNonUserNotes}
            SSENNonUserNotes={SSENNonUserNotes}
            setSSENNonUserNotes={setSSENNonUserNotes}
            setNoteOrg={setNoteOrg}
            noteOrg={noteOrg}
            modalVisibility={modalVisibility}
            mouseAppearance={mouseAppearance}
            setNoteToHighlight={setNoteToHighlight}
            setNoteMarkerType={setNoteMarkerType}
            setmapOrSidebarClick={setmapOrSidebarClick}
            markerClickedLocation={markerClickedLocation}
            noteMarkerType={noteMarkerType}
            setMarkerClickedLocation={setMarkerClickedLocation}
            highlightLastNote={highlightLastNote}
            setHighlightLastNote={setHighlightLastNote}
            userDistrictIdArray={userDistrictIdArray}
            userDistrictNameArray={userDistrictNameArray}
            resiliencePartner={resiliencePartner}
            updateNotes={updateNotes}
            typeOfNoteAction={typeOfNoteAction}

            faultArrayForSave={faultArrayForSave}

            setSidebarExpanded={setSidebarExpanded}
            sidebarExpanded={sidebarExpanded}

            enableButtonsVal={enableButtonsVal}
            setEnableButtons={setEnableButtons}

            saveNoteButtonClick={saveNoteButtonClick}
            setSaveNoteButtonClick={setSaveNoteButtonClick}

            editPublicNoteButtonClick={editPublicNoteButtonClick}
            setEditPublicNoteButtonClick={setEditPublicNoteButtonClick}

            editPartnerNoteButtonClick={editPartnerNoteButtonClick}
            setEditPartnerNoteButtonClick={setEditPartnerNoteButtonClick}

            editSSENNoteButtonClick={editSSENNoteButtonClick}
            setEditSSENNoteButtonClick={setEditSSENNoteButtonClick}


            deletePublicNoteButtonClick={deletePublicNoteButtonClick}
            setDeletePublicNoteButtonClick={setDeletePublicNoteButtonClick}

            deletePartnerNoteButtonClick={deletePartnerNoteButtonClick}
            setDeletePartnerNoteButtonClick={setDeletePartnerNoteButtonClick}

            deleteSSENNoteButtonClick={deleteSSENNoteButtonClick}
            setDeleteSSENNoteButtonClick={setDeleteSSENNoteButtonClick}
            showPublicNoteButtonClick={showPublicNoteButtonClick}
            setShowPublicNoteButtonClick={setShowPublicNoteButtonClick}

            showPartnerNoteButtonClick={showPartnerNoteButtonClick}
            setShowPartnerNoteButtonClick={setShowPartnerNoteButtonClick}

            showSSENNoteButtonClick={showSSENNoteButtonClick}
            setShowSSENNoteButtonClick={setShowSSENNoteButtonClick}

            showPublicNonUserNoteButtonClick={showPublicNonUserNoteButtonClick}
            setShowPublicNonUserNoteButtonClick={setShowPublicNonUserNoteButtonClick}

            showPartnerNonUserNoteButtonClick={showPartnerNonUserNoteButtonClick}
            setShowPartnerNonUserNoteButtonClick={setShowPartnerNonUserNoteButtonClick}

            showSSENNonUserNoteButtonClick={showSSENNonUserNoteButtonClick}
            setShowSSENNonUserNoteButtonClick={setShowSSENNonUserNoteButtonClick}



            organisationPublicArrayNo={organisationPublicArrayNo} setOrganisationPublicArrayNo={setOrganisationPublicArrayNo}

            organisationPartnerArrayNo={organisationPartnerArrayNo} setOrganisationPartnerArrayNo={setOrganisationPartnerArrayNo}

            organisationSSENArrayNo={organisationSSENArrayNo} setOrganisationSSENArrayNo={setOrganisationSSENArrayNo}
            readableSelectedArea={readableSelectedArea}
            setReadableSelectedArea={setReadableSelectedArea}

            extendedNotesArray={extendedNotesArray}
            setExtendedNotesArray={setExtendedNotesArray}
            setAddNoteButtonFlag={setAddNoteButtonFlag}
            addNoteButtonFlag={addNoteButtonFlag}

            publicExpiredUserNotes={publicExpiredUserNotes}
            setPublicExpiredUserNotes={setPublicExpiredUserNotes}
            partnersExpiredUserNotes={partnersExpiredUserNotes}
            setPartnersExpiredUserNotes={setPartnersExpiredUserNotes}
            SSENExpiredUserNotes={SSENExpiredUserNotes}
            setSSENExpiredUserNotes={setSSENExpiredUserNotes}
          
          
          
            publicExpiredNonUserNotes={publicExpiredNonUserNotes}
            setPublicExpiredNonUserNotes={setPublicExpiredNonUserNotes}
            partnersExpiredNonUserNotes={partnersExpiredNonUserNotes}
            setPartnersExpiredNonUserNotes={setPartnersExpiredNonUserNotes}
            SSENExpiredNonUserNotes={SSENExpiredNonUserNotes}
            setSSENExpiredNonUserNotes={setSSENExpiredNonUserNotes}


          ></NoteComponent>


        </IonContent>

      </div >
    </>
  );
}

export default SideBar;

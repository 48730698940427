import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCheckbox, IonDatetime, IonDatetimeButton, IonIcon, IonLabel, IonModal, IonSelect, IonSelectOption, IonTextarea } from "@ionic/react";
import { useEffect } from "react";
import { BASE_URL } from '../../../mapContentComponent/mapContentComponent';
import { ACCESS_CREDENTIALS } from "../../../../pages/Home";
import './EditNoteComponent.css';
import { create } from "ionicons/icons";
import { SidebarState, modalState } from "../../../../enums/enums";



type EditNoteProps = {
    setUpdateNotes: any;


    setModalVisibility: any;
    setTypeOfNoteAction: any;
    resiliencePartner: any;
    publicUserNotes: any;
    partnersUserNotes: any;
    SSENUserNotes: any;
    publicNonUserNotes: any;
    partnersNonUserNotes: any;
    SSENNonUserNotes: any;
    organisationArray: any;
    setEditButtonNumber: any;
    setDeleteNoteReference: any;
    setDeleteDateReference: any;


    setDeleteDistrictReference: any;
    setDeleteIDReference: any;
    setDeleteLocationReferenceX: any;
    setDeleteLocationReferenceY: any;

    deleteIDReference: any;

    deleteDistrictReference: any;
    deleteLocationReferenceX: any;
    deleteLocationReferenceY: any;
    deleteDateReference: any;


    editPublicNoteButtonClick: any;
    setEditPublicNoteButtonClick: any;

    editPartnerNoteButtonClick: any;
    setEditPartnerNoteButtonClick: any;

    editSSENNoteButtonClick: any;
    setEditSSENNoteButtonClick: any;


    readableSelectedArea: any;
    setReadableSelectedArea: any;

    userDistrictIdArray: any;
    userDistrictNameArray: any;

    setSidebarExpanded: any;

    extendedNotesArray: any;
    setExtendedNotesArray: any;
}


const EditNoteComponent: React.FC<EditNoteProps> = ({

    setUpdateNotes, setModalVisibility,
    setTypeOfNoteAction, resiliencePartner,
    publicUserNotes, partnersUserNotes,
    SSENUserNotes, publicNonUserNotes,
    partnersNonUserNotes, SSENNonUserNotes,
    organisationArray, setEditButtonNumber,
    setDeleteNoteReference, setDeleteDateReference,
    setDeleteDistrictReference, setDeleteIDReference,
    setDeleteLocationReferenceX, setDeleteLocationReferenceY,
    deleteIDReference, deleteDistrictReference,
    deleteLocationReferenceX, deleteLocationReferenceY,
    deleteDateReference,
    editPublicNoteButtonClick, setEditPublicNoteButtonClick,
    editPartnerNoteButtonClick, setEditPartnerNoteButtonClick,
    editSSENNoteButtonClick, setEditSSENNoteButtonClick,
    readableSelectedArea, setReadableSelectedArea,
    userDistrictIdArray,
    userDistrictNameArray,
    setSidebarExpanded,
    
    extendedNotesArray,
    setExtendedNotesArray


}) => {

    let modal_Container: any;


    let buttonShow: any;
    let buttonEdit: any;
    let buttonDelete: any;


    let segmentActivation: any;
    let segmentActivation2: any;
    let segmentActivation3: any;
    let ionSelectActive: any;


    let noteExtendActivate: any;

    let modal_Edit: any;

    let modal_Edit_Line: any;


    useEffect(() => {
        if (editPublicNoteButtonClick != 0) { // Use effect for note toggle coming on and affecting the sidebar by adding the notes
            editPublicNote(editPublicNoteButtonClick);
            setEditPublicNoteButtonClick(0);
        }
    }, [editPublicNoteButtonClick])


    useEffect(() => {
        if (editPartnerNoteButtonClick != 0) { // Use effect for note toggle coming on and affecting the sidebar by adding the notes
            editPartnersNote(editPartnerNoteButtonClick);
            setEditPartnerNoteButtonClick(0);
        }
    }, [editPartnerNoteButtonClick])


    useEffect(() => {
        if (editSSENNoteButtonClick != 0) { // Use effect for note toggle coming on and affecting the sidebar by adding the notes
            editSSENNote(editSSENNoteButtonClick);
            setEditSSENNoteButtonClick(0);
        }
    }, [editSSENNoteButtonClick])


    function disableButtons() { //Disables all of the buttons on the sidebar, if we split up into smaller components this will need split up
        publicUserNotes.map((function (publicUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + publicUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + publicUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + publicUserNotes.index);
            buttonShow.disabled = true;
            buttonEdit.disabled = true;
            buttonDelete.disabled = true;
        }))

        partnersUserNotes.map((function (partnersUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + partnersUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + partnersUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + partnersUserNotes.index);
            buttonShow.disabled = true;
            buttonEdit.disabled = true;
            buttonDelete.disabled = true;
        }))

        SSENUserNotes.map((function (SSENUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + SSENUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + SSENUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + SSENUserNotes.index);
            buttonShow.disabled = true;
            buttonEdit.disabled = true;
            buttonDelete.disabled = true;
        }))



        publicNonUserNotes.map((function (publicNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + publicNonUserNotes.index);
            buttonShow.disabled = true;
        }))


        partnersNonUserNotes.map((function (partnersNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + partnersNonUserNotes.index);
            buttonShow.disabled = true;
        }))


        SSENNonUserNotes.map((function (SSENNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + SSENNonUserNotes.index);
            buttonShow.disabled = true;
        }))



        segmentActivation = document.getElementById('segment-Id-1');
        segmentActivation.disabled = true;

        segmentActivation2 = document.getElementById('segment-Id-2');
        segmentActivation2.disabled = true;

        segmentActivation3 = document.getElementById('segment-Id-3');
        segmentActivation3.disabled = true;


        ionSelectActive = document.getElementById('ion-select-ID');
        ionSelectActive.disabled = true;

        noteExtendActivate = document.getElementById('chevronDownCircle');
        noteExtendActivate.style.pointerEvents = 'none';

        organisationArray.map(function (organisation: any, index: number) {
            index++;
            noteExtendActivate = document.getElementById("chevronDownCircle" + index);
            noteExtendActivate.style.pointerEvents = 'none';
        })
    }



    function enableButtons() { // Enables all of the sidebar buttons for use
        publicUserNotes.map((function (publicUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + publicUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + publicUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + publicUserNotes.index);
            buttonShow.disabled = false;
            buttonEdit.disabled = false;
            buttonDelete.disabled = false;
        }))

        partnersUserNotes.map((function (partnersUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + partnersUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + partnersUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + partnersUserNotes.index);
            buttonShow.disabled = false;
            buttonEdit.disabled = false;
            buttonDelete.disabled = false;
        }))

        SSENUserNotes.map((function (SSENUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + SSENUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + SSENUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + SSENUserNotes.index);
            buttonShow.disabled = false;
            buttonEdit.disabled = false;
            buttonDelete.disabled = false;
        }))



        publicNonUserNotes.map((function (publicNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + publicNonUserNotes.index);
            buttonShow.disabled = false;
        }))


        partnersNonUserNotes.map((function (partnersNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + partnersNonUserNotes.index);
            buttonShow.disabled = false;
        }))


        SSENNonUserNotes.map((function (SSENNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + SSENNonUserNotes.index);
            buttonShow.disabled = false;
        }))


        segmentActivation = document.getElementById('segment-Id-1');
        segmentActivation.disabled = false;
        segmentActivation2 = document.getElementById('segment-Id-2');
        segmentActivation2.disabled = false;
        segmentActivation3 = document.getElementById('segment-Id-3');
        segmentActivation3.disabled = false;

        ionSelectActive = document.getElementById('ion-select-ID');
        ionSelectActive.disabled = false;

        noteExtendActivate = document.getElementById('chevronDownCircle');
        noteExtendActivate.style.pointerEvents = 'all';

        organisationArray.map(function (organisation: any, index: number) {
            index++;
            noteExtendActivate = document.getElementById("chevronDownCircle" + index);
            noteExtendActivate.style.pointerEvents = 'all';
        })
    }

    let noteMinimise: any;
    let addNoteStyleWidth: any;

    let modal_Container_Edit: any;

    function editPublicNote(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 

        if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
            noteMinimise = document.getElementById('note-Test-Perm-Fix');
            noteMinimise.style.height = "72px";
        }

        if (window.innerWidth == 640 || window.innerWidth < 640) {
            addNoteStyleWidth = document.getElementById('modal-header-container-edit');
            addNoteStyleWidth.style.width = '100%'
        }

        modal_Edit = document.getElementById('modal-Edit');
        modal_Edit_Line = document.getElementById('note-Edit-Line');
        modal_Container = document.getElementById('modal-Container-Edit');

        setUpdateNotes("Update");
        setEditButtonNumber(noteNumber);
        if (modal_Edit.style.display == "none") {
            modal_Edit.style.display = "block";
            modal_Container.style.display = 'flex';

            let portrait = window.matchMedia("(orientation: portrait)");

            portrait.addEventListener("change", function (e) {
                if (e.matches) {
                    modal_Container_Edit = document.getElementById('modal-Container-Edit');
                    modal_Container_Edit.style.top = '0';
                } else {
                    modal_Container_Edit = document.getElementById('modal-Container-Edit');
                    modal_Container_Edit.style.top = '-35px';
                }
            })

            disableButtons();
            setModalVisibility(modalState.SHOWN);

            // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

            publicUserNotes.map((function (notesArrayEdit: any) {
                if (notesArrayEdit.index == noteNumber) {
                    setDeleteNoteReference(notesArrayEdit.note_Content);
                    modal_Edit_Line.value = notesArrayEdit.note_Content;
                    setDeleteDateReference(notesArrayEdit.note_Creation);
                    setDeleteDistrictReference(notesArrayEdit.note_District);
                    setDeleteIDReference(notesArrayEdit.note_ID);
                    userDistrictIdArray.map(function (districts: any, index: any) {
                        if (districts == notesArrayEdit.note_District) {
                            setReadableSelectedArea(userDistrictNameArray[index])
                        }
                    })
                    const myArray = notesArrayEdit.note_Location.split(",");
                    setDeleteLocationReferenceX(myArray[0]);
                    setDeleteLocationReferenceY(myArray[1].trim())
                }
            }))
        } else if (modal_Edit.style.display = "block") {
            modal_Edit.style.display = "none";
            modal_Container.style.display = 'none';
            setModalVisibility(modalState.HIDDEN);
            enableButtons();

            if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                extendedNotesArray.map(function (noteExtender: any) {
                    noteMinimise = document.getElementById(noteExtender);
                    noteMinimise.style.height = "auto";
                })
    
            }
        }
    }

    function editPartnersNote(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
        modal_Edit = document.getElementById('modal-Edit');
        modal_Edit_Line = document.getElementById('note-Edit-Line');
        modal_Container = document.getElementById('modal-Container-Edit');

        if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
            noteMinimise = document.getElementById('note-Test-Perm-Fix');
            noteMinimise.style.height = "72px";
        }

        if (window.innerWidth == 640 || window.innerWidth < 640) {
            addNoteStyleWidth = document.getElementById('modal-header-container-edit');
            addNoteStyleWidth.style.width = '100%'

        }

        setUpdateNotes("Update");
        setEditButtonNumber(noteNumber);
        if (modal_Edit.style.display == "none") {
            modal_Edit.style.display = "block";
            modal_Container.style.display = 'flex';

            let portrait = window.matchMedia("(orientation: portrait)");

            portrait.addEventListener("change", function (e) {
                if (e.matches) {
                    modal_Container_Edit = document.getElementById('modal-Container-Edit');
                    modal_Container_Edit.style.top = '0';
                } else {
                    modal_Container_Edit = document.getElementById('modal-Container-Edit');
                    modal_Container_Edit.style.top = '-35px';
                }
            })

            disableButtons();
            setModalVisibility(modalState.SHOWN);

            // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

            partnersUserNotes.map((function (notesArrayEdit: any) {
                if (notesArrayEdit.index == noteNumber) {
                    setDeleteNoteReference(notesArrayEdit.note_Content);
                    modal_Edit_Line.value = notesArrayEdit.note_Content;
                    setDeleteDateReference(notesArrayEdit.note_Creation);
                    setDeleteDistrictReference(notesArrayEdit.note_District);
                    setDeleteIDReference(notesArrayEdit.note_ID);
                    userDistrictIdArray.map(function (districts: any, index: any) {
                        if (districts == notesArrayEdit.note_District) {
                            setReadableSelectedArea(userDistrictNameArray[index])
                        }
                    })
                    const myArray = notesArrayEdit.note_Location.split(",");
                    setDeleteLocationReferenceX(myArray[0]);
                    setDeleteLocationReferenceY(myArray[1].trim())
                }
            }))
        }
        else if (modal_Edit.style.display = "block") {
            modal_Edit.style.display = "none";
            modal_Container.style.display = 'none';
            setModalVisibility(modalState.HIDDEN);
            enableButtons();

            if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                extendedNotesArray.map(function (noteExtender: any) {
                    noteMinimise = document.getElementById(noteExtender);
                    noteMinimise.style.height = "auto";
                })
    
            }
        }
    }

    function editSSENNote(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
        modal_Edit = document.getElementById('modal-Edit');
        modal_Edit_Line = document.getElementById('note-Edit-Line');
        modal_Container = document.getElementById('modal-Container-Edit');

        if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
            noteMinimise = document.getElementById('note-Test-Perm-Fix');
            noteMinimise.style.height = "72px";
        }

        if (window.innerWidth == 640 || window.innerWidth < 640) {
            addNoteStyleWidth = document.getElementById('modal-header-container-edit');
            addNoteStyleWidth.style.width = '100%'

        }

        setUpdateNotes("Update");
        setEditButtonNumber(noteNumber);
        if (modal_Edit.style.display == "none") {
            modal_Edit.style.display = "block";
            modal_Container.style.display = 'flex';

            let portrait = window.matchMedia("(orientation: portrait)");

            portrait.addEventListener("change", function (e) {
                if (e.matches) {
                    modal_Container_Edit = document.getElementById('modal-Container-Edit');
                    modal_Container_Edit.style.top = '0';
                } else {
                    modal_Container_Edit = document.getElementById('modal-Container-Edit');
                    modal_Container_Edit.style.top = '-35px';
                }
            })

            disableButtons();
            setModalVisibility(modalState.SHOWN);

            // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

            SSENUserNotes.map((function (notesArrayEdit: any) {
                if (notesArrayEdit.index == noteNumber) {
                    setDeleteNoteReference(notesArrayEdit.note_Content);
                    modal_Edit_Line.value = notesArrayEdit.note_Content;
                    setDeleteDateReference(notesArrayEdit.note_Creation);
                    setDeleteDistrictReference(notesArrayEdit.note_District);
                    userDistrictIdArray.map(function (districts: any, index: any) {
                        if (districts == notesArrayEdit.note_District) {
                            setReadableSelectedArea(userDistrictNameArray[index])
                        }
                    })
                    setDeleteIDReference(notesArrayEdit.note_ID);
                    const myArray = notesArrayEdit.note_Location.split(",");
                    setDeleteLocationReferenceX(myArray[0]);
                    setDeleteLocationReferenceY(myArray[1].trim())
                }
            }))
        } else if (modal_Edit.style.display = "block") {
            modal_Edit.style.display = "none";
            modal_Container.style.display = 'none';
            setModalVisibility(modalState.HIDDEN);
            enableButtons();

            if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                extendedNotesArray.map(function (noteExtender: any) {
                    noteMinimise = document.getElementById(noteExtender);
                    noteMinimise.style.height = "auto";
                })
    
            }
        }
    }

    function saveEditNote() { // Function to save the values edited by the user on the edit note modal, called by clicking publish button on the edit modal.  
        modal_Edit_Line = document.getElementById('note-Edit-Line');
        if (modal_Edit_Line.value.trim().length === 0) {
            alert("The note must have some text content.");
        } else {
            modal_Edit = document.getElementById('modal-Edit');
            modal_Edit.style.display = "none";
            modal_Container = document.getElementById('modal-Container-Edit');
            modal_Container.style.display = 'none';
            enableButtons();
            setModalVisibility(modalState.HIDDEN);

            // Creates path for edited data to be posted to, also creates headings containing necessary auth 


            if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                extendedNotesArray.map(function (noteExtender: any) {
                    noteMinimise = document.getElementById(noteExtender);
                    noteMinimise.style.height = "auto";
                })
    
            }

            let pathNoteEditTest: any
            let headersForEdit = new Headers();
            headersForEdit.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
            headersForEdit.append("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");

            pathNoteEditTest = BASE_URL + "/gridim/rotaload/partners";

            // var of data to be posted, record then encoded into formbody.

            var details = {
                'action': 'updateNote',
                'id': deleteIDReference,
                'text': modal_Edit_Line.value,
            };
            const formBody = Object.entries(details).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&')

            // fetch POST method used to post the now edited data to the backend, if fetch fails an error is thrown, if it doesn't fail notes are set to update and type of note action is set. 

            fetch(pathNoteEditTest, {
                method: 'POST',
                headers: headersForEdit,
                body: formBody
            })
                .then(function (response) {
                    if (!response.ok) {
                        throw new Error("HTTP status " + response.status);
                    } else {
                        setUpdateNotes("Update");
                        setTypeOfNoteAction("Edit Note")
                    }
                }
                )
        }
    }




















    return (
        <>

            <div id='modal-Container-Edit' className='modal-Container-Edit' style={{ display: "none", top: '0' }}>


                <div id="modal-Edit" className="modal-Edit" style={{ display: "none" }}>
                    <div className="modal_content">
                        <div className='note-Input'>
                            <IonCard className='add-Note-Style' id='modal-header-container-edit'><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={create} style={{ color: "white" }} />  Edit This Note</h1></IonCard>
                            <div className='note-Layout-Input'>
                                <div style={{ display: 'flex' }}>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Area: {readableSelectedArea}</IonCardContent></IonCard>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Note Coordinates: {deleteLocationReferenceX}, {deleteLocationReferenceY} </IonCardContent></IonCard>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Partner: {resiliencePartner}</IonCardContent></IonCard>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Created:  {deleteDateReference}</IonCardContent></IonCard>
                                </div>

                                {/* <div style={{ display: 'flex' }}>
    <IonCard className='note-Card-Style-Small' style={{ width: '100%' }}><IonCardContent className='note-Card-Content'>Note Type: {} </IonCardContent></IonCard>
  </div>  */}

                                <IonTextarea placeholder="Enter text" className='note-Input-Line' id='note-Edit-Line' readonly={false}> {/*Review for RESILIENCE 142 */}
                                </ IonTextarea>
                                <div className='note-Button-Placement'>
                                    <IonButton className='note-Button-Red' shape="round" onClick={() => editPublicNote(0)}>Cancel</IonButton>
                                    <IonButton className='note-Button-Green' shape="round" onClick={() => saveEditNote()}>Publish</IonButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}



export default EditNoteComponent
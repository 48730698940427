import { IonButton, IonCard, IonCardContent, IonCardTitle, IonIcon, IonSelect, IonSelectOption } from "@ionic/react";
import { useEffect } from "react";
import { BASE_URL } from '../../mapContentComponent/mapContentComponent';
import { ACCESS_CREDENTIALS } from "../../../pages/Home";
import './ScenarioComponentStyle.css';
import { chevronDown, chevronUp, timeOutline } from "ionicons/icons";
import { modalState } from "../../../enums/enums";


type scenarioProps = {
    setScenarioStage: any;
    setScenarioTime: any;
    setScenarioDay: any;
    scenarioDay: any;
    scenarioTime: any;
    scenarioStage: any;
    timeAndLevelButtons: any;


    scenarioDayView: any;
    setScenarioDayView: any;
    scenarioTimeView: any
    setScenarioTimeView: any;
    scenarioStageView: any;
    setScenarioStageView: any;
    setModalVisibility: any;
    setSendScenarioDataFlag: any;

    propertyNumbersScenario: any;
    customerNumbersScenario: any;
    setClearScenario: any;
    RLBToggle: any;
    setUpdateNotes: any;
}


const ScenarioComponent: React.FC<scenarioProps> = ({

    setScenarioStage, setScenarioTime, setScenarioDay, scenarioStage, scenarioTime, scenarioDay, timeAndLevelButtons, scenarioDayView, setScenarioDayView, scenarioTimeView,
    setScenarioTimeView, scenarioStageView, setScenarioStageView, setModalVisibility, propertyNumbersScenario, customerNumbersScenario, setClearScenario, setSendScenarioDataFlag,
    RLBToggle, setUpdateNotes

}) => {

    let stageSelection: any;
    let modal_Container: any;
    let daySelection: any;
    let timeSelection: any;
    let stageIncrement: any;
    let stageReduce: any;
    let timeIncrement: any;
    let timeReduce: any;
    let modal_Scenario: any;

    let scenarioSelected1: any;
    let scenarioSelected2: any;
    let scenarioSelected3: any;

    let level_Buttons: any;
    let time_Buttons: any;

    let scenarioClear: any;

    let scenarioButton: any;
    let incrementTimeVal: any;
    let reduceTimeVal: any;
    let incrementStageVal: any;
    let reduceStageVal: any;

    let scenario_Planner_Style: any;

    let scenario_Planner_Buttons: any;
    let dottedLine: any;




    useEffect(() => {
        if (RLBToggle === "ON") {  // Updates when RLB is toggled on/off

            scenario_Planner_Style = document.getElementById('scenario-Planner-Style');
            scenario_Planner_Style.style.display = 'block';
            scenario_Planner_Buttons = document.getElementById('scenario-Planner-Buttons');
            scenario_Planner_Buttons.disabled = 'false';
            scenario_Planner_Buttons.style.display = 'flex';
            dottedLine = document.getElementById('horizontal-dotted-line');
            dottedLine.style.display = "flex";
            setUpdateNotes("Update");
        } if (RLBToggle === "OFF") {
            setUpdateNotes("Update");

            scenario_Planner_Style = document.getElementById('scenario-Planner-Style');
            scenario_Planner_Style.style.display = 'none';

            scenario_Planner_Buttons = document.getElementById('scenario-Planner-Buttons');
            scenario_Planner_Buttons.disabled = 'true';
            scenario_Planner_Buttons.style.display = 'none';

            dottedLine = document.getElementById('horizontal-dotted-line');
            dottedLine.style.display = "none";

            level_Buttons = document.getElementById('level-Buttons');
            level_Buttons.style.display = 'none';
            time_Buttons = document.getElementById('time-Buttons');
            time_Buttons.style.display = 'none';

            scenarioSelected2 = document.getElementById('scenario-Planner-Section-2');
            scenarioSelected2.style.display = 'none';
            scenarioSelected3 = document.getElementById('scenario-Planner-Section-3');
            scenarioSelected3.style.display = 'none';
            stageSelection = document.getElementById('stage-Selection');
            daySelection = document.getElementById('day-Selection');
            timeSelection = document.getElementById('time-Selection');
            stageIncrement = document.getElementById('stage-Increment');
            stageReduce = document.getElementById('stage-Reduce');
            stageIncrement.style.pointerEvents = 'none';
            stageReduce.style.pointerEvents = 'none';
            timeIncrement = document.getElementById('time-Increment');
            timeReduce = document.getElementById('time-Reduce');
            timeIncrement.style.pointerEvents = 'none';
            timeReduce.style.pointerEvents = 'none';
            scenarioClear = document.getElementById('scenario-Clear-Buttons');
            scenarioClear.style.display = 'none';
            setScenarioStage(0);
            setScenarioDay(0);
            setScenarioTime(0);
        }
    }, [RLBToggle])

    let noteMinimise: any; 
    let dataCardsStyleAttemptScenario: any;
    let modalScenario: any; 
    let modalScenarioHeader: any; 



    function scenarioPlanner() { // Function to bring up scenario planner modal, called when "Create a Scenario" button is clicked.
        stageSelection = document.getElementById('stage-Selection');
        daySelection = document.getElementById('day-Selection');
        timeSelection = document.getElementById('time-Selection');
        modal_Container = document.getElementById('modal-Container-Scenario');

        noteMinimise = document.getElementById('note-Test-Perm-Fix');
        noteMinimise.style.height = "72px"

        if (window.innerWidth == 640 || window.innerWidth < 640) {
            dataCardsStyleAttemptScenario = document.getElementById('data-Cards-Style-Attempt-Scenario');  
            dataCardsStyleAttemptScenario.style.flexWrap = 'wrap';
            modalScenario = document.getElementById('modal-Scenario');  
            modalScenario.style.width = '96%';
            modalScenarioHeader = document.getElementById('modal-Header-Scenario');  
            modalScenarioHeader.style.width = '100%';
        }


        setUpdateNotes("Update");

        setModalVisibility(modalState.SHOWN);
        disableButtons();
        //clears values incase scenario creation has already happened that use session.
        stageSelection.value = "";
        daySelection.value = "";
        timeSelection.value = "";
        modal_Scenario = document.getElementById('modal-Scenario');
        if (modal_Scenario.style.display == "block") {
            if (scenarioStage.toString() != "" && scenarioTime.toString() != "" && scenarioDay.toString() != "") {
                stageIncrement = document.getElementById('stage-Increment');
                stageReduce = document.getElementById('stage-Reduce');
                stageIncrement.style.pointerEvents = 'all';
                stageReduce.style.pointerEvents = 'all';
                timeIncrement = document.getElementById('time-Increment');
                timeReduce = document.getElementById('time-Reduce');
                timeIncrement.style.pointerEvents = 'all';
                timeReduce.style.pointerEvents = 'all';
                modal_Scenario.style.display = "none";
                modal_Container.style.display = 'none';
                setModalVisibility(modalState.HIDDEN);
                enableButtons();
            } else {
                modal_Scenario.style.display = "none";
                modal_Container.style.display = 'none';
                setModalVisibility(modalState.HIDDEN);
                enableButtons();
            }
        } else if (modal_Scenario.style.display == "none") {
            modal_Scenario.style.display = "block";
            modal_Container.style.display = 'flex';
        }
    }






    function scenarioPlanChosen() { // Function called when user clicks view scenario button on scenario planner modal.
        stageSelection = document.getElementById('stage-Selection');
        daySelection = document.getElementById('day-Selection');
        timeSelection = document.getElementById('time-Selection');

        setModalVisibility(modalState.HIDDEN);
        enableButtons();

        // Makes sure user submits values in all three sceanrio parameters.
        if (stageSelection.value != "" && daySelection.value != "" && timeSelection.value != "") {
            modal_Container = document.getElementById('modal-Container-Scenario');
            modal_Container.style.display = 'none';
            level_Buttons = document.getElementById('level-Buttons');
            level_Buttons.style.display = 'flex';
            time_Buttons = document.getElementById('time-Buttons');
            time_Buttons.style.display = 'flex';
            scenarioSelected1 = document.getElementById('Scenario-Planner-Section');
            scenarioSelected1.style.display = 'flex';
            scenarioSelected2 = document.getElementById('scenario-Planner-Section-2');
            scenarioSelected2.style.display = 'flex';
            scenarioSelected3 = document.getElementById('scenario-Planner-Section-3');
            scenarioSelected3.style.display = 'flex';
            stageSelection = document.getElementById('stage-Selection');
            daySelection = document.getElementById('day-Selection');
            timeSelection = document.getElementById('time-Selection');
            modal_Scenario = document.getElementById('modal-Scenario');
            modal_Scenario.style.display = "none";
            stageIncrement = document.getElementById('stage-Increment');
            stageReduce = document.getElementById('stage-Reduce');
            stageIncrement.style.pointerEvents = 'all';
            stageReduce.style.pointerEvents = 'all';
            timeIncrement = document.getElementById('time-Increment');
            timeReduce = document.getElementById('time-Reduce');
            timeIncrement.style.pointerEvents = 'all';
            timeReduce.style.pointerEvents = 'all';
            scenarioClear = document.getElementById('scenario-Clear-Buttons');
            scenarioClear.style.display = 'flex';
            setScenarioStage(stageSelection.value);
            setScenarioDay(daySelection.value);
            setScenarioTime(timeSelection.value);
            sendScenarioData(stageSelection.value, daySelection.value, timeSelection.value);
            setUpdateNotes("Update");
        } else {
            alert("Please fully enter scenario parameters"); //Alert to inform user they have left one of the parameters empty/unselected. 
        }
    }

    useEffect(() => { // Enables the buttons for scenarios, should be added to a scenario component
        if (timeAndLevelButtons === "Enable") {
            incrementTimeVal = document.getElementById('time-Increment');
            incrementTimeVal.style.pointerEvents = 'all';
            incrementStageVal = document.getElementById('stage-Increment');
            incrementStageVal.style.pointerEvents = 'all';
            reduceTimeVal = document.getElementById('time-Reduce');
            reduceTimeVal.style.pointerEvents = 'all';
            reduceStageVal = document.getElementById('stage-Reduce');
            reduceStageVal.style.pointerEvents = 'all';
        }
        if (timeAndLevelButtons === "Disable") {
            incrementTimeVal = document.getElementById('time-Increment');
            incrementTimeVal.style.pointerEvents = 'none';
            incrementStageVal = document.getElementById('stage-Increment');
            incrementStageVal.style.pointerEvents = 'none';
            reduceTimeVal = document.getElementById('time-Reduce');
            reduceTimeVal.style.pointerEvents = 'none';
            reduceStageVal = document.getElementById('stage-Reduce');
            reduceStageVal.style.pointerEvents = 'none';
        }
    }, [timeAndLevelButtons]
    );

    function scenarioClearer() {
        level_Buttons = document.getElementById('level-Buttons');
        level_Buttons.style.display = 'none';
        time_Buttons = document.getElementById('time-Buttons');
        time_Buttons.style.display = 'none';
        setUpdateNotes("Update");

        scenarioSelected2 = document.getElementById('scenario-Planner-Section-2');
        scenarioSelected2.style.display = 'none';
        scenarioSelected3 = document.getElementById('scenario-Planner-Section-3');
        scenarioSelected3.style.display = 'none';
        stageSelection = document.getElementById('stage-Selection');
        daySelection = document.getElementById('day-Selection');
        timeSelection = document.getElementById('time-Selection');
        stageIncrement = document.getElementById('stage-Increment');
        stageReduce = document.getElementById('stage-Reduce');
        stageIncrement.style.pointerEvents = 'none';
        stageReduce.style.pointerEvents = 'none';
        timeIncrement = document.getElementById('time-Increment');
        timeReduce = document.getElementById('time-Reduce');
        timeIncrement.style.pointerEvents = 'none';
        timeReduce.style.pointerEvents = 'none';
        scenarioClear = document.getElementById('scenario-Clear-Buttons');
        scenarioClear.style.display = 'none';
        setScenarioStage(0);
        setScenarioDay(0);
        setScenarioTime(0);
        setClearScenario("Clear")
    }




    function incrementStage() { // Function to increase stage parameter without having to create an entire scenario. Called when user presses up chevron under level label, only present after a scenario has been created. 
        if (scenarioStage.toString() != "") {
            let scenarioString = scenarioStage.toString();
            let string = scenarioString.substring(0, scenarioString.length - 1);
            let string2 = scenarioString.charAt(scenarioString.length - 1);
            let string3 = scenarioString.charAt(scenarioString.length - 2);
            let scnerioInt = parseInt(string2);
            let scnerioInt2 = parseInt(string3);
            if (scnerioInt == 8 && scnerioInt2 == 1) {
            } else {
                scnerioInt = scnerioInt + 1;
                let String4 = scnerioInt.toString()
                let string5 = string + String4
                setScenarioStage(string5);
                sendScenarioData(string5, scenarioDay, scenarioTime)
            }

        }
        setUpdateNotes("Update");
    }

    function reduceStage() { // Function to decrease stage parameter without having to create an entire scenario. Called when user presses down chevron under level label, only present after a scenario has been created. 
        if (scenarioStage.toString() != "") {
            let scenarioString = scenarioStage.toString();
            let string2 = scenarioString.charAt(scenarioString.length - 1);
            let string3 = scenarioString.charAt(scenarioString.length - 2);
            let scnerioInt = parseInt(string2);
            let scnerioInt2 = parseInt(string3);
            if (scnerioInt != 0 && scnerioInt2 == 1) {
                let string = scenarioString.substring(0, scenarioString.length - 1);
                scnerioInt = scnerioInt - 1;
                let String4 = scnerioInt.toString()
                let string5 = string + String4
                setScenarioStage(string5);
                sendScenarioData(string5, scenarioDay, scenarioTime)
            } else if (scnerioInt == 0 && scnerioInt2 == 1) {
                let string = scenarioString.substring(0, scenarioString.length - 2);
                scnerioInt = 9;
                let String4 = scnerioInt.toString()
                let string5 = string + String4
                setScenarioStage(string5);
                sendScenarioData(string5, scenarioDay, scenarioTime)
            } else if (scnerioInt != 1) {
                let string = scenarioString.substring(0, scenarioString.length - 1);
                scnerioInt = scnerioInt - 1;
                let String4 = scnerioInt.toString()
                let string5 = string + String4
                setScenarioStage(string5);
                sendScenarioData(string5, scenarioDay, scenarioTime)
            }

        }
        setUpdateNotes("Update");
    }


    function incrementTime() { // Function to increase time parameter without having to create an entire scenario. Called when user presses up chevron under time label, only present after a scenario has been created. 
        if (scenarioTime.toString() != "") {
            let dayArray: any;
            dayArray = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
            let scenarioTimeVal: any;
            scenarioTimeVal = "";
            let scenarioDayVal: any;
            switch (scenarioTime.toString()) {
                case "00:30 - 03:30":
                    setScenarioTime("03:30 - 06:30")
                    scenarioTimeVal = "03:30 - 06:30";
                    scenarioDayVal = scenarioDay;
                    break;
                case "03:30 - 06:30":
                    setScenarioTime("06:30 - 09:30")
                    scenarioTimeVal = "06:30 - 09:30";
                    scenarioDayVal = scenarioDay;
                    break;
                case "06:30 - 09:30":
                    setScenarioTime("09:30 - 12:30")
                    scenarioTimeVal = "09:30 - 12:30";
                    scenarioDayVal = scenarioDay;
                    break;
                case "09:30 - 12:30":
                    setScenarioTime("12:30 - 15:30")
                    scenarioTimeVal = "12:30 - 15:30";
                    scenarioDayVal = scenarioDay;
                    break;
                case "12:30 - 15:30":
                    setScenarioTime("15:30 - 18:30")
                    scenarioTimeVal = "15:30 - 18:30";
                    scenarioDayVal = scenarioDay;
                    break;
                case "15:30 - 18:30":
                    setScenarioTime("18:30 - 21:30")
                    scenarioTimeVal = "18:30 - 21:30";
                    scenarioDayVal = scenarioDay;
                    break;
                case "18:30 - 21:30":
                    setScenarioTime("21:30 - 00:30")
                    scenarioTimeVal = "21:30 - 00:30";
                    scenarioDayVal = scenarioDay;
                    break;
                case "21:30 - 00:30":
                    setScenarioTime("00:30 - 03:30")
                    scenarioTimeVal = "00:30 - 03:30";
                    for (let i = 0; i < dayArray.length; i++) {
                        if (dayArray[i] === scenarioDay) {
                            if (i === 6) {
                                setScenarioDay(dayArray[0]);
                                scenarioDayVal = dayArray[0];
                            } else {
                                setScenarioDay(dayArray[i + 1]);
                                scenarioDayVal = dayArray[i + 1]
                            }
                        }
                    }
                    break;
            }
            sendScenarioData(scenarioStage, scenarioDayVal, scenarioTimeVal)
        }
        setUpdateNotes("Update");
    }

    function reduceTime() { // Function to decrease time parameter without having to create an entire scenario. Called when user presses down chevron under time label, only present after a scenario has been created. 
        if (scenarioTime.toString() != "") {
            let dayArray: any;
            dayArray = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
            let scenarioTimeVal: any;
            scenarioTimeVal = "";
            let scenarioDayVal: any;
            switch (scenarioTime.toString()) {
                case "00:30 - 03:30":
                    setScenarioTime("21:30 - 00:30")
                    scenarioTimeVal = "21:30 - 00:30";
                    for (let i = 0; i < dayArray.length; i++) {
                        if (dayArray[i] === scenarioDay) {
                            if (i === 0) {
                                setScenarioDay(dayArray[6]);
                                scenarioDayVal = dayArray[6];
                            } else {
                                setScenarioDay(dayArray[i - 1]);
                                scenarioDayVal = dayArray[i - 1];
                            }
                        }
                    }
                    break;
                case "21:30 - 00:30":
                    setScenarioTime("18:30 - 21:30")
                    scenarioTimeVal = "18:30 - 21:30";
                    scenarioDayVal = scenarioDay;
                    break;
                case "18:30 - 21:30":
                    setScenarioTime("15:30 - 18:30")
                    scenarioTimeVal = "15:30 - 18:30";
                    scenarioDayVal = scenarioDay;
                    break;
                case "15:30 - 18:30":
                    setScenarioTime("12:30 - 15:30")
                    scenarioTimeVal = "12:30 - 15:30";
                    scenarioDayVal = scenarioDay;
                    break;
                case "12:30 - 15:30":
                    setScenarioTime("09:30 - 12:30")
                    scenarioTimeVal = "09:30 - 12:30";
                    scenarioDayVal = scenarioDay;
                    break;
                case "09:30 - 12:30":
                    setScenarioTime("06:30 - 09:30")
                    scenarioTimeVal = "06:30 - 09:30";
                    scenarioDayVal = scenarioDay;
                    break;
                case "06:30 - 09:30":
                    setScenarioTime("03:30 - 06:30")
                    scenarioTimeVal = "03:30 - 06:30";
                    scenarioDayVal = scenarioDay;
                    break;
                case "03:30 - 06:30":
                    setScenarioTime("00:30 - 03:30")
                    scenarioTimeVal = "00:30 - 03:30";
                    scenarioDayVal = scenarioDay;
                    break;
            }
            sendScenarioData(scenarioStage, scenarioDayVal, scenarioTimeVal)
        }
        setUpdateNotes("Update");
    }

    function sendScenarioData(stage: any, day: any, time: any) {
        setScenarioDay(day);
        setScenarioStage(stage);
        setScenarioTime(time);
        setSendScenarioDataFlag("Send");
    }


    function disableButtons() { //Disables all of the buttons on the sidebar, if we split up into smaller components this will need split up
        scenarioButton = document.getElementById('scenario-Planner-Buttons');
        scenarioButton.disabled = true;
        incrementTimeVal = document.getElementById('time-Increment');
        incrementTimeVal.style.pointerEvents = 'none';
        incrementStageVal = document.getElementById('stage-Increment');
        incrementStageVal.style.pointerEvents = 'none';
        reduceTimeVal = document.getElementById('time-Reduce');
        reduceTimeVal.style.pointerEvents = 'none';
        reduceStageVal = document.getElementById('stage-Reduce');
        reduceStageVal.style.pointerEvents = 'none';



    }


    function enableButtons() { // Enables all of the sidebar buttons for use

        scenarioButton = document.getElementById('scenario-Planner-Buttons');
        scenarioButton.disabled = false;
        incrementTimeVal = document.getElementById('time-Increment');
        incrementTimeVal.style.pointerEvents = 'all';
        incrementStageVal = document.getElementById('stage-Increment');
        incrementStageVal.style.pointerEvents = 'all';
        reduceTimeVal = document.getElementById('time-Reduce');
        reduceTimeVal.style.pointerEvents = 'all';
        reduceStageVal = document.getElementById('stage-Reduce');
        reduceStageVal.style.pointerEvents = 'all';

    }






    return (
        <>
            <div className="horizontal_dotted_line" id='horizontal-dotted-line'></div>
            <div className='scenario-Planner-Style' id='scenario-Planner-Style'>
                <span>Scenario Planner</span>
                <div>
                    <div className='scenario-Style-Placement'>
                        <div id="Scenario-Planner-Section" className='scenario-Planner-Section'>
                            <div id='level-Buttons' className='level-Buttons'>
                                <IonCardContent>
                                    Level
                                </IonCardContent>
                                <div className='scenario-Increment-Button' id='stage-Increment' onClick={() => incrementStage()}>
                                    <IonIcon slot='icon-only' className='icon-Only' icon={chevronUp} style={{ color: "white" }} />
                                </div>
                                <div className='scenario-Reduction-Button' id='stage-Reduce' onClick={() => reduceStage()} >
                                    <IonIcon slot='icon-only' className='icon-Only' icon={chevronDown} style={{ color: "white" }} />
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <IonButton className='scenario-Planner-Buttons' id='scenario-Planner-Buttons' onClick={() => scenarioPlanner()}>Create a scenario</IonButton>
                                <IonButton className='scenario-Clear-Buttons' id='scenario-Clear-Buttons' onClick={() => scenarioClearer()} style={{ display: 'none' }}>Clear the scenario</IonButton>
                            </div>
                            <div id='time-Buttons' className='time-Buttons'>
                                <IonCardContent>
                                    Time
                                </IonCardContent>
                                <div className='scenario-Increment-Button' id='time-Increment' onClick={() => incrementTime()} >
                                    <IonIcon slot='icon-only' className='icon-Only' icon={chevronUp} style={{ color: "white" }} />
                                </div>
                                <div className='scenario-Reduction-Button' id='time-Reduce' onClick={() => reduceTime()}>
                                    <IonIcon slot='icon-only' className='icon-Only' icon={chevronDown} style={{ color: "white" }} />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="scenario-Info-Card" id="scenario-Planner-Section-2">
                            <IonCardTitle class="explanation-Card-Title">{scenarioStage} • {scenarioDay} • {scenarioTime} </IonCardTitle>
                        </div>
                    </div>
                    <div className="data-Cards-Style-Attempt" id="scenario-Planner-Section-3" style={{ display: 'none' }}>
                        <div className="data-Card-Style">
                            <IonCardTitle class="explanation-Card-Title">Number of Properties Affected</IonCardTitle>
                            <IonCardContent class="block-Letter-Text-Smaller">
                                {propertyNumbersScenario}
                            </IonCardContent>
                        </div>
                        <div className="data-Card-Style">
                            <IonCardTitle class="explanation-Card-Title">Priority Customers Affected</IonCardTitle>
                            <IonCardContent class="block-Letter-Text-Smaller">
                                {customerNumbersScenario}
                            </IonCardContent>
                        </div>
                    </div>
                </div>
            </div>
            <div id='modal-Container-Scenario' className='modal-Container' style={{ display: "none" }}>

                <div id="modal-Scenario" className="modal-Scenario" style={{ display: "none" }}>
                    <div className="modal_content">
                        <div className='note-Input'>
                            <IonCard className='scenario-Style' id='modal-Header-Scenario'><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={timeOutline} style={{ color: "white" }} />  Scenario Planner</h1></IonCard>
                            <div className='note-Layout-Input'>
                                <div className="data-Cards-Style-Attempt" id="data-Cards-Style-Attempt-Scenario" >
                                    <div className='scenario-Planner-Selection-Style-Stage'>
                                        <IonSelect id='stage-Selection' interface="popover" placeholder="Level" value={scenarioStageView} onIonChange={(e: any) => setScenarioStageView(e.detail.value)}>
                                            <IonSelectOption value="Level 1">Level 1</IonSelectOption>
                                            <IonSelectOption value="Level 2">Level 2</IonSelectOption>
                                            <IonSelectOption value="Level 3">Level 3</IonSelectOption>
                                            <IonSelectOption value="Level 4">Level 4</IonSelectOption>
                                            <IonSelectOption value="Level 5">Level 5</IonSelectOption>
                                            <IonSelectOption value="Level 6">Level 6</IonSelectOption>
                                            <IonSelectOption value="Level 7">Level 7</IonSelectOption>
                                            <IonSelectOption value="Level 8">Level 8</IonSelectOption>
                                            <IonSelectOption value="Level 9">Level 9</IonSelectOption>
                                            <IonSelectOption value="Level 10">Level 10</IonSelectOption>
                                            <IonSelectOption value="Level 11">Level 11</IonSelectOption>
                                            <IonSelectOption value="Level 12">Level 12</IonSelectOption>
                                            <IonSelectOption value="Level 13">Level 13</IonSelectOption>
                                            <IonSelectOption value="Level 14">Level 14</IonSelectOption>
                                            <IonSelectOption value="Level 15">Level 15</IonSelectOption>
                                            <IonSelectOption value="Level 16">Level 16</IonSelectOption>
                                            <IonSelectOption value="Level 17">Level 17</IonSelectOption>
                                            <IonSelectOption value="Level 18">Level 18</IonSelectOption>
                                        </IonSelect>
                                    </div>
                                    <div className='scenario-Planner-Selection-Style-Stage'>
                                        <IonSelect id='day-Selection' interface="popover" placeholder="Day" value={scenarioDayView} onIonChange={(e: any) => setScenarioDayView(e.detail.value)}>
                                            <IonSelectOption value="Monday">Monday</IonSelectOption>
                                            <IonSelectOption value="Tuesday">Tuesday</IonSelectOption>
                                            <IonSelectOption value="Wednesday">Wednesday</IonSelectOption>
                                            <IonSelectOption value="Thursday">Thursday</IonSelectOption>
                                            <IonSelectOption value="Friday">Friday</IonSelectOption>
                                            <IonSelectOption value="Saturday">Saturday</IonSelectOption>
                                            <IonSelectOption value="Sunday">Sunday</IonSelectOption>
                                        </IonSelect>
                                    </div>
                                    <div className='scenario-Planner-Selection-Style-Stage'>
                                        <IonSelect id='time-Selection' interface="popover" placeholder="Time" value={scenarioTimeView} onIonChange={(e: any) => setScenarioTimeView(e.detail.value)}>
                                            <IonSelectOption value="00:30 - 03:30">00:30 - 03:30</IonSelectOption>
                                            <IonSelectOption value="03:30 - 06:30">03:30 - 06:30</IonSelectOption>
                                            <IonSelectOption value="06:30 - 09:30">06:30 - 09:30</IonSelectOption>
                                            <IonSelectOption value="09:30 - 12:30">09:30 - 12:30</IonSelectOption>
                                            <IonSelectOption value="12:30 - 15:30">12:30 - 15:30</IonSelectOption>
                                            <IonSelectOption value="15:30 - 18:30">15:30 - 18:30</IonSelectOption>
                                            <IonSelectOption value="18:30 - 21:30">18:30 - 21:30</IonSelectOption>
                                            <IonSelectOption value="21:30 - 00:30">21:30 - 00:30</IonSelectOption>
                                        </IonSelect>
                                    </div>
                                </div>
                                <div className='scenario-Buttons-Style'>
                                    <IonButton className='note-Button-Red' shape="round" onClick={() => scenarioPlanner()} >Cancel</IonButton>
                                    <IonButton className='note-Button-Green' shape="round" onClick={() => scenarioPlanChosen()} >View Scenario</IonButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ScenarioComponent
import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCheckbox, IonDatetime, IonDatetimeButton, IonIcon, IonLabel, IonModal, IonSelect, IonSelectOption, IonTextarea } from "@ionic/react";
import { JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect } from "react";
import { BASE_URL } from '../../../mapContentComponent/mapContentComponent';
import { ACCESS_CREDENTIALS } from "../../../../pages/Home";
import './DisplayNoteComponent.css';
import { chevronDownCircle, closeCircleOutline, create, personCircleOutline } from "ionicons/icons";
import { SidebarState, modalState, mouseState } from "../../../../enums/enums";
import { AnyLayer } from "mapbox-gl";


type DisplayNoteProps = {


    resiliencePartner: any;
    publicUserNotes: any;
    partnersUserNotes: any;
    SSENUserNotes: any;
    publicNonUserNotes: any;
    partnersNonUserNotes: any;
    SSENNonUserNotes: any;
    organisationArray: any;

    setShowPublicNoteButtonClick: any;


    setShowPartnerNoteButtonClick: any;


    setShowSSENNoteButtonClick: any;


    setShowPublicNonUserNoteButtonClick: any;


    setShowPartnerNonUserNoteButtonClick: any;


    setShowSSENNonUserNoteButtonClick: any;

    setDeletePublicNoteButtonClick: any;


    setDeletePartnerNoteButtonClick: any;


    setDeleteSSENNoteButtonClick: any;

    setEditPublicNoteButtonClick: any;

    setEditPartnerNoteButtonClick: any;

    setEditSSENNoteButtonClick: any;

    modalVisibility: any;

    mouseAppearance: any;

    setNoteToHighlight: any;

    setNoteMarkerType: any;

    setmapOrSidebarClick: any;

    setUpdateNotes: any;

    highlightLastNote: any;

    notesArray: any;

    setHighlightLastNote: any;

    organisationPublicArrayNo: any;


    organisationPartnerArrayNo: any;


    organisationSSENArrayNo: any;

    setSidebarExpanded: any;

    extendedNotesArray: any;
    setExtendedNotesArray: any;
}


const DisplayNoteComponent: React.FC<DisplayNoteProps> = ({

    resiliencePartner,
    publicUserNotes, partnersUserNotes,
    SSENUserNotes, publicNonUserNotes,
    partnersNonUserNotes, SSENNonUserNotes,
    organisationArray,
    setShowPublicNoteButtonClick,


    setShowPartnerNoteButtonClick,


    setShowSSENNoteButtonClick,


    setShowPublicNonUserNoteButtonClick,


    setShowPartnerNonUserNoteButtonClick,


    setShowSSENNonUserNoteButtonClick,

    setDeletePublicNoteButtonClick,


    setDeletePartnerNoteButtonClick,


    setDeleteSSENNoteButtonClick,

    setEditPublicNoteButtonClick,

    setEditPartnerNoteButtonClick,

    setEditSSENNoteButtonClick,

    modalVisibility,

    mouseAppearance,
    setNoteToHighlight,

    setNoteMarkerType,

    setmapOrSidebarClick,
    setUpdateNotes,

    highlightLastNote,

    notesArray,

    setHighlightLastNote,

    organisationPublicArrayNo,


    organisationPartnerArrayNo,


    organisationSSENArrayNo,

    setSidebarExpanded,

    extendedNotesArray,
    setExtendedNotesArray

}) => {

    let noteNoHolder: any;
    noteNoHolder = 0;
    let valueHolder: any;
    valueHolder = 0;
    let mappingCount: any;
    mappingCount = 0;

    let PR_Note_Non_User_Extend: any;

    let chevronDirectionNonUser: any;
    let PR_Note_User_Extend: any;

    let chevronDirectionUser: any;

    let Card_Design: any;

    let tempArrayForNoteExtend: any;

    function ExtendNotes(PRNumber: any) { // Function to extend/minimise the card used to diplay notes, called when the user clicks the chevrons next to the organisation at the top of the note card. Same function for both user organisation notes and other notes. 
        tempArrayForNoteExtend = extendedNotesArray;
        if (PRNumber > 0) {
            // Runs this code if user extends/minimises note from other organisation. 
            PR_Note_Non_User_Extend = document.getElementById('note-Non-User-' + PRNumber);
            chevronDirectionNonUser = document.getElementById('chevronDownCircle' + PRNumber);
            if (PR_Note_Non_User_Extend.style.height == '72px') {
                PR_Note_Non_User_Extend.style.height = 'auto';
                chevronDirectionNonUser.style.transform = 'rotate(180deg)';
                tempArrayForNoteExtend.push(PR_Note_Non_User_Extend.id)
                setExtendedNotesArray(tempArrayForNoteExtend);
            } else if (PR_Note_Non_User_Extend.style.height == 'auto') {
                PR_Note_Non_User_Extend.style.height = '72px';
                chevronDirectionNonUser.style.transform = 'none';
            }
        } else {
            // Runds this code if the note was written by the same organisation currently logged in. 
            PR_Note_User_Extend = document.getElementById('note-Test-Perm-Fix');
            chevronDirectionUser = document.getElementById('chevronDownCircle');
            if (PR_Note_User_Extend.style.height == '72px') {
                PR_Note_User_Extend.style.height = 'auto';
                chevronDirectionUser.style.transform = 'rotate(180deg)';
                tempArrayForNoteExtend.push(PR_Note_User_Extend.id)
                setExtendedNotesArray(tempArrayForNoteExtend);
            } else if (PR_Note_User_Extend.style.height == 'auto') {
                PR_Note_User_Extend.style.height = '72px';
                chevronDirectionUser.style.transform = 'none';
            }
        }
    }

    function publicNotesExtend(PRNumber: any) { // Function to extend/minimise the card used to diplay notes, called when the user clicks the chevrons next to the organisation at the top of the note card. Same function for both user organisation notes and other notes. 
        if (PRNumber > 0) {
            // Runs this code if user extends/minimises note from other organisation. 
            PR_Note_Non_User_Extend = document.getElementById('note-Non-User-Public-' + PRNumber);
            chevronDirectionNonUser = document.getElementById('chevronDownCircle-Public-' + PRNumber);
            if (PR_Note_Non_User_Extend.style.height == '72px') {
                PR_Note_Non_User_Extend.style.height = 'auto';
                chevronDirectionNonUser.style.transform = 'rotate(180deg)';
            } else if (PR_Note_Non_User_Extend.style.height == 'auto') {
                PR_Note_Non_User_Extend.style.height = '72px';
                chevronDirectionNonUser.style.transform = 'none';
            }
        } else {
            // Runds this code if the note was written by the same organisation currently logged in. 
            PR_Note_User_Extend = document.getElementById('note-Public');
            chevronDirectionUser = document.getElementById('chevronDownCircle-Public');
            if (PR_Note_User_Extend.style.height == '72px') {
                PR_Note_User_Extend.style.height = 'auto';
                chevronDirectionUser.style.transform = 'rotate(180deg)';
            } else if (PR_Note_User_Extend.style.height == 'auto') {
                PR_Note_User_Extend.style.height = '72px';
                chevronDirectionUser.style.transform = 'none';
            }
        }
    }

    function partnerNotesExtend(PRNumber: any) { // Function to extend/minimise the card used to diplay notes, called when the user clicks the chevrons next to the organisation at the top of the note card. Same function for both user organisation notes and other notes. 
        if (PRNumber > 0) {
            // Runs this code if user extends/minimises note from other organisation. 
            PR_Note_Non_User_Extend = document.getElementById('note-Non-User-Partners-' + PRNumber);
            chevronDirectionNonUser = document.getElementById('chevronDownCircle-Partners-' + PRNumber);
            if (PR_Note_Non_User_Extend.style.height == '72px') {
                PR_Note_Non_User_Extend.style.height = 'auto';
                chevronDirectionNonUser.style.transform = 'rotate(180deg)';
            } else if (PR_Note_Non_User_Extend.style.height == 'auto') {
                PR_Note_Non_User_Extend.style.height = '72px';
                chevronDirectionNonUser.style.transform = 'none';
            }
        } else {
            // Runds this code if the note was written by the same organisation currently logged in. 
            PR_Note_User_Extend = document.getElementById('note-Partners');
            chevronDirectionUser = document.getElementById('chevronDownCircle-Partners');
            if (PR_Note_User_Extend.style.height == '72px') {
                PR_Note_User_Extend.style.height = 'auto';
                chevronDirectionUser.style.transform = 'rotate(180deg)';
            } else if (PR_Note_User_Extend.style.height == 'auto') {
                PR_Note_User_Extend.style.height = '72px';
                chevronDirectionUser.style.transform = 'none';
            }
        }
    }

    function SSENNotesExtend(PRNumber: any) { // Function to extend/minimise the card used to diplay notes, called when the user clicks the chevrons next to the organisation at the top of the note card. Same function for both user organisation notes and other notes. 
        if (PRNumber > 0) {
            // Runs this code if user extends/minimises note from other organisation. 
            PR_Note_Non_User_Extend = document.getElementById('note-Non-User-SSEN-' + PRNumber);
            chevronDirectionNonUser = document.getElementById('chevronDownCircle-SSEN-' + PRNumber);
            if (PR_Note_Non_User_Extend.style.height == '72px') {
                PR_Note_Non_User_Extend.style.height = 'auto';
                chevronDirectionNonUser.style.transform = 'rotate(180deg)';
            } else if (PR_Note_Non_User_Extend.style.height == 'auto') {
                PR_Note_Non_User_Extend.style.height = '72px';
                chevronDirectionNonUser.style.transform = 'none';
            }
        } else {
            // Runds this code if the note was written by the same organisation currently logged in. 
            PR_Note_User_Extend = document.getElementById('note-SSEN');
            chevronDirectionUser = document.getElementById('chevronDownCircle-SSEN');
            if (PR_Note_User_Extend.style.height == '72px') {
                PR_Note_User_Extend.style.height = 'auto';
                chevronDirectionUser.style.transform = 'rotate(180deg)';
            } else if (PR_Note_User_Extend.style.height == 'auto') {
                PR_Note_User_Extend.style.height = '72px';
                chevronDirectionUser.style.transform = 'none';
            }
        }
    }















    function HighlightNoteUser(hightlightNumber: any) { // Function to highlight note, called by either clicking on note on the sisdebar or by clicking on a marker on the map. This function handles notes written by the current logged in organisation.
        if (modalVisibility === 1) {
            if (mouseAppearance === 0) {
            } else {
                let tempHighlightPublicArray = publicUserNotes;
                let tempHighlightPartnerArray = partnersUserNotes;
                let tempHighlightSSENArray = SSENUserNotes;


                let tempHighlightPublicNonUserArray = publicNonUserNotes;
                let tempHighlightPartnerNonUserArray = partnersNonUserNotes;
                let tempHighlightSSENNonUserArray = SSENNonUserNotes;



                tempHighlightPublicArray.map(function (note: any) {
                    if (note.index == hightlightNumber) {
                        setNoteToHighlight(note.note_Location);
                        setNoteMarkerType("User");
                        setmapOrSidebarClick("Sidebar");
                        Card_Design = document.getElementById('Card-' + note.index);
                        Card_Design.style.border = "solid #FFFFFF 2.5px";
                        Card_Design.style.background = "#64A1BB";
                        Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
                    } else {
                        Card_Design = document.getElementById('Card-' + note.index);
                        Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                        Card_Design.style.background = "rgb(59, 107, 139)";
                    }
                })

                tempHighlightPartnerArray.map(function (note: any) {
                    if (note.index == hightlightNumber) {
                        setNoteToHighlight(note.note_Location);
                        setNoteMarkerType("User");
                        setmapOrSidebarClick("Sidebar");
                        Card_Design = document.getElementById('Card-' + note.index);
                        Card_Design.style.border = "solid #FFFFFF 2.5px";
                        Card_Design.style.background = "#64A1BB";
                        Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
                    } else {
                        Card_Design = document.getElementById('Card-' + note.index);
                        Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                        Card_Design.style.background = "rgb(59, 107, 139)";
                    }
                })

                tempHighlightSSENArray.map(function (note: any) {
                    if (note.index == hightlightNumber) {
                        setNoteToHighlight(note.note_Location);
                        setNoteMarkerType("User");
                        setmapOrSidebarClick("Sidebar");
                        Card_Design = document.getElementById('Card-' + note.index);
                        Card_Design.style.border = "solid #FFFFFF 2.5px";
                        Card_Design.style.background = "#64A1BB";
                        Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
                    } else {
                        Card_Design = document.getElementById('Card-' + note.index);
                        Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                        Card_Design.style.background = "rgb(59, 107, 139)";
                    }
                })

                tempHighlightPublicNonUserArray.map(function (note: any) {
                    Card_Design = document.getElementById('Card-NonUser-' + note.index);
                    Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                    Card_Design.style.background = "rgb(59, 107, 139)";
                })

                tempHighlightPartnerNonUserArray.map(function (note: any) {
                    Card_Design = document.getElementById('Card-NonUser-' + note.index);
                    Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                    Card_Design.style.background = "rgb(59, 107, 139)";
                })

                tempHighlightSSENNonUserArray.map(function (note: any) {
                    Card_Design = document.getElementById('Card-NonUser-' + note.index);
                    Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                    Card_Design.style.background = "rgb(59, 107, 139)";
                })
            }
        }
        setUpdateNotes("Update");
    }

    function HighlightNoteNonUser(hightlightNumber: any) { // Function to highlight note, called by either clicking on note on the sidebar or by clicking on a marker on the map. This function handles notes written by the other organisations that use RLD partner layer.
        if (modalVisibility === 1) {
            if (mouseAppearance === 0) {
            } else {

                let tempHighlightPublicArray = publicUserNotes;
                let tempHighlightPartnerArray = partnersUserNotes;
                let tempHighlightSSENArray = SSENUserNotes;


                let tempHighlightPublicNonUserArray = publicNonUserNotes;
                let tempHighlightPartnerNonUserArray = partnersNonUserNotes;
                let tempHighlightSSENNonUserArray = SSENNonUserNotes;


                tempHighlightPublicNonUserArray.map(function (note: any) {
                    if (note.index === hightlightNumber) {
                        setNoteToHighlight(note.note_Location);
                        setNoteMarkerType("Non-User");
                        setmapOrSidebarClick("Sidebar");
                        Card_Design = document.getElementById('Card-NonUser-' + note.index);
                        Card_Design.style.border = "solid #FFFFFF 2.5px";
                        Card_Design.style.background = "#64A1BB";
                        Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
                    } else {
                        Card_Design = document.getElementById('Card-NonUser-' + note.index);
                        Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                        Card_Design.style.background = "rgb(59, 107, 139)";
                    }
                })


                tempHighlightPartnerNonUserArray.map(function (note: any) {
                    if (note.index === hightlightNumber) {
                        setNoteToHighlight(note.note_Location);
                        setNoteMarkerType("Non-User");
                        setmapOrSidebarClick("Sidebar");
                        Card_Design = document.getElementById('Card-NonUser-' + note.index);
                        Card_Design.style.border = "solid #FFFFFF 2.5px";
                        Card_Design.style.background = "#64A1BB";
                        Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
                    } else {
                        Card_Design = document.getElementById('Card-NonUser-' + note.index);
                        Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                        Card_Design.style.background = "rgb(59, 107, 139)";
                    }
                })


                tempHighlightSSENNonUserArray.map(function (note: any) {
                    if (note.index === hightlightNumber) {
                        setNoteToHighlight(note.note_Location);
                        setNoteMarkerType("Non-User");
                        setmapOrSidebarClick("Sidebar");
                        Card_Design = document.getElementById('Card-NonUser-' + note.index);
                        Card_Design.style.border = "solid #FFFFFF 2.5px";
                        Card_Design.style.background = "#64A1BB";
                        Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
                    } else {
                        Card_Design = document.getElementById('Card-NonUser-' + note.index);
                        Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                        Card_Design.style.background = "rgb(59, 107, 139)";
                    }
                })

                tempHighlightPublicArray.map(function (note: any) {
                    Card_Design = document.getElementById('Card-' + note.index);
                    Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                    Card_Design.style.background = "rgb(59, 107, 139)";
                })

                tempHighlightPartnerArray.map(function (note: any) {
                    Card_Design = document.getElementById('Card-' + note.index);
                    Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                    Card_Design.style.background = "rgb(59, 107, 139)";
                })

                tempHighlightSSENArray.map(function (note: any) {
                    Card_Design = document.getElementById('Card-' + note.index);
                    Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                    Card_Design.style.background = "rgb(59, 107, 139)";
                })
            }
        }
    }


    useEffect(() => { // Handles moving of highlighted note when a new note is added. 
        if (highlightLastNote != 0) {
            Card_Design = document.getElementById('Card-' + (notesArray.length - 1));
            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
            Card_Design.style.background = "rgb(59, 107, 139)";

            Card_Design = document.getElementById('Card-' + notesArray.length);
            Card_Design.style.border = "2.5px solid rgb(255, 255, 255)";
            Card_Design.style.background = "rgb(100, 161, 187)";
            Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
            setHighlightLastNote(0);
        }
    }, [highlightLastNote])


    return (
        <>

            <div id='note-Container' className='note-Container'>

                <IonCard className='note' id='note-Test-Perm-Fix' style={{ height: '72px' }}>

                    <div>
                        <IonCard className='note-Header'>
                            <h1 className='note-Header-Text' style={{ display: 'flex' }}>
                                <IonIcon slot='icon-only' className='icon-Only' icon={personCircleOutline} style={{ color: "white" }} />
                                <span>{resiliencePartner} [{SSENUserNotes.length + publicUserNotes.length + partnersUserNotes.length}]</span>
                                <IonIcon slot='icon-only' id='chevronDownCircle' onClick={() => ExtendNotes(0)} className='icon-Only' icon={chevronDownCircle} /></h1></IonCard>
                    </div>


                    <IonCard className='note' id='note-Public' style={{ height: '72px' }}>

                        <div>
                            <IonCard className='note-Header'>
                                <h1 className='note-Header-Text' style={{ display: 'flex' }}>
                                    <IonIcon slot='icon-only' className='icon-Only' icon={personCircleOutline} style={{ color: "white" }} />
                                    <span>Public [{publicUserNotes.length}]</span>
                                    <IonIcon slot='icon-only' id='chevronDownCircle-Public' onClick={() => publicNotesExtend(0)} className='icon-Only' icon={chevronDownCircle} /></h1></IonCard>
                        </div>


                        {
                            publicUserNotes.map(function (note: any) {
                                let noteId: any;
                                let cardId: any;
                                let contentId: any;
                                let showButtonId: any;
                                let deleteButtonId: any;
                                let editButtonId: any;
                                let buttonNumber: any;
                                let noteContent: any;
                                noteId = "RP-Note-" + note.index;
                                cardId = "Card-" + note.index;
                                contentId = "Content-" + note.index;
                                showButtonId = "Show-Button-" + note.index;
                                deleteButtonId = "Delete-Button-" + note.index;
                                editButtonId = "Edit-Button-" + note.index;
                                buttonNumber = note.index;
                                if (note.note_Content.length > 122) {
                                    noteContent = note.note_Content.substring(0, 122);
                                    noteContent = noteContent + "...";
                                } else {
                                    noteContent = note.note_Content;
                                }
                                return <div id={noteId}>
                                    <IonCard id={cardId} className='note-Card' onClick={() => HighlightNoteUser(buttonNumber)}>
                                        <div className="note-Content-Positioning">
                                            <IonCardContent className='note-Content-Text' id={contentId}>
                                                {renderNoteContent(noteContent)}
                                            </IonCardContent>
                                        </div>
                                        <div className="note-Button-Positioning">
                                            <div className='note-Delete-Edit-Button-Placement'>
                                                <IonButton className='note-Button-Delete' id={deleteButtonId} shape="round" onClick={() => setDeletePublicNoteButtonClick(buttonNumber)} >Delete</IonButton>
                                                <IonButton className='note-Button-Edit' id={editButtonId} shape="round" onClick={() => setEditPublicNoteButtonClick(buttonNumber)} >Edit</IonButton>
                                                <IonButton className='note-Button-Show' id={showButtonId} shape="round" onClick={() => setShowPublicNoteButtonClick(buttonNumber)} >View</IonButton>
                                            </div>
                                            <IonCardContent className='note-Content-Time'>
                                                {renderNoteCreation(note)}
                                            </IonCardContent>
                                        </div>
                                    </IonCard>
                                </div>
                                function renderNoteContent(noteContent: any) {
                                    return <div>{noteContent}</div>;
                                }
                                function renderNoteCreation(noteContent: any) {
                                    return <div>{noteContent.note_Creation}</div>;
                                }
                            })
                        }

                    </IonCard>

                    <IonCard className='note' id='note-Partners' style={{ height: '72px' }}>

                        <div>
                            <IonCard className='note-Header'>
                                <h1 className='note-Header-Text' style={{ display: 'flex' }}>
                                    <IonIcon slot='icon-only' className='icon-Only' icon={personCircleOutline} style={{ color: "white" }} />
                                    <span>Partners [{partnersUserNotes.length}]</span>
                                    <IonIcon slot='icon-only' id='chevronDownCircle-Partners' onClick={() => partnerNotesExtend(0)} className='icon-Only' icon={chevronDownCircle} /></h1></IonCard>
                        </div>


                        {
                            partnersUserNotes.map(function (note: any) {

                                let noteId: any;
                                let cardId: any;
                                let contentId: any;
                                let showButtonId: any;
                                let deleteButtonId: any;
                                let editButtonId: any;
                                let buttonNumber: any;
                                let noteContent: any;
                                noteId = "RP-Note-" + note.index;
                                cardId = "Card-" + note.index;
                                contentId = "Content-" + note.index;
                                showButtonId = "Show-Button-" + note.index;
                                deleteButtonId = "Delete-Button-" + note.index;
                                editButtonId = "Edit-Button-" + note.index;
                                buttonNumber = note.index;
                                if (note.note_Content.length > 122) {
                                    noteContent = note.note_Content.substring(0, 122);
                                    noteContent = noteContent + "...";
                                } else {
                                    noteContent = note.note_Content;
                                }
                                return <div id={noteId}>
                                    <IonCard id={cardId} className='note-Card' onClick={() => HighlightNoteUser(buttonNumber)}>
                                        <div className="note-Content-Positioning">
                                            <IonCardContent className='note-Content-Text' id={contentId}>
                                                {renderNoteContent(noteContent)}
                                            </IonCardContent>
                                        </div>
                                        <div className="note-Button-Positioning">
                                            <div className='note-Delete-Edit-Button-Placement'>
                                                <IonButton className='note-Button-Delete' id={deleteButtonId} shape="round" onClick={() => setDeletePartnerNoteButtonClick(buttonNumber)} >Delete</IonButton>
                                                <IonButton className='note-Button-Edit' id={editButtonId} shape="round" onClick={() => setEditPartnerNoteButtonClick(buttonNumber)} >Edit</IonButton>
                                                <IonButton className='note-Button-Show' id={showButtonId} shape="round" onClick={() => setShowPartnerNoteButtonClick(buttonNumber)} >View</IonButton>
                                            </div>
                                            <IonCardContent className='note-Content-Time'>
                                                {renderNoteCreation(note)}
                                            </IonCardContent>
                                        </div>
                                    </IonCard>
                                </div>
                                function renderNoteContent(noteContent: any) {
                                    return <div>{noteContent}</div>;
                                }
                                function renderNoteCreation(noteContent: any) {
                                    return <div>{noteContent.note_Creation}</div>;
                                }
                            })
                        }

                    </IonCard>
                    <IonCard className='note' id='note-SSEN' style={{ height: '72px' }}>

                        <div>
                            <IonCard className='note-Header'>
                                <h1 className='note-Header-Text' style={{ display: 'flex' }}>
                                    <IonIcon slot='icon-only' className='icon-Only' icon={personCircleOutline} style={{ color: "white" }} />
                                    <span>SSEN [{SSENUserNotes.length}]</span>
                                    <IonIcon slot='icon-only' id='chevronDownCircle-SSEN' onClick={() => SSENNotesExtend(0)} className='icon-Only' icon={chevronDownCircle} /></h1></IonCard>
                        </div>


                        {

                            SSENUserNotes.map(function (note: any) {
                                let noteId: any;
                                let cardId: any;
                                let contentId: any;
                                let showButtonId: any;
                                let deleteButtonId: any;
                                let editButtonId: any;
                                let buttonNumber: any;
                                let noteContent: any;
                                noteId = "RP-Note-" + note.index;
                                cardId = "Card-" + note.index;
                                contentId = "Content-" + note.index;
                                showButtonId = "Show-Button-" + note.index;
                                deleteButtonId = "Delete-Button-" + note.index;
                                editButtonId = "Edit-Button-" + note.index;
                                buttonNumber = note.index;
                                if (note.note_Content.length > 122) {
                                    noteContent = note.note_Content.substring(0, 122);
                                    noteContent = noteContent + "...";
                                } else {
                                    noteContent = note.note_Content;
                                }
                                return <div id={noteId}>
                                    <IonCard id={cardId} className='note-Card' onClick={() => HighlightNoteUser(buttonNumber)}>
                                        <div className="note-Content-Positioning">
                                            <IonCardContent className='note-Content-Text' id={contentId}>
                                                {renderNoteContent(noteContent)}
                                            </IonCardContent>
                                        </div>
                                        <div className="note-Button-Positioning">
                                            <div className='note-Delete-Edit-Button-Placement'>
                                                <IonButton className='note-Button-Delete' id={deleteButtonId} shape="round" onClick={() => setDeleteSSENNoteButtonClick(buttonNumber)} >Delete</IonButton>
                                                <IonButton className='note-Button-Edit' id={editButtonId} shape="round" onClick={() => setEditSSENNoteButtonClick(buttonNumber)} >Edit</IonButton>
                                                <IonButton className='note-Button-Show' id={showButtonId} shape="round" onClick={() => setShowSSENNoteButtonClick(buttonNumber)} >View</IonButton>
                                            </div>
                                            <IonCardContent className='note-Content-Time'>
                                                {renderNoteCreation(note)}
                                            </IonCardContent>
                                        </div>
                                    </IonCard>
                                </div>
                                function renderNoteContent(noteContent: any) {
                                    return <div>{noteContent}</div>;
                                }
                                function renderNoteCreation(noteContent: any) {
                                    return <div>{noteContent.note_Creation}</div>;
                                }
                            })
                        }

                    </IonCard>

                </IonCard>

                {
                    organisationArray.map(function (organisation: any, index: number) {
                        index++;

                        let id = "note-Non-User-" + index
                        let iconID = "chevronDownCircle" + index

                        let Pub_id = "note-Non-User-Public-" + index
                        let pub_iconID = "chevronDownCircle-Public-" + index

                        let Partners_id = "note-Non-User-Partners-" + index
                        let Partners_iconID = "chevronDownCircle-Partners-" + index

                        let SSEN_id = "note-Non-User-SSEN-" + index
                        let SSEN_iconID = "chevronDownCircle-SSEN-" + index

                        let tempNumberHolder = 0;

                        return <IonCard className='note' id={id} style={{ height: '72px' }}>

                            <div>
                                <IonCard className='note-Header'>
                                    <h1 className='note-Header-Text' style={{ display: 'flex' }}>
                                        <IonIcon slot='icon-only' className='icon-Only' icon={personCircleOutline} style={{ color: "white" }} />
                                        <span>{organisation}
                                            {
                                                organisationPublicArrayNo.map(function (notePublicNo: any) {
                                                    if (notePublicNo[0] === organisation) {
                                                        tempNumberHolder = tempNumberHolder + notePublicNo[1];
                                                    }
                                                })
                                            }
                                            {
                                                organisationPartnerArrayNo.map(function (notePartnerNo: any) {
                                                    if (notePartnerNo[0] === organisation) {
                                                        tempNumberHolder = tempNumberHolder + notePartnerNo[1];
                                                    }
                                                })
                                            }
                                            {
                                                organisationSSENArrayNo.map(function (noteSSENNo: any) {
                                                    if (noteSSENNo[0] === organisation) {
                                                        tempNumberHolder = tempNumberHolder + noteSSENNo[1];
                                                    }
                                                })
                                            }
                                            {
                                                <span> [{tempNumberHolder}]</span>
                                            }

                                        </span>
                                        <IonIcon slot='icon-only' id={iconID} onClick={() => ExtendNotes(index)} className='icon-Only' icon={chevronDownCircle} /></h1></IonCard>
                            </div>

                            <IonCard className='note' id={Pub_id} style={{ height: '72px' }}>

                                <div>
                                    <IonCard className='note-Header'>
                                        <h1 className='note-Header-Text' style={{ display: 'flex' }}>
                                            <IonIcon slot='icon-only' className='icon-Only' icon={personCircleOutline} style={{ color: "white" }} />
                                            <span>Public
                                                {
                                                    organisationPublicArrayNo.length !== 0 ?
                                                        organisationPublicArrayNo.map(function (notePartnerNo: any) {
                                                            if (notePartnerNo[0] === organisation) {
                                                                valueHolder = valueHolder + 1;
                                                                noteNoHolder = notePartnerNo[1];
                                                            }
                                                            mappingCount = mappingCount + 1;
                                                            if (valueHolder == 1) {
                                                                valueHolder = 0;
                                                                mappingCount = 0;
                                                                return <span> [{noteNoHolder}]</span>;
                                                            }
                                                            else if (organisationPublicArrayNo.length == 1) {
                                                                noteNoHolder = 0;
                                                                mappingCount = 0;
                                                                return <span> [{0}]</span>;
                                                            }
                                                            else if (mappingCount == organisationPublicArrayNo.length && noteNoHolder == 0) {
                                                                noteNoHolder = 0;
                                                                mappingCount = 0;
                                                                return <span> [{0}]</span>;
                                                            }





                                                        }) : <span> [{0}]</span>
                                                }
                                            </span>
                                            <IonIcon slot='icon-only' id={pub_iconID} onClick={() => publicNotesExtend(index)} className='icon-Only' icon={chevronDownCircle} /></h1></IonCard>
                                </div>

                                {
                                    publicNonUserNotes.map(function (note: any) {
                                        if (note.note_Organisation === organisation) {
                                            let noteId: any;
                                            let cardId: any;
                                            let showButtonId: any;
                                            let buttonNumber: any;
                                            let noteContent: any;
                                            noteId = "RP-Note-NonUser-" + note.index;
                                            cardId = "Card-NonUser-" + note.index;
                                            showButtonId = "Button-Show-NonUser-" + note.index;
                                            buttonNumber = note.index
                                            if (note.note_Content.length > 82) {
                                                noteContent = note.note_Content.substring(0, 82);
                                                noteContent = noteContent + "...";
                                            } else {
                                                noteContent = note.note_Content;
                                            }
                                            return <div id={noteId}>
                                                <IonCard id={cardId} className='note-Card' onClick={() => HighlightNoteNonUser(buttonNumber)}>
                                                    <div className="nonUser-Note-Content">
                                                        <IonCardContent className='note-Content-Text'>
                                                            {renderNoteContent(noteContent)}
                                                        </IonCardContent>
                                                    </div>
                                                    <div className="note-Button-Positioning">
                                                        <IonButton className='note-Button-Show' id={showButtonId} shape="round" onClick={() => setShowPublicNonUserNoteButtonClick(buttonNumber)} >View</IonButton>
                                                        <IonCardContent className='note-Content-Time-NonUser'>
                                                            {renderNoteCreation(note)}
                                                        </IonCardContent>
                                                    </div>
                                                </IonCard>
                                            </div>
                                        }
                                        function renderNoteContent(noteContent: any) {
                                            return <div>{noteContent}</div>;
                                        }
                                        function renderNoteCreation(noteContent: any) {
                                            return <div>{noteContent.note_Creation}</div>;
                                        }
                                    })
                                }


                            </IonCard>
                            <IonCard className='note' id={Partners_id} style={{ height: '72px' }}>

                                <div>
                                    <IonCard className='note-Header'>
                                        <h1 className='note-Header-Text' style={{ display: 'flex' }}>
                                            <IonIcon slot='icon-only' className='icon-Only' icon={personCircleOutline} style={{ color: "white" }} />
                                            <span>Partner  {
                                                organisationPartnerArrayNo.length !== 0 ?
                                                    organisationPartnerArrayNo.map(function (notePartnerNo: any) {
                                                        if (notePartnerNo[0] === organisation) {
                                                            valueHolder = valueHolder + 1;
                                                            noteNoHolder = notePartnerNo[1];
                                                        }
                                                        mappingCount = mappingCount + 1;
                                                        if (valueHolder == 1) {
                                                            valueHolder = 0;
                                                            mappingCount = 0;
                                                            return <span> [{noteNoHolder}]</span>;
                                                        }
                                                        else if (organisationPartnerArrayNo.length == 1) {
                                                            noteNoHolder = 0;
                                                            mappingCount = 0;
                                                            return <span> [{0}]</span>;
                                                        }
                                                        else if (mappingCount == organisationPartnerArrayNo.length && noteNoHolder == 0) {
                                                            noteNoHolder = 0;
                                                            mappingCount = 0;
                                                            return <span> [{0}]</span>;
                                                        }





                                                    }) : <span> [{0}]</span>
                                            }</span>
                                            <IonIcon slot='icon-only' id={Partners_iconID} onClick={() => partnerNotesExtend(index)} className='icon-Only' icon={chevronDownCircle} /></h1></IonCard>
                                </div>

                                {
                                    partnersNonUserNotes.map(function (note: any) {
                                        if (note.note_Organisation == organisation) {
                                            let noteId: any;
                                            let cardId: any;
                                            let showButtonId: any;
                                            let buttonNumber: any;
                                            let noteContent: any;
                                            noteId = "RP-Note-NonUser-" + note.index;
                                            cardId = "Card-NonUser-" + note.index;
                                            showButtonId = "Button-Show-NonUser-" + note.index;
                                            buttonNumber = note.index
                                            if (note.note_Content.length > 82) {
                                                noteContent = note.note_Content.substring(0, 82);
                                                noteContent = noteContent + "...";
                                            } else {
                                                noteContent = note.note_Content;
                                            }
                                            return <div id={noteId}>
                                                <IonCard id={cardId} className='note-Card' onClick={() => HighlightNoteNonUser(buttonNumber)}>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <IonCardContent className='note-Content-Text'>
                                                            {renderNoteContent(noteContent)}
                                                        </IonCardContent>
                                                    </div>
                                                    <div className="note-Button-Positioning">
                                                        <IonButton className='note-Button-Show' id={showButtonId} shape="round" onClick={() => setShowPartnerNonUserNoteButtonClick(buttonNumber)} >View</IonButton>
                                                        <IonCardContent className='note-Content-Time-NonUser'>
                                                            {renderNoteCreation(note)}
                                                        </IonCardContent>
                                                    </div>
                                                </IonCard>
                                            </div>
                                        }
                                        function renderNoteContent(noteContent: any) {
                                            return <div>{noteContent}</div>;
                                        }
                                        function renderNoteCreation(noteContent: any) {
                                            return <div>{noteContent.note_Creation}</div>;
                                        }
                                    })
                                }


                            </IonCard>

                            <IonCard className='note' id={SSEN_id} style={{ height: '72px' }}>

                                <div>
                                    <IonCard className='note-Header'>
                                        <h1 className='note-Header-Text' style={{ display: 'flex' }}>
                                            <IonIcon slot='icon-only' className='icon-Only' icon={personCircleOutline} style={{ color: "white" }} />
                                            <span>SSEN {
                                                organisationSSENArrayNo.length !== 0 ?
                                                    organisationSSENArrayNo.map(function (noteSSENNo: any) {
                                                        if (noteSSENNo[0] === organisation) {
                                                            valueHolder = valueHolder + 1;
                                                            noteNoHolder = noteSSENNo[1];
                                                        }
                                                        mappingCount = mappingCount + 1;
                                                        if (valueHolder == 1) {
                                                            valueHolder = 0;
                                                            mappingCount = 0;
                                                            return <span> [{noteNoHolder}]</span>;
                                                        }
                                                        else if (organisationSSENArrayNo.length == 1) {
                                                            noteNoHolder = 0;
                                                            mappingCount = 0;
                                                            return <span> [{0}]</span>;
                                                        }
                                                        else if (mappingCount == organisationSSENArrayNo.length && noteNoHolder == 0) {
                                                            noteNoHolder = 0;
                                                            mappingCount = 0;
                                                            return <span> [{0}]</span>;
                                                        }
                                                    }) : <span> [{0}]</span>
                                            }</span>
                                            <IonIcon slot='icon-only' id={SSEN_iconID} onClick={() => SSENNotesExtend(index)} className='icon-Only' icon={chevronDownCircle} /></h1></IonCard>
                                </div>

                                {
                                    SSENNonUserNotes.map(function (note: any) {
                                        if (note.note_Organisation == organisation) {
                                            let noteId: any;
                                            let cardId: any;
                                            let showButtonId: any;
                                            let buttonNumber: any;
                                            let noteContent: any;
                                            noteId = "RP-Note-NonUser-" + note.index;
                                            cardId = "Card-NonUser-" + note.index;
                                            showButtonId = "Button-Show-NonUser-" + note.index;
                                            buttonNumber = note.index
                                            if (note.note_Content.length > 82) {
                                                noteContent = note.note_Content.substring(0, 82);
                                                noteContent = noteContent + "...";
                                            } else {
                                                noteContent = note.note_Content;
                                            }
                                            return <div id={noteId}>
                                                <IonCard id={cardId} className='note-Card' onClick={() => HighlightNoteNonUser(buttonNumber)}>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <IonCardContent className='note-Content-Text'>
                                                            {renderNoteContent(noteContent)}
                                                        </IonCardContent>
                                                    </div>
                                                    <div className="note-Button-Positioning">
                                                        <IonButton className='note-Button-Show' id={showButtonId} shape="round" onClick={() => setShowSSENNonUserNoteButtonClick(buttonNumber)} >View</IonButton>
                                                        <IonCardContent className='note-Content-Time-NonUser'>
                                                            {renderNoteCreation(note)}
                                                        </IonCardContent>
                                                    </div>
                                                </IonCard>
                                            </div>
                                        }
                                        function renderNoteContent(noteContent: any) {
                                            return <div>{noteContent}</div>;
                                        }
                                        function renderNoteCreation(noteContent: any) {
                                            return <div>{noteContent.note_Creation}</div>;
                                        }
                                    })
                                }


                            </IonCard>

                        </IonCard>
                    })
                }
            </div>
        </>
    );

}



export default DisplayNoteComponent
import './mapContentComponentStyle.css';
import 'mapbox-gl/dist/mapbox-gl.css';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import * as turf from '@turf/turf'
import { useRef, useEffect } from 'react';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import _ from 'lodash';
import { IonButton, IonCard, IonIcon, IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { add, closeCircle, create, funnel, refresh, remove, clipboard } from 'ionicons/icons';
import { ACCESS_CREDENTIALS } from '../../pages/Home';
import AddNoteComponent from './AddNoteComponents/AddNoteComponent';
import ToggleComponent from './ToggleComponents/toggleComponent';
import ScenarioMapComponent from './ScenarioMapComponents/ScenarioMapComponent';
import { SidebarState } from '../../enums/enums';

export const BASE_URL = "https://resilienceportal-cimphony.ssen-028.uat.opengrid.com";
export const pathLiveFaults = "https://ssen-powertrack-api.opcld.com/gridiview/reporter/info/livefaults";
export const getNotesPath = "/gridim/rotaload/partners?action=getNotes&districts=";
export const districtsPath = "/gridim/rotaload/partners/overlays?districts=";
export const faultAreasEndpoint = "https://ssen-powertrack-content.opcld.com";

mapboxgl.accessToken = 'pk.eyJ1Ijoib3BlbmdyaWQiLCJhIjoiY2xhczQ0ZnJxMGN5ejQwb3ZnZ3h5cGdweiJ9.6s4liW7r_H4vj6P-bfJ4tw';

type mapContentProps = {
	userDistrictNameArray: any;
	setRefreshButton: any;

	updateNotes: any;
	setUpdateNotes: any;

	lastBlocks: any
	setLastBlocks: any

	setMarkerClickedLocation: any;
	setpropertyNumbers: any;
	setCustomerNumbers: any;
	selectedArea: any;
	latLong: any;
	setMouseAppearance: any;

	setpropertyNumbersScenario: any;
	setCustomerNumbersScenario: any;

	scenarioDay: any;
	scenarioTime: any;
	scenarioStage: any;

	noteLocationSelection: any;


	setEnableButtons: any;

	setOverlayArray: any;


	mapOrSidebarClick: any;
	setmapOrSidebarClick: any;

	setXCoordConfirmed: any;
	setYCoordConfirmed: any;
	setLatLong: any;
	setNoteLocationSelection: any;
	deleteButtonNumber: any;
	lastSelectedArea: any;
	sendScenarioDataFlag: any;
	setSendScenarioDataFlag: any;

	blockColourArray: any;

	markerArray: any;
	setMarkerArray: any;
	markerCancelArray: any;
	setMarkerCancelArray: any;
	resiliencePartner: any;
	noteCancelled: any;
	setNoteCancelled: any
	currentBoundaries: any;
	setCurrentBoundaries: any;
	setOutsideOrInsideBoundry: any;
	setNoteMarkerType: any;
	noteMarkerType: any

	tempMarkerStorage: any;
	setTempMarkerStorage: any;

	lastScenarioTime: any;
	setLastScenarioTime: any;

	boundsZoom: any;
	setBoundsZoom: any;

	setAddNoteAllLocation: any;

	addMarkerToArray: any;
	setAddMarkerToArray: any;

	setBoundariesArray: any;
	boundariesArray: any;
	userDistrictIdArray: any;


	noteToHighlight: any;
	setNoteToHighlight: any;

	setTimeAndLevelButtons: any

	clearScenario: any;
	setClearScenario: any;

	RLBToggle: any;
	setRLBToggle: any;
	previousRLBToggle: any;
	setPreviousRLBToggle: any

	noteToggle: any;
	setNoteToggle: any;
	previousNoteToggle: any;
	setPreviousNoteToggle: any;

	faultToggle: any;
	setFaultToggle: any;
	previousFaultToggle: any
	setPreviousFaultToggle: any

	initialFlag: any;
	setInitialFlag: any;

	faultMarkerArray: any;
	setFaultMarkerArray: any;

	visibleOrHiddenFilter: any;
	setVisibleOrHiddenFilter: any;

	newNotevisibility: any;
	setNewNoteVisibility: any;

	publicUserNotes: any;
	setPublicUserNotes: any;
	partnersUserNotes: any;
	setPartnersUserNotes: any;
	SSENUserNotes: any;
	setSSENUserNotes: any;

	publicNonUserNotes: any;
	setPublicNonUserNotes: any;
	partnersNonUserNotes: any;
	setPartnersNonUserNotes: any;
	SSENNonUserNotes: any;
	setSSENNonUserNotes: any;

	noteMouseActive: any;
	setNoteMouseActive: any;
	highlightedNote: any;
	setHighlightedNote: any;

	updateMapNotes: any;
	setUpdateMapNotes: any;
	setfaultArrayForSave: any;

	readableSelectedArea: any;
	setReadableSelectedArea: any;

	setSidebarExpanded: any;

	setAddNoteButtonFlag: any;
}

const MapContent: React.FC<mapContentProps> = ({ setEnableButtons, setMouseAppearance, setOverlayArray, boundsZoom, setBoundsZoom, noteToHighlight, setNoteToHighlight,
	userDistrictIdArray,
	setAddNoteAllLocation, setBoundariesArray, boundariesArray, tempMarkerStorage, setTempMarkerStorage, setAddMarkerToArray, addMarkerToArray,
	setNoteMarkerType, setOutsideOrInsideBoundry, currentBoundaries, setCurrentBoundaries, setNoteCancelled, noteCancelled, markerCancelArray,
	setMarkerCancelArray, resiliencePartner, markerArray, setMarkerArray, lastSelectedArea, setpropertyNumbers, setCustomerNumbers, selectedArea, noteLocationSelection,
	latLong, setLatLong, setNoteLocationSelection, setXCoordConfirmed, setYCoordConfirmed, setMarkerClickedLocation,
	setpropertyNumbersScenario, setCustomerNumbersScenario, scenarioDay, scenarioTime, scenarioStage, sendScenarioDataFlag, setSendScenarioDataFlag,
	noteMarkerType, mapOrSidebarClick, setmapOrSidebarClick, lastBlocks, setLastBlocks, lastScenarioTime, setLastScenarioTime, blockColourArray, setTimeAndLevelButtons, clearScenario,
	setClearScenario, RLBToggle, setRLBToggle, noteToggle, setNoteToggle, faultToggle, setFaultToggle, previousNoteToggle, setPreviousNoteToggle, previousRLBToggle, setPreviousRLBToggle,
	previousFaultToggle, setPreviousFaultToggle, initialFlag, setInitialFlag, faultMarkerArray, setFaultMarkerArray, visibleOrHiddenFilter, setVisibleOrHiddenFilter,
	setNewNoteVisibility,
	setHighlightedNote, highlightedNote,
	setNoteMouseActive,
	setfaultArrayForSave,
	setUpdateNotes,
	updateNotes,
	setRefreshButton,
	readableSelectedArea,
	setReadableSelectedArea,
	userDistrictNameArray,
	setSidebarExpanded,
	setAddNoteButtonFlag

}) => {
	const mapboxContainer = useRef<any>(undefined);
	const map = useRef<any>(undefined);
	const llb = new mapboxgl.LngLatBounds();

	useEffect(() => {
		setLastBlocks([])
		setRLBToggle("OFF");
		setNoteToggle("OFF");
		setFaultToggle("OFF");

		setVisibleOrHiddenFilter(1);
		map.current = new mapboxgl.Map({
			container: 'mapbox-Container',
			style: 'mapbox://styles/opengrid/cl3n12p66002l15nyllbssdhr',
			center: [-2.6743, 54.8235],
			zoom: 5,
			minZoom: 4,
			transformRequest: (url: String, resourceType: any) => {
				if (resourceType === 'Source' && url.startsWith(BASE_URL)) {
					return {
						url: url,
						headers: { 'Authorization': ACCESS_CREDENTIALS.basicAuthentication }
					}
				}

			}
		});

		map.current.dragRotate.disable();
		map.current.touchZoomRotate.disableRotation();

		const searchBar = new MapboxGeocoder({
			accessToken: mapboxgl.accessToken,
			mapboxgl: mapboxgl,
			marker: false,
			placeholder: 'Search for your location',
			bbox: [-9, 50, 2, 61],
			proximity: {
				longitude: -1.27,
				latitude: 51.75
			},

			reverseGeocode: false  // for 'coordinates -> address' mappings
		});
		map.current.addControl(searchBar);

		var lastSearchTarget: any;

		searchBar.on('result', (e: any) => {
			lastSearchTarget = e.result.center;
			map.current.flyTo({
				zoom: 17,
				duration: 3000,
				center: e.result.center,
			})

		});


		const resizer = new ResizeObserver(_.debounce(() => map.current.resize(), 25));
		resizer.observe(mapboxContainer.current);

		return (() => {
			resizer.disconnect();
		});


	}, []);






	let filter_Button: any;





















	useEffect(() => {

		if (selectedArea !== 0) {
			if (initialFlag === "Set") {
				if (lastSelectedArea === "All") {
					for (let i = 0; i < userDistrictIdArray.length; i++) {
						map.current.removeLayer('Layer-' + i);
						map.current.removeLayer('outline' + i);
						map.current.removeSource(userDistrictIdArray[i] + '-ID');

					}
				} else {
					map.current.removeLayer('Layer-' + lastSelectedArea);
					map.current.removeLayer('outline');
					map.current.removeSource(lastSelectedArea + '-ID');
				}

			}

			if (visibleOrHiddenFilter === 1) {
				filter_Button = document.getElementById('filter-Button')
				filter_Button.style.display = 'flex'
			}




			setSendScenarioDataFlag("Set");
			setInitialFlag("Set");



			map.current.on('render', afterChangeComplete);
			let path2: any;

			path2 = BASE_URL;

			if (selectedArea == 'All') {
				for (let i = 0; i < userDistrictIdArray.length; i++) {

					map.current.addSource(userDistrictIdArray[i] + '-ID', {
						'type': 'geojson',
						'data': BASE_URL + districtsPath + userDistrictIdArray[i]
					});

					let areaPaint: any = {
						'fill-color': 'black',
						'fill-opacity': [
							'interpolate',
							['exponential', 0.5],
							['zoom'],
							7,
							0.25,
							17,
							0.25
						]
					};

					map.current.addLayer(
						{
							'id': 'Layer-' + i,
							'type': 'fill',
							'source': userDistrictIdArray[i] + '-ID',
							'layout': {},
							'paint': areaPaint
						}
					);

					map.current.addLayer({
						'id': 'outline' + i,
						'type': 'line',
						'source': userDistrictIdArray[i] + '-ID',
						'layout': {},
						'paint': {
							'line-color': 'white',
							'line-width': 2
						}
					});


				}
			} else {
				path2 += districtsPath + selectedArea;
				map.current.addSource(selectedArea + '-ID', {
					'type': 'geojson',
					'data': path2
				});

				let areaPaint: any = {
					'fill-color': 'black',
					'fill-opacity': [
						'interpolate',
						['exponential', 0.5],
						['zoom'],
						7,
						0.25,
						17,
						0.25
					]
				};

				map.current.addLayer(
					{
						'id': 'Layer-' + selectedArea,
						'type': 'fill',
						'source': selectedArea + '-ID',
						'layout': {},
						'paint': areaPaint
					}
				);

				map.current.addLayer({
					'id': 'outline',
					'type': 'line',
					'source': selectedArea + '-ID',
					'layout': {},
					'paint': {
						'line-color': 'white',
						'line-width': 2
					}
				});
			}

			let headers = new Headers();
			headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
			let tempBoundariesArray: any;
			tempBoundariesArray = [];
			if (selectedArea !== "All") {
				fetch(path2, { headers: headers })
					.then(function (response) { return response.json(); })
					.then(function (json) {
						const coordinates = json.features[0].geometry.coordinates;

						let featureType = json.features[0].geometry.type.toLowerCase();

						if (featureType == "polygon") {



							var polygon = turf.polygon([coordinates[0]]);

							setCurrentBoundaries(polygon);




						} else if (featureType == "multipolygon") {
							for (let i = 0; i < coordinates.length; i++) {
								for (let j = 0; j < coordinates[i][0].length; j++) {
									llb.extend(coordinates[i][0][j]);
								}
							}
							map.current.fitBounds(llb);

							var multipolygon = turf.multiPolygon([coordinates]);
							setCurrentBoundaries(multipolygon);
						}
						map.current.once('zoomend', () => {

							setBoundsZoom(map.current.getZoom());
						});
					})
			} else {

				const fetchData = async () => {
					for (let i = 0; i < userDistrictIdArray.length; i++) {
						let pathTest: any;
						pathTest = BASE_URL + districtsPath + userDistrictIdArray[i];
						const response = await fetch(pathTest, { headers: headers }) as any;
						const json = await response.json();
						const coordinates = json.features[0].geometry.coordinates;
						let featureType = json.features[0].geometry.type.toLowerCase();

						if (featureType == "polygon") {

							var polygon = turf.polygon([coordinates[0]]);
							tempBoundariesArray.push(polygon);

						} else if (featureType == "multipolygon") {

							for (let i = 0; i < coordinates.length; i++) {
								for (let j = 0; j < coordinates[i][0].length; j++) {
									llb.extend(coordinates[i][0][j]);
								}
							}

							var multipolygon = turf.multiPolygon([coordinates]);
							tempBoundariesArray.push(multipolygon);

						}
						if (i == (userDistrictIdArray.length - 1)) {
							setBoundariesArray(tempBoundariesArray);
						}
					}
					map.current.fitBounds(llb);
					enableAddNoteButton()


				}

				fetchData();



				setAddNoteButtonFlag("Disabled")

				map.current.flyTo({
					center: [-2.6743, 54.8235],
					zoom: 5.2,
					essential: true // this animation is considered essential with respect to prefers-reduced-motion
				});
			}

			let pathNoteTest: any;

			if (selectedArea == "All") {
				let allAddress: any
				allAddress = "";
				for (let i = 0; i < userDistrictIdArray.length; i++) {
					allAddress = allAddress + userDistrictIdArray[i];
					if ((i !== userDistrictIdArray.length - 1)) {
						allAddress = allAddress + ","
					}
				}
				pathNoteTest = BASE_URL + getNotesPath + allAddress;

			} else {
				pathNoteTest = BASE_URL + getNotesPath + selectedArea;
			}
		}
	}, [selectedArea]);












	function afterChangeComplete() {
		if (!map.current.loaded()) { return } // still not loaded; bail out.

		let headers = new Headers();
		headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
		let path2: any;
		path2 = BASE_URL;
		if (selectedArea !== "All") {
			path2 = path2 + districtsPath + selectedArea;
			fetch(path2, { headers: headers })
				.then(function (response) { return response.json(); })
				.then(function (json) {

					let psrCustomers: any;
					let properties: any;


					if (json.features[0].properties.psrCustomers.toString().length < 4) {
						psrCustomers = json.features[0].properties.psrCustomers.toString();
					} else if (json.features[0].properties.psrCustomers.toString().length === 4) {
						const result = json.features[0].properties.psrCustomers.toString().match(/.{1,1}/g) || [];
						psrCustomers = result[0] + ',' + result[1] + result[2] + result[3];
					} else if (json.features[0].properties.psrCustomers.toString().length === 5) {
						const result = json.features[0].properties.psrCustomers.toString().match(/.{1,2}/g) || [];
						psrCustomers = result[0] + ',' + result[1] + result[2];
					} else if (json.features[0].properties.psrCustomers.toString().length === 6) {
						const result = json.features[0].properties.psrCustomers.toString().match(/.{1,3}/g) || [];
						psrCustomers = result[0] + ',' + result[1];
					} else if (json.features[0].properties.psrCustomers.toString().length === 7) {
						const result = json.features[0].properties.psrCustomers.toString().match(/.{1,1}/g) || [];
						psrCustomers = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
					}

					if (json.features[0].properties.properties.toString().length < 4) {
						properties = json.features[0].properties.properties.toString();
					} else if (json.features[0].properties.properties.toString().length === 4) {
						const result = json.features[0].properties.properties.toString().match(/.{1,1}/g) || [];
						properties = result[0] + ',' + result[1] + result[2] + result[3];
					} else if (json.features[0].properties.properties.toString().length === 5) {
						const result = json.features[0].properties.properties.toString().match(/.{1,2}/g) || [];
						properties = result[0] + ',' + result[1] + result[2];
					} else if (json.features[0].properties.properties.toString().length === 6) {
						const result = json.features[0].properties.properties.toString().match(/.{1,3}/g) || [];
						properties = result[0] + ',' + result[1];
					} else if (json.features[0].properties.properties.toString().length === 7) {
						const result = json.features[0].properties.properties.toString().match(/.{1,1}/g) || [];
						properties = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
					}

					setpropertyNumbers(properties);
					setCustomerNumbers(psrCustomers);

				})
		} else {
			let customerNoAll: any;
			customerNoAll = 0;
			let propertyNoAll: any;
			propertyNoAll = 0;
			let fetchesComplete = 0;
			for (let i = 0; i < userDistrictIdArray.length; i++) {
				let pathTest: any;
				pathTest = BASE_URL + districtsPath + userDistrictIdArray[i];
				fetch(pathTest, { headers: headers })
					.then(function (response) { return response.json(); })
					.then(function (json) {
						customerNoAll = customerNoAll + json.features[0].properties.psrCustomers;
						propertyNoAll = propertyNoAll + json.features[0].properties.properties;
						fetchesComplete++;
						if (fetchesComplete == (userDistrictIdArray.length)) {

							let psrCustomers: any;
							let properties: any;


							if (customerNoAll.toString().length < 4) {
								psrCustomers = customerNoAll.toString();
							} else if (customerNoAll.toString().length === 4) {
								const result = customerNoAll.toString().match(/.{1,1}/g) || [];
								psrCustomers = result[0] + ',' + result[1] + result[2] + result[3];
							} else if (customerNoAll.toString().length === 5) {
								const result = customerNoAll.toString().match(/.{1,2}/g) || [];
								psrCustomers = result[0] + ',' + result[1] + result[2];
							} else if (customerNoAll.toString().length === 6) {
								const result = customerNoAll.toString().match(/.{1,3}/g) || [];
								psrCustomers = result[0] + ',' + result[1];
							} else if (customerNoAll.toString().length === 7) {
								const result = customerNoAll.toString().match(/.{1,1}/g) || [];
								psrCustomers = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
							}

							if (propertyNoAll.toString().length < 4) {
								properties = propertyNoAll.toString();
							} else if (propertyNoAll.toString().length === 4) {
								const result = propertyNoAll.toString().match(/.{1,1}/g) || [];
								properties = result[0] + ',' + result[1] + result[2] + result[3];
							} else if (propertyNoAll.toString().length === 5) {
								const result = propertyNoAll.toString().match(/.{1,2}/g) || [];
								properties = result[0] + ',' + result[1] + result[2];
							} else if (propertyNoAll.toString().length === 6) {
								const result = propertyNoAll.toString().match(/.{1,3}/g) || [];
								properties = result[0] + ',' + result[1];
							} else if (propertyNoAll.toString().length === 7) {
								const result = propertyNoAll.toString().match(/.{1,1}/g) || [];
								properties = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
							}

							setpropertyNumbers(properties);
							setCustomerNumbers(psrCustomers);
						}
					})
			}
		}
		map.current.off('render', afterChangeComplete); // remove this handler now that we're done.
	}









	let maptestthing: any;
	maptestthing = [];

	function updateMap(mapArray: any) {
		if (noteToggle == "ON") {
			mapArray.forEach((marker: { remove: () => any; }) => marker.remove())

			let headers = new Headers();
			headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);

			let pathNoteTest: any;

			if (selectedArea == "All") {
				let allAddress: any
				allAddress = "";
				for (let i = 0; i < userDistrictIdArray.length; i++) {
					allAddress = allAddress + userDistrictIdArray[i];
					if ((i !== userDistrictIdArray.length - 1)) {
						allAddress = allAddress + ","
					}
				}
				pathNoteTest = BASE_URL + getNotesPath + allAddress;

			} else {
				pathNoteTest = BASE_URL + getNotesPath + selectedArea;
			}

			fetch(pathNoteTest, { headers: headers })
				.then(function (response) { return response.json(); })
				.then(async function (note) {
					const d = new Date();
					let faultArray: any[] = [];
					let index = note.length + 1;

					const fetchData = async () => {

						const response = await fetch(pathLiveFaults) as any;

						const json = await response.json();
						for (let i = 0; i < json.Faults.length; i++) {
							faultArray.push(json.Faults[i].reference);
						}

						note.forEach((note: any) => { // Goes through each note obtained through the fetch, keeps count of every note with the index.  
							index--;
							let count = 0;
							let note_Expiration = note.Expiration;
							let note_Fault = note.LinkedTo;
							let passTimeCheck: any;

							if (selectedArea === "All") {
								for (let i = 0; i < userDistrictIdArray.length; i++) {
									if (note.district === userDistrictIdArray[i]) {
										count = count + 1;
									}
								}
							} else if (note.district === selectedArea) {
								count = count + 1;
							}

							if (count === 1) {

								if (note.noteExpirationFlag != "Note has expired") {


									if (note_Expiration.length > 3 && note_Expiration != "undefined") {

										let dateTest: any;
										dateTest = new Date();

										passTimeCheck = "Fail";
										if (note_Expiration > dateTest.getTime()) {
											passTimeCheck = "Pass";
										} else if (note.organisation === resiliencePartner) {


											let headersForEdit = new Headers();
											headersForEdit.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
											headersForEdit.append("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
											let pathNoteEditTest: any
											pathNoteEditTest = BASE_URL + "/gridim/rotaload/partners";

											var details = {
												'action': 'updateNote',
												'id': note.id,
												'noteExpirationFlag': 'Note has expired',
											};
											const formBody = Object.entries(details).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&')

											// fetch POST method used to post the now edited data to the backend, if fetch fails an error is thrown, if it doesn't fail notes are set to update and type of note action is set. 

											fetch(pathNoteEditTest, {
												method: 'POST',
												headers: headersForEdit,
												body: formBody
											})
												.then(function (response) {
													if (!response.ok) {
														throw new Error("HTTP status " + response.status);
													}
												}
												)
										}

										let noteFaultPass: any;
										if (passTimeCheck === "Pass") {

											noteFaultPass = "Fail"
											for (let i = 0; i < faultArray.length; i++) {
												if (note_Fault === faultArray[i] || note_Fault === "No_Fault") {
													noteFaultPass = "Pass"
												}
											}

											if (noteFaultPass === "Pass") {

												const el = document.createElement('div');

												if (note.organisation == resiliencePartner) {
													let markerVar: any;


													if (note.Visibility === "Public") {
														el.className = 'markerPublicNote';
													} else if (note.Visibility === "Partners") {
														el.className = 'markerPartnersNote';
													} else if (note.Visibility === "SSEN") {
														el.className = 'markerSSENNote';
													}


													let lngLat = [String(note.longitude), String(note.latitude)];
													markerVar = new mapboxgl.Marker({
														element: el,
														anchor: 'bottom'
													})
														.setLngLat(lngLat)
														.addTo(map.current);
													maptestthing.push(markerVar)
													markerVar.getElement().addEventListener('click', () => {
														setMarkerClickedLocation(markerVar.getLngLat());
														setNoteMarkerType("User")
														setNoteToHighlight(markerVar.getLngLat());
														setHighlightedNote(note.Visibility);
														setmapOrSidebarClick("Map");
													});
												} else {
													let markerVar: any;


													if (note.Visibility === "Public") {
														el.className = 'markerPublicNoteNonUser';
													} else if (note.Visibility === "Partners") {
														el.className = 'markerPartnersNoteNonUser';
													} else if (note.Visibility === "SSEN") {
														el.className = 'markerSSENNoteNonUser';
													}


													let lngLat = [String(note.longitude), String(note.latitude)];
													markerVar = new mapboxgl.Marker({
														element: el,
														anchor: 'bottom'
													})
														.setLngLat(lngLat)
														.addTo(map.current);
													maptestthing.push(markerVar)
													markerVar.getElement().addEventListener('click', () => {
														setMarkerClickedLocation(markerVar.getLngLat());
														setNoteMarkerType("Non-User")
														setHighlightedNote(note.Visibility);
														setNoteToHighlight(markerVar.getLngLat());
														setmapOrSidebarClick("Map");
													});
												}
												setMarkerArray(maptestthing);

											}
										}
									}
								}
							}

						}
						)

					};
					await fetchData();



					maptestthing = [];

					setTimeout(EnableRefreshButton, 2000);

				});
		}
	}



	function EnableRefreshButton() {
		setRefreshButton("Enabled")
	}


	function enableAddNoteButton() {
		setAddNoteButtonFlag("Enabled")
	}




	useEffect(() => {
		if (updateNotes === "Update") {
			updateMap(markerArray)
		}
	}, [updateNotes])














	useEffect(() => {
		if (noteToHighlight != "0") {
			let testValue: any;
			let testValue2: any;
			if (mapOrSidebarClick === "Sidebar") {
				let arrayOfNote = noteToHighlight.split(",");

				testValue = Number(arrayOfNote[1]);
				testValue = testValue.toFixed(3);


				testValue2 = Number(arrayOfNote[0]);
				testValue2 = testValue2.toFixed(3);

			} else if (mapOrSidebarClick === "Map") {
				let arrayOfNote = noteToHighlight;

				testValue = arrayOfNote.lat;
				testValue = testValue.toFixed(3);


				testValue2 = arrayOfNote.lng;
				testValue2 = testValue2.toFixed(3);

				if (window.innerWidth == 640 || window.innerWidth < 640) {
					setSidebarExpanded(SidebarState.EXPANDED);
				}
			}



			for (let i = 0; i < markerArray.length; i++) {
				if ((markerArray[i]._lngLat.lng.toFixed(3).toString() === testValue2) && (markerArray[i]._lngLat.lat.toFixed(3).toString() === testValue)) {
					if (noteMarkerType === "User") {
						if (highlightedNote === "Public") {
							markerArray[i]._element.className = "markerPublicNoteSelected mapboxgl-marker mapboxgl-marker-anchor-bottom"
						} else if (highlightedNote === "Partners") {
							markerArray[i]._element.className = "markerPartnersNoteSelected mapboxgl-marker mapboxgl-marker-anchor-bottom"
						} else if (highlightedNote === "SSEN") {
							markerArray[i]._element.className = "markerSSENNoteSelected mapboxgl-marker mapboxgl-marker-anchor-bottom"
						}

						setNoteMarkerType(0);
					} else if (noteMarkerType === "Non-User") {
						if (highlightedNote === "Public") {
							markerArray[i]._element.className = "markerPublicNoteNonUserSelected mapboxgl-marker mapboxgl-marker-anchor-bottom"
						} else if (highlightedNote === "Partners") {
							markerArray[i]._element.className = "markerPartnersNoteNonUserSelected mapboxgl-marker mapboxgl-marker-anchor-bottom"
						} else if (highlightedNote === "SSEN") {
							markerArray[i]._element.className = "markerSSENNoteNonUserSelected mapboxgl-marker mapboxgl-marker-anchor-bottom"
						}
						setNoteMarkerType(0);
					}
					if (map.current.getZoom() < boundsZoom) {
						map.current.flyTo({
							center: [markerArray[i]._lngLat.lng, markerArray[i]._lngLat.lat],
							duration: 1200,
							essential: true,
							zoom: boundsZoom
						});
					} else {
						map.current.flyTo({
							center: [markerArray[i]._lngLat.lng, markerArray[i]._lngLat.lat],
							duration: 1200,
							essential: true,
						})
					}
				} else {



					if (markerArray[i]._element.className === "markerPublicNoteSelected mapboxgl-marker mapboxgl-marker-anchor-bottom" || markerArray[i]._element.className === "markerPartnersNoteSelected mapboxgl-marker mapboxgl-marker-anchor-bottom" || markerArray[i]._element.className === "markerSSENNoteSelected mapboxgl-marker mapboxgl-marker-anchor-bottom") {
						markerArray[i]._element.className = "markerPublicNote mapboxgl-marker mapboxgl-marker-anchor-bottom";
					} else if (markerArray[i]._element.className === "markerPublicNote mapboxgl-marker mapboxgl-marker-anchor-bottom" || markerArray[i]._element.className === "markerPartnersNote mapboxgl-marker mapboxgl-marker-anchor-bottom" || markerArray[i]._element.className === "markerSSENNote mapboxgl-marker mapboxgl-marker-anchor-bottom") {
						markerArray[i]._element.className = "markerPublicNote mapboxgl-marker mapboxgl-marker-anchor-bottom";
					} else {
						markerArray[i]._element.className = "markerPublicNoteNonUser mapboxgl-marker mapboxgl-marker-anchor-bottom";
					}

				}
			}
		}
	}, [noteToHighlight])


	return (
		<>
			<AddNoteComponent
				MapThing={map.current}
				noteLocationSelection={noteLocationSelection}
				selectedArea={selectedArea}
				currentBoundaries={currentBoundaries}
				setMarkerClickedLocation={setMarkerClickedLocation}
				setNoteMarkerType={setNoteMarkerType}
				setNoteToHighlight={setNoteToHighlight}
				setmapOrSidebarClick={setmapOrSidebarClick}
				boundsZoom={boundsZoom}
				setLatLong={setLatLong}

				setMarkerCancelArray={setMarkerCancelArray}
				setMouseAppearance={setMouseAppearance}
				setTempMarkerStorage={setTempMarkerStorage}
				setAddNoteAllLocation={setAddNoteAllLocation}
				setNoteMouseActive={setNoteMouseActive}
				setUpdateNotes={setUpdateNotes}
				setfaultArrayForSave={setfaultArrayForSave}
				setOutsideOrInsideBoundry={setOutsideOrInsideBoundry}
				setNoteLocationSelection={setNoteLocationSelection}

				userDistrictIdArray={userDistrictIdArray}
				userDistrictNameArray={userDistrictNameArray}
				boundariesArray={boundariesArray}

				addMarkerToArray={addMarkerToArray}
				markerArray={markerArray}
				tempMarkerStorage={tempMarkerStorage}

				setMarkerArray={setMarkerArray}
				setAddMarkerToArray={setAddMarkerToArray}

				markerCancelArray={markerCancelArray}
				setEnableButtons={setEnableButtons}

				setXCoordConfirmed={setXCoordConfirmed}
				setYCoordConfirmed={setYCoordConfirmed}
				setNewNoteVisibility={setNewNoteVisibility}

				latLong={latLong}

				noteCancelled={noteCancelled}
				setNoteCancelled={setNoteCancelled}

				readableSelectedArea={readableSelectedArea}
				setReadableSelectedArea={setReadableSelectedArea}

			/>

			<ToggleComponent
				MapThing={map.current}
				selectedArea={selectedArea}
				setUpdateNotes={setUpdateNotes}
				userDistrictIdArray={userDistrictIdArray}
				markerArray={markerArray}

				noteToggle={noteToggle}

				setPreviousNoteToggle={setPreviousNoteToggle}

				previousNoteToggle={previousNoteToggle}

				faultToggle={faultToggle}

				setPreviousFaultToggle={setPreviousFaultToggle}

				setFaultMarkerArray={setFaultMarkerArray}

				previousFaultToggle={previousFaultToggle}

				faultMarkerArray={faultMarkerArray}

				RLBToggle={RLBToggle}

				setPreviousRLBToggle={setPreviousRLBToggle}

				setOverlayArray={setOverlayArray}

				setCurrentBoundaries={setCurrentBoundaries}

				setBoundariesArray={setBoundariesArray}

				previousRLBToggle={previousRLBToggle}

				setpropertyNumbers={setpropertyNumbers}

				setCustomerNumbers={setCustomerNumbers}

				setVisibleOrHiddenFilter={setVisibleOrHiddenFilter}

				setRLBToggle={setRLBToggle}
				setNoteToggle={setNoteToggle}
				setFaultToggle={setFaultToggle}
			/>

			<ScenarioMapComponent
				MapThing={map.current}
				selectedArea={selectedArea}

				userDistrictIdArray={userDistrictIdArray}

				setOverlayArray={setOverlayArray}

				setCurrentBoundaries={setCurrentBoundaries}

				scenarioStage={scenarioStage}
				scenarioDay={scenarioDay}
				scenarioTime={scenarioTime}
				sendScenarioDataFlag={sendScenarioDataFlag}
				setLastScenarioTime={setLastScenarioTime}
				setTimeAndLevelButtons={setTimeAndLevelButtons}

				setCustomerNumbersScenario={setCustomerNumbersScenario}
				setpropertyNumbersScenario={setpropertyNumbersScenario}
				setLastBlocks={setLastBlocks}
				lastBlocks={lastBlocks}
				lastScenarioTime={lastScenarioTime}
				blockColourArray={blockColourArray}

				setSendScenarioDataFlag={setSendScenarioDataFlag}

				setpropertyNumbers={setpropertyNumbers}
				setCustomerNumbers={setCustomerNumbers}
				clearScenario={clearScenario}
				setClearScenario={setClearScenario}
			/>


			<div id='mapbox-Container' ref={mapboxContainer}>
				<div id='key-Container' className='key-Container' style={{ display: 'none' }}>
					<div id="key" className="key">
						<div id='key-Letter-And-Block' className='key-Letter-And-Block' style={{ width: '60px', right: '6px', position: 'relative', display: 'flex', justifyContent: 'center' }}>
							<div id="key-Letter" className="key-Letter">KEY</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(0,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">A</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(12,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">B</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(23,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">C</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(35,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">D</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(47,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">E</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(58,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">F</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(70,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">G</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(81,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">H</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(93,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">J</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(105,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">K</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(116,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">L</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(128,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">M</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(140,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">N</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(151,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">O</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(163,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">P</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(175,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">Q</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(186,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">R</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(198,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">S</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(209,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">T</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(221,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">U</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(233,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">V</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-Z'>
							<div style={{ backgroundColor: "hsl(244,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">Z</div>
						</div>
					</div>
				</div>
			</div>
			<div id='map-Buttons'>
				<div className='map-Button-Container'>
					<IonButton id='zoom-in' shape='round' onClick={() => map.current.zoomIn()}><IonIcon slot='icon-only' icon={add} /></IonButton>
					<label className='map-Button-Label'>Zoom In</label>
				</div>
				<div className='map-Button-Container'>
					<IonButton id='zoom-out' shape='round' onClick={() => map.current.zoomOut()}><IonIcon slot='icon-only' icon={remove} /></IonButton>
					<label className='map-Button-Label'>Zoom Out</label>
				</div>
				<div className='map-Button-Container'>
					<IonButton id='reset-to-uk-bounds' shape='round' onClick={() => map.current.fitBounds([[-10.5, 49.5], [2.5, 60.5]])}><IonIcon slot='icon-only' icon={refresh} /></IonButton>
					<label className='map-Button-Label'>Reset Map</label>
				</div>

			</div>
		</>
	);
}

export default MapContent;
import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCheckbox, IonDatetime, IonDatetimeButton, IonIcon, IonLabel, IonModal, IonSelect, IonSelectOption, IonTextarea } from "@ionic/react";
import { useEffect } from "react";
import { BASE_URL, pathLiveFaults, getNotesPath } from '../../mapContentComponent/mapContentComponent';
import { ACCESS_CREDENTIALS } from "../../../pages/Home";
import './NoteComponentStyle.css';
import { chevronDown, chevronDownCircle, chevronForwardCircle, chevronUp, closeCircleOutline, create, personCircleOutline, timeOutline } from "ionicons/icons";
import { SidebarState, modalState, mouseState } from "../../../enums/enums";
import SaveNoteComponent from "./SaveNoteComponents/SaveNoteComponent";
import EditNoteComponent from "./EditNoteComponents/EditNoteComponent";
import DeleteNoteComponent from "./DeleteNoteComponents/DeleteNoteComponent";
import ShowNoteComponent from "./ShowNoteComponents/ShowNoteComponent";
import DisplayNoteComponent from "./DisplayNoteComponents/DisplayNoteComponent";


type noteProps = {



    setRefreshButton: any;

    noteToggle: any;
    setOrganisationArray: any;
    setUpdateNotes: any;
    setTypeOfNoteAction: any;


    noteMouseActive: any;
    setMouseAppearance: any;
    setNoteMouseActive: any;


    notesArray: any;
    setNotesArray: any;


    organisationArray: any;
    publicUserNotes: any;
    setPublicUserNotes: any;
    partnersUserNotes: any;
    setPartnersUserNotes: any;
    SSENUserNotes: any;
    setSSENUserNotes: any;
    setModalVisibility: any;
    setNoteCancelled: any;

    setNoteLocationSelection: any;
    setOutsideOrInsideBoundry: any;
    outsideOrInsideBoundry: any;
    XCoordConfirmed: any;
    YCoordConfirmed: any;
    setLinkedToFault: any;
    linkedToFault: any;
    permNote: any;
    setPermNote: any;
    faultReferenceToBeSaved: any;
    setFaultReferenceToBeSaved: any;
    noteExpiryDate: any;
    setNoteExpiryDate: any;
    newNoteVisibility: any;

    selectedArea: any;
    lastSelectedArea: any;
    addNoteAllLocation: any;
    noteSaveCount: any;
    setNoteSaveCount: any;
    setSelectedArea: any;
    setAddMarkerToArray: any;
    setEditButtonNumber: any;

    setDeleteDateReference: any;
    deleteDateReference: any;
    setDeleteNoteReference: any;
    deleteDistrictReference: any;
    setDeleteDistrictReference: any;

    setDeleteLocationReferenceX: any;
    deleteLocationReferenceX: any;
    setDeleteLocationReferenceY: any;
    deleteLocationReferenceY: any;
    deleteIDReference: any;
    setDeleteIDReference: any;
    setDeleteButtonNumber: any;
    setDeleteMarker: any;
    deleteButtonNumber: any;
    publicNonUserNotes: any;
    setPublicNonUserNotes: any;
    partnersNonUserNotes: any;
    setPartnersNonUserNotes: any;
    SSENNonUserNotes: any;
    setSSENNonUserNotes: any;
    setNoteOrg: any;
    noteOrg: any;
    modalVisibility: any;
    mouseAppearance: any;
    setNoteToHighlight: any;
    setNoteMarkerType: any;
    setmapOrSidebarClick: any;
    markerClickedLocation: any;
    noteMarkerType: any;
    setMarkerClickedLocation: any;
    highlightLastNote: any;
    setHighlightLastNote: any;
    userDistrictIdArray: any;
    userDistrictNameArray: any;
    resiliencePartner: any;

    updateNotes: any;
    typeOfNoteAction: any;
    faultArrayForSave: any;


    setSidebarExpanded: any;
    sidebarExpanded: any;

    setEnableButtons: any;
    enableButtonsVal: any;

    refreshButton: any;

    saveNoteButtonClick: any;
    setSaveNoteButtonClick: any;


    editPublicNoteButtonClick: any;




    setEditPublicNoteButtonClick: any;

    editPartnerNoteButtonClick: any;
    setEditPartnerNoteButtonClick: any;

    editSSENNoteButtonClick: any;
    setEditSSENNoteButtonClick: any;


    deletePublicNoteButtonClick: any;
    setDeletePublicNoteButtonClick: any;

    deletePartnerNoteButtonClick: any;
    setDeletePartnerNoteButtonClick: any;

    deleteSSENNoteButtonClick: any;
    setDeleteSSENNoteButtonClick: any;

    showPublicNoteButtonClick: any;
    setShowPublicNoteButtonClick: any;

    showPartnerNoteButtonClick: any;
    setShowPartnerNoteButtonClick: any;

    showSSENNoteButtonClick: any;
    setShowSSENNoteButtonClick: any;

    showPublicNonUserNoteButtonClick: any;
    setShowPublicNonUserNoteButtonClick: any;

    showPartnerNonUserNoteButtonClick: any;
    setShowPartnerNonUserNoteButtonClick: any;

    showSSENNonUserNoteButtonClick: any;
    setShowSSENNonUserNoteButtonClick: any;
    organisationPublicArrayNo: any;
    setOrganisationPublicArrayNo: any;

    organisationPartnerArrayNo: any;
    setOrganisationPartnerArrayNo: any;

    organisationSSENArrayNo: any;
    setOrganisationSSENArrayNo: any;

    readableSelectedArea: any;
    setReadableSelectedArea: any;

    extendedNotesArray: any;
    setExtendedNotesArray: any;

    setAddNoteButtonFlag: any;
    addNoteButtonFlag: any;


    publicExpiredUserNotes: any;
    setPublicExpiredUserNotes: any;
    partnersExpiredUserNotes: any;
    setPartnersExpiredUserNotes: any;
    SSENExpiredUserNotes: any;
    setSSENExpiredUserNotes: any;


    publicExpiredNonUserNotes: any;
    setPublicExpiredNonUserNotes: any;
    partnersExpiredNonUserNotes: any;
    setPartnersExpiredNonUserNotes: any;
    SSENExpiredNonUserNotes: any;
    setSSENExpiredNonUserNotes: any;

}


const NoteComponent: React.FC<noteProps> = ({
    noteToggle, setOrganisationArray, setUpdateNotes, setTypeOfNoteAction, notesArray, setNotesArray, organisationArray, publicUserNotes, setPublicUserNotes, partnersUserNotes, setPartnersUserNotes, SSENUserNotes, setSSENUserNotes, noteMouseActive, setMouseAppearance, setNoteMouseActive,
    setModalVisibility, setNoteCancelled, setNoteLocationSelection, setOutsideOrInsideBoundry, outsideOrInsideBoundry, XCoordConfirmed, YCoordConfirmed, setLinkedToFault, linkedToFault, permNote, setPermNote, faultReferenceToBeSaved, setFaultReferenceToBeSaved, noteExpiryDate, setNoteExpiryDate, newNoteVisibility, selectedArea,
    lastSelectedArea, addNoteAllLocation, noteSaveCount, setNoteSaveCount, setSelectedArea, setAddMarkerToArray, setEditButtonNumber, setDeleteDateReference, setDeleteNoteReference, deleteDistrictReference, setDeleteDistrictReference, setDeleteLocationReferenceX, deleteLocationReferenceX, setDeleteLocationReferenceY, deleteLocationReferenceY,
    deleteIDReference, setDeleteIDReference, setDeleteButtonNumber, setDeleteMarker, deleteButtonNumber, publicNonUserNotes, setPublicNonUserNotes, partnersNonUserNotes, setPartnersNonUserNotes, SSENNonUserNotes, setSSENNonUserNotes, setNoteOrg, noteOrg, modalVisibility, mouseAppearance, setNoteToHighlight, setNoteMarkerType, setmapOrSidebarClick, markerClickedLocation,
    noteMarkerType, setMarkerClickedLocation, highlightLastNote, setHighlightLastNote, userDistrictIdArray, resiliencePartner, updateNotes, typeOfNoteAction, deleteDateReference, faultArrayForSave, setSidebarExpanded, sidebarExpanded, setEnableButtons, enableButtonsVal, setRefreshButton, refreshButton, saveNoteButtonClick, setSaveNoteButtonClick, editPublicNoteButtonClick,
    setEditPublicNoteButtonClick, editPartnerNoteButtonClick, setEditPartnerNoteButtonClick, editSSENNoteButtonClick, setEditSSENNoteButtonClick,
    deletePublicNoteButtonClick,
    setDeletePublicNoteButtonClick,

    deletePartnerNoteButtonClick,
    setDeletePartnerNoteButtonClick,

    deleteSSENNoteButtonClick,
    setDeleteSSENNoteButtonClick,
    showPublicNoteButtonClick,
    setShowPublicNoteButtonClick,

    showPartnerNoteButtonClick,
    setShowPartnerNoteButtonClick,

    showSSENNoteButtonClick,
    setShowSSENNoteButtonClick,

    showPublicNonUserNoteButtonClick,
    setShowPublicNonUserNoteButtonClick,

    showPartnerNonUserNoteButtonClick,
    setShowPartnerNonUserNoteButtonClick,

    showSSENNonUserNoteButtonClick,
    setShowSSENNonUserNoteButtonClick,
    organisationPublicArrayNo,
    setOrganisationPublicArrayNo,

    organisationPartnerArrayNo,
    setOrganisationPartnerArrayNo,

    organisationSSENArrayNo,
    setOrganisationSSENArrayNo,

    readableSelectedArea,
    setReadableSelectedArea,
    userDistrictNameArray,


    extendedNotesArray,
    setExtendedNotesArray,
    setAddNoteButtonFlag,
    addNoteButtonFlag,

    publicExpiredUserNotes,
    setPublicExpiredUserNotes,
    partnersExpiredUserNotes,
    setPartnersExpiredUserNotes,
    SSENExpiredUserNotes,
    setSSENExpiredUserNotes,


    publicExpiredNonUserNotes,
    setPublicExpiredNonUserNotes,
    partnersExpiredNonUserNotes,
    setPartnersExpiredNonUserNotes,
    SSENExpiredNonUserNotes,
    setSSENExpiredNonUserNotes



}) => {

    let scenario_Planner_Style_2: any;
    let Note_Section: any;
    let dottedLine2: any;
    let PR_Note_Non_User_Extend: any;

    let public_Note_Non_User: any;
    let partner_Note_Non_User: any;
    let SSEN_Note_Non_User: any;
    let public_Note_Chevron_Non_User: any;
    let partner_Note_Chevron_Non_User: any;
    let SSEN_Note_Chevron_Non_User: any;

    let chevronDirectionNonUser: any;
    let PR_Note_User_Extend: any;
    let public_Note_User_Extend: any;
    let partner_Note_User_Extend: any;
    let SSEN_Note_User_Extend: any;
    let chevronDirectionUser: any;
    let public_Note_Chevron: any;
    let partner_Note_Chevron: any;
    let SSEN_Note_Chevron: any;


    let Card_Design: any;



    let buttonShow: any;
    let buttonEdit: any;
    let buttonDelete: any;

    let segmentActivation: any;
    let segmentActivation2: any;
    let segmentActivation3: any;
    let ionSelectActive: any;


    let noteExtendActivate: any;

    let refresh_Note_Button: any;

    let add_Note_Button: any;




    useEffect(() => {
        if (refreshButton === "Disabled") { // Use effect for note toggle coming on and affecting the sidebar by adding the notes
            refresh_Note_Button = document.getElementById('refresh-Note-Button');
            refresh_Note_Button.disabled = 'true';
        } else if (refreshButton === "Enabled") {
            refresh_Note_Button = document.getElementById('refresh-Note-Button');
            refresh_Note_Button.disabled = 'false';
        }
    }, [refreshButton])


    useEffect(() => {
        if (addNoteButtonFlag === "Disabled") { // Use effect for note toggle coming on and affecting the sidebar by adding the notes
            add_Note_Button = document.getElementById('add-Note-Button');
            add_Note_Button.disabled = 'true';
        } else if (addNoteButtonFlag === "Enabled") {
            add_Note_Button = document.getElementById('add-Note-Button');
            add_Note_Button.disabled = 'false';
        }
    }, [addNoteButtonFlag])





    useEffect(() => {
        if (noteToggle === "ON") { // Use effect for note toggle coming on and affecting the sidebar by adding the notes

            scenario_Planner_Style_2 = document.getElementById('scenario-Planner-Style-2');
            scenario_Planner_Style_2.style.display = 'block';
            Note_Section = document.getElementById('note-Container');
            Note_Section.style.display = 'flex';
            dottedLine2 = document.getElementById('horizontal-dotted-line-2');
            dottedLine2.style.display = "flex";

            // If user toggles note mode then all of the necessary buttons, cards and, labels appear. 

            let fakeArrayTest: any;
            fakeArrayTest = [];
            setOrganisationArray(fakeArrayTest);
            // Sets all arrays to an empty array so they can be refilled easier. 

            setUpdateNotes("Update");
            setTypeOfNoteAction("Show Notes");
            // Updates note to show RLD notes. 
        } if (noteToggle === "OFF") {
            scenario_Planner_Style_2 = document.getElementById('scenario-Planner-Style-2');
            scenario_Planner_Style_2.style.display = 'none';
            Note_Section = document.getElementById('note-Container');
            Note_Section.style.display = 'none';
            dottedLine2 = document.getElementById('horizontal-dotted-line-2');
            dottedLine2.style.display = "none";
        }
    }, [noteToggle])












    useEffect(() => {
        if (enableButtonsVal != 0) {
            enableButtons();
            setEnableButtons(0);
        }
    }, [enableButtonsVal]
    );

    function enableButtons() { // Enables all of the sidebar buttons for use
        publicUserNotes.map((function (publicUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + publicUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + publicUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + publicUserNotes.index);
            buttonShow.disabled = false;
            buttonEdit.disabled = false;
            buttonDelete.disabled = false;
        }))

        partnersUserNotes.map((function (partnersUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + partnersUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + partnersUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + partnersUserNotes.index);
            buttonShow.disabled = false;
            buttonEdit.disabled = false;
            buttonDelete.disabled = false;
        }))

        SSENUserNotes.map((function (SSENUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + SSENUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + SSENUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + SSENUserNotes.index);
            buttonShow.disabled = false;
            buttonEdit.disabled = false;
            buttonDelete.disabled = false;
        }))



        publicNonUserNotes.map((function (publicNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + publicNonUserNotes.index);
            buttonShow.disabled = false;
        }))


        partnersNonUserNotes.map((function (partnersNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + partnersNonUserNotes.index);
            buttonShow.disabled = false;
        }))


        SSENNonUserNotes.map((function (SSENNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + SSENNonUserNotes.index);
            buttonShow.disabled = false;
        }))


        segmentActivation = document.getElementById('segment-Id-1');
        segmentActivation.disabled = false;
        segmentActivation2 = document.getElementById('segment-Id-2');
        segmentActivation2.disabled = false;
        segmentActivation3 = document.getElementById('segment-Id-3');
        segmentActivation3.disabled = false;

        ionSelectActive = document.getElementById('ion-select-ID');
        ionSelectActive.disabled = false;

        noteExtendActivate = document.getElementById('chevronDownCircle');
        noteExtendActivate.style.pointerEvents = 'all';

        organisationArray.map(function (organisation: any, index: number) {
            index++;
            noteExtendActivate = document.getElementById("chevronDownCircle" + index);
            noteExtendActivate.style.pointerEvents = 'all';
        })
    }

















    useEffect(() => {
        if (markerClickedLocation != 0) { //useEffect that is called when markerClickedLocation and noteMarkerType changes, changes when user clicks on note marker on map.
            if (mouseAppearance === 0) {

            } else {
                if (noteMarkerType == "User") { //Checks if note is from user org or not to know which array to check.
                    setUpdateNotes("Update");

                    publicUserNotes.map((function (note: any) { //Check user org array.

                        let arrayOfNote = note.note_Location.split(",");

                        let testValue: any;
                        testValue = Number(arrayOfNote[1]);
                        testValue = testValue.toFixed(3);

                        let testValue2: any;
                        testValue2 = Number(arrayOfNote[0]);
                        testValue2 = testValue2.toFixed(3);
                        // Values used to determine what note needs to be highlighted/fly to. 

                        if (testValue2 == markerClickedLocation.lng.toFixed(3) && testValue == markerClickedLocation.lat.toFixed(3)) { // Checks note coordinates saved against note coordinates clicked, if they match highlights note and scrolls to it.
                            PR_Note_User_Extend = document.getElementById('note-Test-Perm-Fix');
                            PR_Note_User_Extend.style.height = 'auto';

                            chevronDirectionUser = document.getElementById('chevronDownCircle');
                            chevronDirectionUser.style.transform = 'none';

                            public_Note_User_Extend = document.getElementById('note-Public');
                            public_Note_User_Extend.style.height = 'auto';

                            public_Note_Chevron = document.getElementById('chevronDownCircle-Public');
                            public_Note_Chevron.style.transform = 'none';


                            Card_Design = document.getElementById('Card-' + note.index);
                            Card_Design.style.border = "solid #FFFFFF 2.5px";
                            Card_Design.style.background = "#64A1BB";
                            Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
                        } else {
                            Card_Design = document.getElementById('Card-' + note.index);
                            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                            Card_Design.style.background = "rgb(59, 107, 139)";
                        }
                    }))

                    partnersUserNotes.map((function (note: any) { //Check user org array.

                        let arrayOfNote = note.note_Location.split(",");

                        let testValue: any;
                        testValue = Number(arrayOfNote[1]);
                        testValue = testValue.toFixed(3);

                        let testValue2: any;
                        testValue2 = Number(arrayOfNote[0]);
                        testValue2 = testValue2.toFixed(3);
                        // Values used to determine what note needs to be highlighted/fly to. 

                        if (testValue2 == markerClickedLocation.lng.toFixed(3) && testValue == markerClickedLocation.lat.toFixed(3)) { // Checks note coordinates saved against note coordinates clicked, if they match highlights note and scrolls to it.

                            PR_Note_User_Extend = document.getElementById('note-Test-Perm-Fix');
                            chevronDirectionUser = document.getElementById('chevronDownCircle');

                            PR_Note_User_Extend.style.height = 'auto';
                            chevronDirectionUser.style.transform = 'rotate(180deg)';

                            partner_Note_User_Extend = document.getElementById('note-Partners');
                            partner_Note_User_Extend.style.height = 'auto';

                            partner_Note_Chevron = document.getElementById('chevronDownCircle-Partners');
                            partner_Note_Chevron.style.transform = 'rotate(180deg)';

                            Card_Design = document.getElementById('Card-' + note.index);
                            Card_Design.style.border = "solid #FFFFFF 2.5px";
                            Card_Design.style.background = "#64A1BB";
                            Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
                        } else {
                            Card_Design = document.getElementById('Card-' + note.index);
                            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                            Card_Design.style.background = "rgb(59, 107, 139)";
                        }
                    }))

                    SSENUserNotes.map((function (note: any) { //Check user org array.

                        let arrayOfNote = note.note_Location.split(",");

                        let testValue: any;
                        testValue = Number(arrayOfNote[1]);
                        testValue = testValue.toFixed(3);

                        let testValue2: any;
                        testValue2 = Number(arrayOfNote[0]);
                        testValue2 = testValue2.toFixed(3);
                        // Values used to determine what note needs to be highlighted/fly to. 

                        if (testValue2 == markerClickedLocation.lng.toFixed(3) && testValue == markerClickedLocation.lat.toFixed(3)) { // Checks note coordinates saved against note coordinates clicked, if they match highlights note and scrolls to it.

                            PR_Note_User_Extend = document.getElementById('note-Test-Perm-Fix');
                            PR_Note_User_Extend.style.height = 'auto';

                            chevronDirectionUser = document.getElementById('chevronDownCircle');
                            chevronDirectionUser.style.transform = 'rotate(180deg)';

                            SSEN_Note_User_Extend = document.getElementById('note-SSEN');
                            SSEN_Note_User_Extend.style.height = 'auto';

                            SSEN_Note_Chevron = document.getElementById('chevronDownCircle-SSEN');
                            SSEN_Note_Chevron.style.transform = 'rotate(180deg)';

                            Card_Design = document.getElementById('Card-' + note.index);
                            Card_Design.style.border = "solid #FFFFFF 2.5px";
                            Card_Design.style.background = "#64A1BB";
                            Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
                        } else {
                            Card_Design = document.getElementById('Card-' + note.index);
                            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                            Card_Design.style.background = "rgb(59, 107, 139)";
                        }
                    }))


                    publicNonUserNotes.map(function (note: any) {
                        Card_Design = document.getElementById('Card-NonUser-' + note.index);
                        Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                        Card_Design.style.background = "rgb(59, 107, 139)";
                    })

                    partnersNonUserNotes.map(function (note: any) {
                        Card_Design = document.getElementById('Card-NonUser-' + note.index);
                        Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                        Card_Design.style.background = "rgb(59, 107, 139)";
                    })

                    SSENNonUserNotes.map(function (note: any) {
                        Card_Design = document.getElementById('Card-NonUser-' + note.index);
                        Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                        Card_Design.style.background = "rgb(59, 107, 139)";
                    })

                } else if (noteMarkerType == "Non-User") { //Checks if note is from non-user org or not to know which array to check.

                    publicNonUserNotes.map((function (note: any) { //Check non-user org array.
                        let arrayOfNote = note.note_Location.split(",");
                        let testValue: any;
                        testValue = Number(arrayOfNote[1]);
                        testValue = testValue.toFixed(3);

                        let testValue2: any;
                        testValue2 = Number(arrayOfNote[0]);
                        testValue2 = testValue2.toFixed(3);
                        // Values used to determine what note needs to be highlighted/fly to. 

                        if (testValue2 == markerClickedLocation.lng.toFixed(3) && testValue == markerClickedLocation.lat.toFixed(3)) {  // Checks note coordinates saved against note coordinates clicked, if they match highlights note and scrolls to it.

                            organisationArray.map((function (organisation: any, index: number) {
                                index++
                                if (note.note_Organisation === organisation) {

                                    PR_Note_Non_User_Extend = document.getElementById('note-Non-User-' + index);
                                    PR_Note_Non_User_Extend.style.height = 'auto';

                                    chevronDirectionNonUser = document.getElementById('chevronDownCircle' + index);
                                    chevronDirectionNonUser.style.transform = 'rotate(180deg)';

                                    public_Note_Non_User = document.getElementById("note-Non-User-Public-" + index);
                                    public_Note_Non_User.style.height = 'auto';

                                    public_Note_Chevron_Non_User = document.getElementById("chevronDownCircle-Public-" + index);
                                    public_Note_Chevron_Non_User.style.transform = 'rotate(180deg)';
                                }
                            }))
                            Card_Design = document.getElementById('Card-NonUser-' + note.index);
                            Card_Design.style.border = "solid #FFFFFF 2.5px";
                            Card_Design.style.background = "#64A1BB";
                            Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
                        } else {
                            Card_Design = document.getElementById('Card-NonUser-' + note.index);
                            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                            Card_Design.style.background = "rgb(59, 107, 139)";
                        }
                    }))

                    partnersNonUserNotes.map((function (note: any) { //Check non-user org array.
                        let arrayOfNote = note.note_Location.split(",");
                        let testValue: any;
                        testValue = Number(arrayOfNote[1]);
                        testValue = testValue.toFixed(3);

                        let testValue2: any;
                        testValue2 = Number(arrayOfNote[0]);
                        testValue2 = testValue2.toFixed(3);
                        // Values used to determine what note needs to be highlighted/fly to. 

                        if (testValue2 == markerClickedLocation.lng.toFixed(3) && testValue == markerClickedLocation.lat.toFixed(3)) {  // Checks note coordinates saved against note coordinates clicked, if they match highlights note and scrolls to it.

                            organisationArray.map((function (organisation: any, index: number) {
                                index++
                                if (note.note_Organisation === organisation) {

                                    PR_Note_Non_User_Extend = document.getElementById('note-Non-User-' + index);
                                    PR_Note_Non_User_Extend.style.height = 'auto';

                                    chevronDirectionNonUser = document.getElementById('chevronDownCircle' + index);
                                    chevronDirectionNonUser.style.transform = 'rotate(180deg)';

                                    partner_Note_Non_User = document.getElementById("note-Non-User-Partners-" + index);
                                    partner_Note_Non_User.style.height = 'auto';

                                    partner_Note_Chevron_Non_User = document.getElementById("chevronDownCircle-Partners-" + index);
                                    partner_Note_Chevron_Non_User.style.transform = 'rotate(180deg)';
                                }
                            }))
                            Card_Design = document.getElementById('Card-NonUser-' + note.index);
                            Card_Design.style.border = "solid #FFFFFF 2.5px";
                            Card_Design.style.background = "#64A1BB";
                            Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
                        } else {
                            Card_Design = document.getElementById('Card-NonUser-' + note.index);
                            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                            Card_Design.style.background = "rgb(59, 107, 139)";
                        }
                    }))

                    SSENNonUserNotes.map((function (note: any) { //Check non-user org array.
                        let arrayOfNote = note.note_Location.split(",");
                        let testValue: any;
                        testValue = Number(arrayOfNote[1]);
                        testValue = testValue.toFixed(3);

                        let testValue2: any;
                        testValue2 = Number(arrayOfNote[0]);
                        testValue2 = testValue2.toFixed(3);
                        // Values used to determine what note needs to be highlighted/fly to. 

                        if (testValue2 == markerClickedLocation.lng.toFixed(3) && testValue == markerClickedLocation.lat.toFixed(3)) {  // Checks note coordinates saved against note coordinates clicked, if they match highlights note and scrolls to it.

                            organisationArray.map((function (organisation: any, index: number) {
                                index++
                                if (note.note_Organisation === organisation) {

                                    PR_Note_Non_User_Extend = document.getElementById('note-Non-User-' + index);
                                    PR_Note_Non_User_Extend.style.height = 'auto';

                                    chevronDirectionNonUser = document.getElementById('chevronDownCircle' + index);
                                    chevronDirectionNonUser.style.transform = 'rotate(180deg)';

                                    SSEN_Note_Non_User = document.getElementById("note-Non-User-SSEN-" + index);
                                    SSEN_Note_Non_User.style.height = 'auto';

                                    SSEN_Note_Chevron_Non_User = document.getElementById("chevronDownCircle-SSEN-" + index);
                                    SSEN_Note_Chevron_Non_User.style.transform = 'rotate(180deg)';
                                }
                            }))
                            Card_Design = document.getElementById('Card-NonUser-' + note.index);
                            Card_Design.style.border = "solid #FFFFFF 2.5px";
                            Card_Design.style.background = "#64A1BB";
                            Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
                        } else {
                            Card_Design = document.getElementById('Card-NonUser-' + note.index);
                            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                            Card_Design.style.background = "rgb(59, 107, 139)";
                        }
                    }))
                    notesArray.map(function (note: any) {
                        Card_Design = document.getElementById('Card-' + note.index);
                        Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                        Card_Design.style.background = "rgb(59, 107, 139)";
                    })
                }

                setNoteMarkerType(0);
                setMarkerClickedLocation(0); // Resets values to 0, means it won't run again unless set elsewhere.
            }
        }
    },
        [markerClickedLocation, noteMarkerType]
    );























    useEffect(() => { // useEffect that is called when updateNotes is changed, which happens whenever a note is edited, added, deleted, the LRD is changed, or the mode is changed.   

        if (updateNotes != 0) { // Makes sure updateNotes has ben set elsewhere and isn't still the default 0. 
            let pathNoteTest: any;

            if (selectedArea === "All") { // If in the dropdown menu the user selects the all value, which some partners won't have acess to as they only have one LRD.  
                let allAddress: any
                allAddress = "";
                for (let i = 0; i < userDistrictIdArray.length; i++) {
                    allAddress = allAddress + userDistrictIdArray[i]; // Adds each LRD the user org has access to into a string. 
                    if ((i != userDistrictIdArray.length - 1)) { // If LRD isn't last in userDistrictIdArray adds a comma to the address.
                        allAddress = allAddress + ","
                    }
                }
                pathNoteTest = BASE_URL + getNotesPath + allAddress; // Adds created address onto end of path.

            } else {
                pathNoteTest = BASE_URL + getNotesPath + selectedArea; // Adds the selected LRD onto end of path
            }

            let headers = new Headers();
            headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);

            // Headers for fetch auth.

            fetch(pathNoteTest, { headers: headers })
                .then(function (response) { return response.json(); })
                .then(function (notesJson) {

                    const d = new Date();

                    let tempPublicNotesArray: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_ID: any; note_Visibility: any; }[] = []
                    let tempPublicNotesArrayNonUser: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_Organisation: any; note_ID: any; note_Visibility: any; }[] = []

                    let tempPartnersNotesArray: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_ID: any; note_Visibility: any; }[] = []
                    let tempPartnersNotesArrayNonUser: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_Organisation: any; note_ID: any; note_Visibility: any; }[] = []

                    let tempSSENNotesArray: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_ID: any; note_Visibility: any; }[] = []
                    let tempSSENNotesArrayNonUser: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_Organisation: any; note_ID: any; note_Visibility: any; }[] = []

                    let tempArraySSENNoNotes: any[] = [];
                    let tempArrayPartnerNoNotes: any[] = [];
                    let tempArrayPublicNoNotes: any[] = [];

                    let tempPublicExpiredNotesArray: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_ID: any; note_Visibility: any; }[] = []
                    let tempPublicExpiredNotesArrayNonUser: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_Organisation: any; note_ID: any; note_Visibility: any; }[] = []

                    let tempPartnersExpiredNotesArray: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_ID: any; note_Visibility: any; }[] = []
                    let tempPartnersExpiredNotesArrayNonUser: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_Organisation: any; note_ID: any; note_Visibility: any; }[] = []

                    let tempSSENExpiredNotesArray: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_ID: any; note_Visibility: any; }[] = []
                    let tempSSENExpiredNotesArrayNonUser: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_Organisation: any; note_ID: any; note_Visibility: any; }[] = []

                    let tempArraySSENExpiredNoNotes: any[] = [];
                    let tempArrayPartnerExpiredNoNotes: any[] = [];
                    let tempArrayPublicExpiredNoNotes: any[] = [];

                    let UserNoteCount = 0;
                    let NonUserNoteCount = 0;

                    let ExpiredUserNoteCount = 0;
                    let ExpiredNonUserNoteCount = 0;



                    // Make temp record arrays for the data we get from the backend, where to place notes for a local version.  

                    let index = notesJson.length + 1; // How many notes there are saved. 
                    let tempOrganisationArray: any[] = []
                    let faultArray: any[] = [];


                    const fetchData = async () => {
                        const response = await fetch(pathLiveFaults) as any;
                        const json = await response.json();
                        for (let i = 0; i < json.Faults.length; i++) {
                            faultArray.push(json.Faults[i].reference);
                        }

                        if (notesJson.length == 0 && noteToggle == "ON") {
                            setPublicUserNotes([]);
                            setPublicNonUserNotes([]);

                            setPartnersUserNotes([]);
                            setPartnersNonUserNotes([])

                            setSSENUserNotes([]);
                            setSSENNonUserNotes([]);

                            setOrganisationSSENArrayNo([])
                            setOrganisationPartnerArrayNo([])
                            setOrganisationPublicArrayNo([])

                            setPublicExpiredUserNotes([]);
                            setPartnersExpiredUserNotes([]);
                            setSSENExpiredUserNotes([]);

                            setPublicExpiredNonUserNotes([]);
                            setPartnersExpiredNonUserNotes([]);
                            setSSENExpiredNonUserNotes([]);


                        }

                        notesJson.forEach((note: any) => { // Goes through each note obtained through the fetch, keeps count of every note with the index.  
                            index--;

                            let note_Expiration = note.Expiration;
                            let note_Fault = note.LinkedTo;
                            let passTimeCheck: any;

                            if (note.noteExpirationFlag != "Note has expired") {
                                if (note_Expiration.length > 3 && note_Expiration != "undefined") {


                                    let dateTest: any;
                                    dateTest = new Date();

                                    passTimeCheck = "Fail";
                                    if (note_Expiration > dateTest.getTime()) {
                                        passTimeCheck = "Pass";
                                    } else if (note.organisation === resiliencePartner) {


                                        let headersForEdit = new Headers();
                                        headersForEdit.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
                                        headersForEdit.append("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
                                        let pathNoteEditTest: any
                                        pathNoteEditTest = BASE_URL + "/gridim/rotaload/partners";

                                        var details = {
                                            'action': 'updateNote',
                                            'id': note.id,
                                            'noteExpirationFlag': 'Note has expired',
                                        };
                                        const formBody = Object.entries(details).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&')

                                        // fetch POST method used to post the now edited data to the backend, if fetch fails an error is thrown, if it doesn't fail notes are set to update and type of note action is set. 

                                        fetch(pathNoteEditTest, {
                                            method: 'POST',
                                            headers: headersForEdit,
                                            body: formBody
                                        })
                                            .then(function (response) {
                                                if (!response.ok) {
                                                    throw new Error("HTTP status " + response.status);
                                                }
                                            }
                                            )
                                    }

                                    let noteFaultPass: any;
                                    if (passTimeCheck === "Pass") {

                                        noteFaultPass = "Fail"
                                        for (let i = 0; i < faultArray.length; i++) {

                                            if (note_Fault === faultArray[i] || note_Fault === "No_Fault") {
                                                noteFaultPass = "Pass"
                                            }
                                        }

                                        if (noteFaultPass === "Pass") {
                                            if (note.organisation === resiliencePartner) { // Checks org of note against logged in org
                                                let note_Content = note.text;
                                                let note_Time__Reformatted_part_1 = note.created.split('T');
                                                let note_Time__Reformatted_part_2 = note_Time__Reformatted_part_1[1].split('.');
                                                let note_Time__Reformatted = note_Time__Reformatted_part_2[0] + " " + note_Time__Reformatted_part_1[0];
                                                let note_locatonLongLat = String(note.longitude) + ", " + String(note.latitude);
                                                let note_District = note.district;
                                                let note_ID = note.id;
                                                let note_Visibility = note.Visibility;
                                                UserNoteCount = 1;

                                                if (note_Visibility === "SSEN") {
                                                    tempSSENNotesArray.push({
                                                        index: index,
                                                        note_Content: note_Content,
                                                        note_Creation: note_Time__Reformatted,
                                                        note_Location: note_locatonLongLat,
                                                        note_District: note_District,
                                                        note_ID: note_ID,
                                                        note_Visibility: note_Visibility
                                                    }
                                                    )

                                                } else if (note_Visibility === "Partners") {
                                                    tempPartnersNotesArray.push({
                                                        index: index,
                                                        note_Content: note_Content,
                                                        note_Creation: note_Time__Reformatted,
                                                        note_Location: note_locatonLongLat,
                                                        note_District: note_District,
                                                        note_ID: note_ID,
                                                        note_Visibility: note_Visibility
                                                    }
                                                    )
                                                } else if (note_Visibility === "Public") {
                                                    tempPublicNotesArray.push({
                                                        index: index,
                                                        note_Content: note_Content,
                                                        note_Creation: note_Time__Reformatted,
                                                        note_Location: note_locatonLongLat,
                                                        note_District: note_District,
                                                        note_ID: note_ID,
                                                        note_Visibility: note_Visibility
                                                    }
                                                    )

                                                }

                                            } else {

                                                if (!tempOrganisationArray.includes(note.organisation)) { // Checks if temp org array has current notes org in it, if not adds it. 
                                                    tempOrganisationArray.push(note.organisation);
                                                }

                                                let note_Content = note.text;
                                                let note_Time__Reformatted_part_1 = note.created.split('T');
                                                let note_Time__Reformatted_part_2 = note_Time__Reformatted_part_1[1].split('.');
                                                let note_Time__Reformatted = note_Time__Reformatted_part_2[0] + " " + note_Time__Reformatted_part_1[0];
                                                let note_locatonLongLat = String(note.longitude) + ", " + String(note.latitude);
                                                let note_District = note.district;
                                                let note_Organisation = note.organisation;
                                                let note_ID = note.id;
                                                let note_Visibility = note.Visibility
                                                NonUserNoteCount = 1;
                                                if (note_Visibility === "SSEN") {
                                                    let countTest: any;
                                                    tempSSENNotesArrayNonUser.push({
                                                        index: index,
                                                        note_Content: note_Content,
                                                        note_Creation: note_Time__Reformatted,
                                                        note_Location: note_locatonLongLat,
                                                        note_District: note_District,
                                                        note_Organisation: note_Organisation,
                                                        note_ID: note_ID,
                                                        note_Visibility: note_Visibility
                                                    })
                                                    if (tempArraySSENNoNotes.length == 0) {
                                                        tempArraySSENNoNotes.push([note.organisation, 1]);
                                                    } else {
                                                        for (let i = 0; i < tempArraySSENNoNotes.length; i++) {
                                                            if (tempArraySSENNoNotes[i][0] == note.organisation) {
                                                                countTest = 1;
                                                                tempArraySSENNoNotes[i][1] = tempArraySSENNoNotes[i][1] + 1;
                                                                break;
                                                            }
                                                        }
                                                        if (countTest != 1) {
                                                            tempArraySSENNoNotes.push([note.organisation, 1]);
                                                        }
                                                    }


                                                } else if (note_Visibility === "Partners") {
                                                    let countTest: any;
                                                    tempPartnersNotesArrayNonUser.push({
                                                        index: index,
                                                        note_Content: note_Content,
                                                        note_Creation: note_Time__Reformatted,
                                                        note_Location: note_locatonLongLat,
                                                        note_District: note_District,
                                                        note_Organisation: note_Organisation,
                                                        note_ID: note_ID,
                                                        note_Visibility: note_Visibility
                                                    }
                                                    )
                                                    if (tempArrayPartnerNoNotes.length == 0) {
                                                        tempArrayPartnerNoNotes.push([note.organisation, 1]);
                                                    } else {
                                                        for (let i = 0; i < tempArrayPartnerNoNotes.length; i++) {
                                                            if (tempArrayPartnerNoNotes[i][0] == note.organisation) {
                                                                countTest = 1;
                                                                tempArrayPartnerNoNotes[i][1] = tempArrayPartnerNoNotes[i][1] + 1;
                                                                break;
                                                            }
                                                        }
                                                        if (countTest != 1) {
                                                            tempArrayPartnerNoNotes.push([note.organisation, 1]);
                                                        }
                                                    }
                                                } else if (note_Visibility === "Public") {
                                                    let countTest: any;
                                                    tempPublicNotesArrayNonUser.push({
                                                        index: index,
                                                        note_Content: note_Content,
                                                        note_Creation: note_Time__Reformatted,
                                                        note_Location: note_locatonLongLat,
                                                        note_District: note_District,
                                                        note_Organisation: note_Organisation,
                                                        note_ID: note_ID,
                                                        note_Visibility: note_Visibility
                                                    }
                                                    )
                                                    if (tempArrayPublicNoNotes.length == 0) {
                                                        {/*Review for RESILIENCE 136 */ }
                                                        tempArrayPublicNoNotes.push([note.organisation, 1]);
                                                    } else {
                                                        for (let i = 0; i < tempArrayPublicNoNotes.length; i++) {
                                                            if (tempArrayPublicNoNotes[i][0] == note.organisation) {
                                                                countTest = 1;
                                                                tempArrayPublicNoNotes[i][1] = tempArrayPublicNoNotes[i][1] + 1;
                                                                break;
                                                            }
                                                        }
                                                        if (countTest != 1) {
                                                            tempArrayPublicNoNotes.push([note.organisation, 1]);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    tempPublicNotesArray.reverse();
                                    tempPublicNotesArrayNonUser.reverse();
                                    tempPartnersNotesArray.reverse();
                                    tempPartnersNotesArrayNonUser.reverse();
                                    tempSSENNotesArray.reverse();
                                    tempSSENNotesArrayNonUser.reverse();


                                    setPublicUserNotes(tempPublicNotesArray);
                                    setPublicNonUserNotes(tempPublicNotesArrayNonUser);

                                    setPartnersUserNotes(tempPartnersNotesArray);
                                    setPartnersNonUserNotes(tempPartnersNotesArrayNonUser)

                                    setSSENUserNotes(tempSSENNotesArray);
                                    setSSENNonUserNotes(tempSSENNotesArrayNonUser);

                                    setOrganisationSSENArrayNo(tempArraySSENNoNotes)
                                    setOrganisationPartnerArrayNo(tempArrayPartnerNoNotes)
                                    setOrganisationPublicArrayNo(tempArrayPublicNoNotes)

                                } else (
                                    console.log("Caught an undefined")
                                )
                            } else {
                                if (note.organisation === resiliencePartner) {
                                    let note_Content = note.text;
                                    let note_Time__Reformatted_part_1 = note.created.split('T');
                                    let note_Time__Reformatted_part_2 = note_Time__Reformatted_part_1[1].split('.');
                                    let note_Time__Reformatted = note_Time__Reformatted_part_2[0] + " " + note_Time__Reformatted_part_1[0];
                                    let note_locatonLongLat = String(note.longitude) + ", " + String(note.latitude);
                                    let note_District = note.district;
                                    let note_ID = note.id;
                                    let note_Visibility = note.Visibility;
                                    ExpiredUserNoteCount = 1;


                                    if (note_Visibility === "SSEN") {
                                        tempSSENExpiredNotesArray.push({
                                            index: index,
                                            note_Content: note_Content,
                                            note_Creation: note_Time__Reformatted,
                                            note_Location: note_locatonLongLat,
                                            note_District: note_District,
                                            note_ID: note_ID,
                                            note_Visibility: note_Visibility
                                        }
                                        )

                                    } else if (note_Visibility === "Partners") {
                                        tempPartnersExpiredNotesArray.push({
                                            index: index,
                                            note_Content: note_Content,
                                            note_Creation: note_Time__Reformatted,
                                            note_Location: note_locatonLongLat,
                                            note_District: note_District,
                                            note_ID: note_ID,
                                            note_Visibility: note_Visibility
                                        }
                                        )
                                    } else if (note_Visibility === "Public") {
                                        tempPublicExpiredNotesArray.push({
                                            index: index,
                                            note_Content: note_Content,
                                            note_Creation: note_Time__Reformatted,
                                            note_Location: note_locatonLongLat,
                                            note_District: note_District,
                                            note_ID: note_ID,
                                            note_Visibility: note_Visibility
                                        }
                                        )

                                    }
                                } else {

                                    if (!tempOrganisationArray.includes(note.organisation)) { // Checks if temp org array has current notes org in it, if not adds it. 
                                        tempOrganisationArray.push(note.organisation);
                                    }

                                    let note_Content = note.text;
                                    let note_Time__Reformatted_part_1 = note.created.split('T');
                                    let note_Time__Reformatted_part_2 = note_Time__Reformatted_part_1[1].split('.');
                                    let note_Time__Reformatted = note_Time__Reformatted_part_2[0] + " " + note_Time__Reformatted_part_1[0];
                                    let note_locatonLongLat = String(note.longitude) + ", " + String(note.latitude);
                                    let note_District = note.district;
                                    let note_Organisation = note.organisation;
                                    let note_ID = note.id;
                                    let note_Visibility = note.Visibility
                                    ExpiredNonUserNoteCount = 1;

                                    if (note_Visibility === "SSEN") {
                                        let countTest: any;
                                        tempSSENExpiredNotesArrayNonUser.push({
                                            index: index,
                                            note_Content: note_Content,
                                            note_Creation: note_Time__Reformatted,
                                            note_Location: note_locatonLongLat,
                                            note_District: note_District,
                                            note_Organisation: note_Organisation,
                                            note_ID: note_ID,
                                            note_Visibility: note_Visibility
                                        })
                                        if (tempArraySSENExpiredNoNotes.length == 0) {
                                            tempArraySSENExpiredNoNotes.push([note.organisation, 1]);
                                        } else {
                                            for (let i = 0; i < tempArraySSENExpiredNoNotes.length; i++) {
                                                if (tempArraySSENExpiredNoNotes[i][0] == note.organisation) {
                                                    countTest = 1;
                                                    tempArraySSENExpiredNoNotes[i][1] = tempArraySSENExpiredNoNotes[i][1] + 1;
                                                    break;
                                                }
                                            }
                                            if (countTest != 1) {
                                                tempArraySSENExpiredNoNotes.push([note.organisation, 1]);
                                            }
                                        }


                                    } else if (note_Visibility === "Partners") {
                                        let countTest: any;
                                        tempPartnersExpiredNotesArrayNonUser.push({
                                            index: index,
                                            note_Content: note_Content,
                                            note_Creation: note_Time__Reformatted,
                                            note_Location: note_locatonLongLat,
                                            note_District: note_District,
                                            note_Organisation: note_Organisation,
                                            note_ID: note_ID,
                                            note_Visibility: note_Visibility
                                        }
                                        )
                                        if (tempArrayPartnerExpiredNoNotes.length == 0) {
                                            tempArrayPartnerExpiredNoNotes.push([note.organisation, 1]);
                                        } else {
                                            for (let i = 0; i < tempArrayPartnerExpiredNoNotes.length; i++) {
                                                if (tempArrayPartnerExpiredNoNotes[i][0] == note.organisation) {
                                                    countTest = 1;
                                                    tempArrayPartnerExpiredNoNotes[i][1] = tempArrayPartnerExpiredNoNotes[i][1] + 1;
                                                    break;
                                                }
                                            }
                                            if (countTest != 1) {
                                                tempArrayPartnerExpiredNoNotes.push([note.organisation, 1]);
                                            }
                                        }
                                    } else if (note_Visibility === "Public") {
                                        let countTest: any;
                                        tempPublicExpiredNotesArrayNonUser.push({
                                            index: index,
                                            note_Content: note_Content,
                                            note_Creation: note_Time__Reformatted,
                                            note_Location: note_locatonLongLat,
                                            note_District: note_District,
                                            note_Organisation: note_Organisation,
                                            note_ID: note_ID,
                                            note_Visibility: note_Visibility
                                        }
                                        )
                                        if (tempArrayPublicExpiredNoNotes.length == 0) {
                                            tempArrayPublicExpiredNoNotes.push([note.organisation, 1]);
                                        } else {
                                            for (let i = 0; i < tempArrayPublicExpiredNoNotes.length; i++) {
                                                if (tempArrayPublicExpiredNoNotes[i][0] == note.organisation) {
                                                    countTest = 1;
                                                    tempArrayPublicExpiredNoNotes[i][1] = tempArrayPublicExpiredNoNotes[i][1] + 1;
                                                    break;
                                                }
                                            }
                                            if (countTest != 1) {
                                                tempArrayPublicExpiredNoNotes.push([note.organisation, 1]);
                                            }
                                        }
                                    }
                                }
                                tempPublicExpiredNotesArray.reverse();
                                tempPublicExpiredNotesArrayNonUser.reverse();
                                tempPartnersExpiredNotesArray.reverse();
                                tempPartnersExpiredNotesArrayNonUser.reverse();
                                tempSSENExpiredNotesArray.reverse();
                                tempSSENExpiredNotesArrayNonUser.reverse();


                                setPublicExpiredUserNotes(tempPublicExpiredNotesArray);
                                setPublicExpiredNonUserNotes(tempPublicExpiredNotesArrayNonUser);

                                setPartnersExpiredUserNotes(tempPartnersExpiredNotesArray);
                                setPartnersExpiredNonUserNotes(tempPartnersExpiredNotesArrayNonUser)

                                setSSENExpiredUserNotes(tempSSENExpiredNotesArray);
                                setSSENExpiredNonUserNotes(tempSSENExpiredNotesArrayNonUser);


                            }


                        });
                        if (UserNoteCount != 1) {
                            setPublicUserNotes([]);
                            setPartnersUserNotes([]);
                            setSSENUserNotes([]);
                        }
                        if (NonUserNoteCount != 1) {
                            setPublicNonUserNotes([]);
                            setPartnersNonUserNotes([]);
                            setSSENNonUserNotes([]);
                        }

                        if (ExpiredUserNoteCount != 1) {
                            setPublicExpiredUserNotes([]);
                            setPartnersExpiredUserNotes([]);
                            setSSENExpiredUserNotes([]);
                        }
                        if (ExpiredNonUserNoteCount != 1) {
                            setPublicExpiredNonUserNotes([]);
                            setPartnersExpiredNonUserNotes([]);
                            setSSENExpiredNonUserNotes([]);
                        }

                        if (UserNoteCount != 1 && NonUserNoteCount != 1) {
                            setOrganisationSSENArrayNo([]);
                            setOrganisationPartnerArrayNo([]);
                            setOrganisationPublicArrayNo([]);
                        }
                    }

                    fetchData();


                    if (typeOfNoteAction === "Show Notes") { // Checks the reason for the update, "Show Notes" is for when the mode is toggled.
                        notesArray.map(function (note: any) { // Goes through every note in the array and changes style back to default, gets rid of any highlighting. 
                            Card_Design = document.getElementById('Card-' + note.index);
                            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                            Card_Design.style.background = "rgb(59, 107, 139)";
                        })
                    }

                    if (typeOfNoteAction === "Add Note") { // Checks the reason for the update, "Add Notes" is for when a new note is being added. 
                        let highlightedCardIndex: any;
                        notesArray.map(function (note: any) {
                            Card_Design = document.getElementById('Card-' + note.index);
                            if (Card_Design.style.border === "2.5px solid rgb(255, 255, 255)" && Card_Design.style.background === "rgb(100, 161, 187)" && highlightedCardIndex != "Highlight Moved.") {
                                highlightedCardIndex = note.index;
                                if (typeOfNoteAction === "Add Note") {
                                    if (notesArray.length === highlightedCardIndex) {
                                        setHighlightLastNote("Highlight Last Note")
                                    } else {
                                        Card_Design = document.getElementById('Card-' + highlightedCardIndex);
                                        Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                                        Card_Design.style.background = "rgb(59, 107, 139)";

                                        Card_Design = document.getElementById('Card-' + (highlightedCardIndex + 1));
                                        Card_Design.style.border = "2.5px solid rgb(255, 255, 255)";
                                        Card_Design.style.background = "rgb(100, 161, 187)";
                                        Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });

                                        highlightedCardIndex = "Highlight Moved."
                                    }
                                }
                            }
                        })

                    }

                    if (typeOfNoteAction === "Delete Note") { // Checks the reason for the update, "Show Notes" is for when the mode is toggled.
                        notesArray.map(function (note: any) { // Goes through every note in the array and changes style back to default, gets rid of any highlighting. 
                            Card_Design = document.getElementById('Card-' + note.index);
                            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                            Card_Design.style.background = "rgb(59, 107, 139)";
                        })
                    }



                    setOrganisationArray(tempOrganisationArray);


                    setUpdateNotes(0);
                    setTypeOfNoteAction(0);

                });
        }
    },
        [updateNotes]
    )


    function refreshNotes() {
        setUpdateNotes("Update");
        setRefreshButton("Disabled");

    }



    return (
        <>
            <div className='scenario-Planner-Style' id='scenario-Planner-Style-2'>
                <div className="horizontal_dotted_line" id='horizontal-dotted-line-2' ></div>
                <span>Notes Assigned To This LRD  <IonButton className='scenario-Planner-Buttons' id='refresh-Note-Button' onClick={() => refreshNotes()}>Refresh</IonButton></span>
                <div>
                    <IonButton className='scenario-Planner-Buttons' id='add-Note-Button' onClick={() => setSaveNoteButtonClick("Clicked")}>Add Note</IonButton>
                </div>
            </div>





            <SaveNoteComponent
                setUpdateNotes={setUpdateNotes}
                noteMouseActive={noteMouseActive}
                setNoteMouseActive={setNoteMouseActive}
                setMouseAppearance={setMouseAppearance}
                setModalVisibility={setModalVisibility}
                setNoteCancelled={setNoteCancelled}
                setNoteLocationSelection={setNoteLocationSelection}
                setOutsideOrInsideBoundry={setOutsideOrInsideBoundry}
                outsideOrInsideBoundry={outsideOrInsideBoundry}
                XCoordConfirmed={XCoordConfirmed}
                YCoordConfirmed={YCoordConfirmed}
                setLinkedToFault={setLinkedToFault}
                linkedToFault={linkedToFault}
                permNote={permNote}
                setPermNote={setPermNote}
                noteExpiryDate={noteExpiryDate}
                setNoteExpiryDate={setNoteExpiryDate}
                notesArray={notesArray}
                setNotesArray={setNotesArray}
                faultReferenceToBeSaved={faultReferenceToBeSaved}
                newNoteVisibility={newNoteVisibility}
                selectedArea={selectedArea}
                lastSelectedArea={lastSelectedArea}
                addNoteAllLocation={addNoteAllLocation}
                setTypeOfNoteAction={setTypeOfNoteAction}
                setNoteSaveCount={setNoteSaveCount}
                noteSaveCount={noteSaveCount}
                setSelectedArea={setSelectedArea}
                setAddMarkerToArray={setAddMarkerToArray}
                resiliencePartner={resiliencePartner}
                setFaultReferenceToBeSaved={setFaultReferenceToBeSaved}
                faultArrayForSave={faultArrayForSave}
                publicUserNotes={publicUserNotes}
                partnersUserNotes={partnersUserNotes}
                SSENUserNotes={SSENUserNotes}
                publicNonUserNotes={publicNonUserNotes}
                partnersNonUserNotes={partnersNonUserNotes}
                SSENNonUserNotes={SSENNonUserNotes}
                organisationArray={organisationArray}
                saveNoteButtonClick={saveNoteButtonClick}
                setSaveNoteButtonClick={setSaveNoteButtonClick}
                readableSelectedArea={readableSelectedArea}
                setReadableSelectedArea={setReadableSelectedArea}
                setSidebarExpanded={setSidebarExpanded} />

            <EditNoteComponent
                setUpdateNotes={setUpdateNotes}


                setModalVisibility={setModalVisibility}
                setTypeOfNoteAction={setTypeOfNoteAction}
                resiliencePartner={resiliencePartner}
                publicUserNotes={publicUserNotes}
                partnersUserNotes={partnersUserNotes}
                SSENUserNotes={SSENUserNotes}
                publicNonUserNotes={publicNonUserNotes}
                partnersNonUserNotes={partnersNonUserNotes}
                SSENNonUserNotes={SSENNonUserNotes}
                organisationArray={organisationArray}
                setEditButtonNumber={setEditButtonNumber}
                setDeleteNoteReference={setDeleteNoteReference}
                setDeleteDateReference={setDeleteDateReference}

                setDeleteDistrictReference={setDeleteDistrictReference}
                setDeleteIDReference={setDeleteIDReference}
                setDeleteLocationReferenceX={setDeleteLocationReferenceX}
                setDeleteLocationReferenceY={setDeleteLocationReferenceY}

                deleteIDReference={deleteIDReference}

                deleteDistrictReference={deleteDistrictReference}
                deleteLocationReferenceX={deleteLocationReferenceX}
                deleteLocationReferenceY={deleteLocationReferenceY}
                deleteDateReference={deleteDateReference}

                editPublicNoteButtonClick={editPublicNoteButtonClick}




                setEditPublicNoteButtonClick={setEditPublicNoteButtonClick}

                editPartnerNoteButtonClick={editPartnerNoteButtonClick}
                setEditPartnerNoteButtonClick={setEditPartnerNoteButtonClick}

                editSSENNoteButtonClick={editSSENNoteButtonClick}
                setEditSSENNoteButtonClick={setEditSSENNoteButtonClick}

                readableSelectedArea={readableSelectedArea}
                setReadableSelectedArea={setReadableSelectedArea}

                userDistrictIdArray={userDistrictIdArray}
                userDistrictNameArray={userDistrictNameArray}

                setSidebarExpanded={setSidebarExpanded}

                extendedNotesArray={extendedNotesArray}
                setExtendedNotesArray={setExtendedNotesArray}

            />

            <DeleteNoteComponent
                setUpdateNotes={setUpdateNotes}


                setModalVisibility={setModalVisibility}
                setTypeOfNoteAction={setTypeOfNoteAction}
                resiliencePartner={resiliencePartner}
                publicUserNotes={publicUserNotes}
                partnersUserNotes={partnersUserNotes}
                SSENUserNotes={SSENUserNotes}
                publicNonUserNotes={publicNonUserNotes}
                partnersNonUserNotes={partnersNonUserNotes}
                SSENNonUserNotes={SSENNonUserNotes}
                organisationArray={organisationArray}

                setDeleteNoteReference={setDeleteNoteReference}
                setDeleteDateReference={setDeleteDateReference}

                setDeleteDistrictReference={setDeleteDistrictReference}
                setDeleteIDReference={setDeleteIDReference}
                setDeleteLocationReferenceX={setDeleteLocationReferenceX}
                setDeleteLocationReferenceY={setDeleteLocationReferenceY}

                deleteIDReference={deleteIDReference}

                deleteDistrictReference={deleteDistrictReference}
                deleteLocationReferenceX={deleteLocationReferenceX}
                deleteLocationReferenceY={deleteLocationReferenceY}
                deleteDateReference={deleteDateReference}

                setDeleteButtonNumber={setDeleteButtonNumber}

                setDeleteMarker={setDeleteMarker}
                deleteButtonNumber={deleteButtonNumber}


                deletePublicNoteButtonClick={deletePublicNoteButtonClick}
                setDeletePublicNoteButtonClick={setDeletePublicNoteButtonClick}

                deletePartnerNoteButtonClick={deletePartnerNoteButtonClick}
                setDeletePartnerNoteButtonClick={setDeletePartnerNoteButtonClick}

                deleteSSENNoteButtonClick={deleteSSENNoteButtonClick}
                setDeleteSSENNoteButtonClick={setDeleteSSENNoteButtonClick}

                readableSelectedArea={readableSelectedArea}
                setReadableSelectedArea={setReadableSelectedArea}
                userDistrictIdArray={userDistrictIdArray}
                userDistrictNameArray={userDistrictNameArray}
                setSidebarExpanded={setSidebarExpanded}

                extendedNotesArray={extendedNotesArray}
                setExtendedNotesArray={setExtendedNotesArray}

            />

            <ShowNoteComponent
                setUpdateNotes={setUpdateNotes}


                setModalVisibility={setModalVisibility}

                resiliencePartner={resiliencePartner}
                publicUserNotes={publicUserNotes}
                partnersUserNotes={partnersUserNotes}
                SSENUserNotes={SSENUserNotes}
                publicNonUserNotes={publicNonUserNotes}
                partnersNonUserNotes={partnersNonUserNotes}
                SSENNonUserNotes={SSENNonUserNotes}
                organisationArray={organisationArray}

                setDeleteNoteReference={setDeleteNoteReference}
                setDeleteDateReference={setDeleteDateReference}

                setDeleteDistrictReference={setDeleteDistrictReference}
                setDeleteIDReference={setDeleteIDReference}
                setDeleteLocationReferenceX={setDeleteLocationReferenceX}
                setDeleteLocationReferenceY={setDeleteLocationReferenceY}



                deleteDistrictReference={deleteDistrictReference}
                deleteLocationReferenceX={deleteLocationReferenceX}
                deleteLocationReferenceY={deleteLocationReferenceY}
                deleteDateReference={deleteDateReference}
                setNoteOrg={setNoteOrg}
                noteOrg={noteOrg}
                showPublicNoteButtonClick={showPublicNoteButtonClick}
                setShowPublicNoteButtonClick={setShowPublicNoteButtonClick}

                showPartnerNoteButtonClick={showPartnerNoteButtonClick}
                setShowPartnerNoteButtonClick={setShowPartnerNoteButtonClick}

                showSSENNoteButtonClick={showSSENNoteButtonClick}
                setShowSSENNoteButtonClick={setShowSSENNoteButtonClick}

                showPublicNonUserNoteButtonClick={showPublicNonUserNoteButtonClick}
                setShowPublicNonUserNoteButtonClick={setShowPublicNonUserNoteButtonClick}

                showPartnerNonUserNoteButtonClick={showPartnerNonUserNoteButtonClick}
                setShowPartnerNonUserNoteButtonClick={setShowPartnerNonUserNoteButtonClick}

                showSSENNonUserNoteButtonClick={showSSENNonUserNoteButtonClick}
                setShowSSENNonUserNoteButtonClick={setShowSSENNonUserNoteButtonClick}

                readableSelectedArea={readableSelectedArea}
                setReadableSelectedArea={setReadableSelectedArea}
                userDistrictIdArray={userDistrictIdArray}
                userDistrictNameArray={userDistrictNameArray}
                setSidebarExpanded={setSidebarExpanded}

                extendedNotesArray={extendedNotesArray}
                setExtendedNotesArray={setExtendedNotesArray}
            />
            <DisplayNoteComponent
                resiliencePartner={resiliencePartner}
                publicUserNotes={publicUserNotes}
                partnersUserNotes={partnersUserNotes}
                SSENUserNotes={SSENUserNotes}
                publicNonUserNotes={publicNonUserNotes}
                partnersNonUserNotes={partnersNonUserNotes}
                SSENNonUserNotes={SSENNonUserNotes}
                organisationArray={organisationArray}

                setShowPublicNoteButtonClick={setShowPublicNoteButtonClick}


                setShowPartnerNoteButtonClick={setShowPartnerNoteButtonClick}


                setShowSSENNoteButtonClick={setShowSSENNoteButtonClick}


                setShowPublicNonUserNoteButtonClick={setShowPublicNonUserNoteButtonClick}


                setShowPartnerNonUserNoteButtonClick={setShowPartnerNonUserNoteButtonClick}


                setShowSSENNonUserNoteButtonClick={setShowSSENNonUserNoteButtonClick}

                setDeletePublicNoteButtonClick={setDeletePublicNoteButtonClick}


                setDeletePartnerNoteButtonClick={setDeletePartnerNoteButtonClick}


                setDeleteSSENNoteButtonClick={setDeleteSSENNoteButtonClick}

                setEditPublicNoteButtonClick={setEditPublicNoteButtonClick}

                setEditPartnerNoteButtonClick={setEditPartnerNoteButtonClick}

                setEditSSENNoteButtonClick={setEditSSENNoteButtonClick}

                modalVisibility={modalVisibility}

                mouseAppearance={mouseAppearance}

                setNoteToHighlight={setNoteToHighlight}

                setNoteMarkerType={setNoteMarkerType}

                setmapOrSidebarClick={setmapOrSidebarClick}

                setUpdateNotes={setUpdateNotes}

                highlightLastNote={highlightLastNote}

                notesArray={notesArray}

                setHighlightLastNote={setHighlightLastNote}

                organisationPublicArrayNo={organisationPublicArrayNo}

                organisationPartnerArrayNo={organisationPartnerArrayNo}

                organisationSSENArrayNo={organisationSSENArrayNo}

                setSidebarExpanded={setSidebarExpanded}



                extendedNotesArray={extendedNotesArray}
                setExtendedNotesArray={setExtendedNotesArray}
            />
        </>
    );
}

export default NoteComponent
import { IonButton, IonCard, IonCardContent, IonCardTitle, IonIcon, IonSelect, IonSelectOption } from "@ionic/react";
import { BASE_URL, pathLiveFaults } from '../mapContentComponent';
import { ACCESS_CREDENTIALS } from "../../../pages/Home";
import './AddNoteComponent.css';
import 'mapbox-gl/dist/mapbox-gl.css';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import * as turf from '@turf/turf'
import { useRef, useEffect } from 'react';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import _ from 'lodash';
import { create } from "ionicons/icons";
import { mouseState } from "../../../enums/enums";


type AddNoteProps = {

  noteLocationSelection: any;
  selectedArea: any;
  currentBoundaries: any;
  setMarkerClickedLocation: any;
  setNoteMarkerType: any;
  setNoteToHighlight: any;
  setmapOrSidebarClick: any;
  boundsZoom: any;
  setLatLong: any;

  setMarkerCancelArray: any;
  setMouseAppearance: any;
  setTempMarkerStorage: any;
  setAddNoteAllLocation: any;
  setNoteMouseActive: any;
  setUpdateNotes: any;
  setfaultArrayForSave: any;
  setOutsideOrInsideBoundry: any;
  setNoteLocationSelection: any;

  userDistrictIdArray: any;
  boundariesArray: any;

  addMarkerToArray: any;
  markerArray: any;
  tempMarkerStorage: any;

  setMarkerArray: any;
  setAddMarkerToArray: any;

  markerCancelArray: any;
  setEnableButtons: any;

  setXCoordConfirmed: any;
  setYCoordConfirmed: any;
  setNewNoteVisibility: any;

  latLong: any;

  noteCancelled: any;
  setNoteCancelled: any;
  MapThing: any;

  readableSelectedArea: any;
  setReadableSelectedArea: any;

  userDistrictNameArray: any;
}


const AddNoteComponent: React.FC<AddNoteProps> = ({

  noteLocationSelection,
  selectedArea,
  currentBoundaries,
  setMarkerClickedLocation,
  setNoteMarkerType,
  setNoteToHighlight,
  setmapOrSidebarClick,
  boundsZoom,
  setLatLong,

  setMarkerCancelArray,
  setMouseAppearance,
  setTempMarkerStorage,
  setAddNoteAllLocation,
  setNoteMouseActive,
  setUpdateNotes,
  setfaultArrayForSave,
  setOutsideOrInsideBoundry,
  setNoteLocationSelection,
  userDistrictIdArray,
  boundariesArray,

  addMarkerToArray,
  markerArray,
  tempMarkerStorage,
  setMarkerArray,
  setAddMarkerToArray,
  markerCancelArray,
  setEnableButtons,
  setXCoordConfirmed,
  setYCoordConfirmed,
  setNewNoteVisibility,
  latLong,
  noteCancelled,
  setNoteCancelled,
  MapThing,
  readableSelectedArea,
  setReadableSelectedArea,
  userDistrictNameArray
}) => {


  let modal_Postcode: any;
  let modal_Container2: any;
  let mapCancelMarkers: any[] = [];
  let visibilityButtonPosition: any;
  let postcodeModalHeader: any; 
  let modalPublicHeader: any; 
  let modalPublic: any; 
  let publicConfirmationButtons: any; 



  useEffect(() => {
    if (noteLocationSelection !== 0) {
      let outofbounds: any;
      if (selectedArea !== 0 && selectedArea !== "All") {
        outofbounds = 0;
        MapThing.once('click', async (e: any) => {
          const el = document.createElement('div');
          el.className = 'markerPublicNote';
          let point = turf.point([e.lngLat.lng, e.lngLat.lat]);

          let isInsideBoundary = "Outside";
          for (let i = 0; i < currentBoundaries.geometry.coordinates[0].length; i++) {


            var polygon = turf.polygon([currentBoundaries.geometry.coordinates[0][i][0]]);

            if (turf.booleanPointInPolygon(point, polygon)) {
              let markerVar: any;
              markerVar = new mapboxgl.Marker({
                element: el,
                offset: [3, -18],
                anchor: 'bottom'

              })
                .setLngLat(e.lngLat)
                .addTo(MapThing);
              markerVar.getElement().addEventListener('click', () => {
                setMarkerClickedLocation(markerVar.getLngLat());
                setNoteMarkerType("User")
                setNoteToHighlight(markerVar.getLngLat());
                setmapOrSidebarClick("Map");
                if (MapThing.getZoom() < boundsZoom) {
                  MapThing.flyTo({
                    center: [markerVar._lngLat.lng, markerVar._lngLat.lat],
                    duration: 1200,
                    essential: true,
                    zoom: boundsZoom
                  });
                } else {
                  MapThing.flyTo({
                    center: [markerVar._lngLat.lng, markerVar._lngLat.lat],
                    duration: 1200,
                    essential: true,
                  })
                };
              });
              modal_Postcode = document.getElementById('modal-Postcode');
              modal_Postcode.style.display = "block";

              if (window.innerWidth == 640 || window.innerWidth < 640) {
                modal_Postcode.style.width = '80%'
                visibilityButtonPosition = document.getElementById('visibility-button-column');
                visibilityButtonPosition.style.flexDirection = 'column'
                postcodeModalHeader = document.getElementById('modal-header-postcode');
                postcodeModalHeader.style.width = '100%'
                modal_Postcode = document.getElementById('modal-Postcode');
                modal_Postcode.style.width = '80%'
                modalPublicHeader = document.getElementById('modal-Header-Public');
                modalPublicHeader.style.width = '100%'
                modalPublic = document.getElementById('modal-Public');
                modalPublic.style.width = '80%'
                publicConfirmationButtons = document.getElementById('public-Confirmation-Buttons');
                publicConfirmationButtons.style.flexDirection = 'column'
              }


              setLatLong(markerVar.getLngLat());
              modal_Container2 = document.getElementById('modal-Container2');
              modal_Container2.style.display = 'flex'
              mapCancelMarkers.push(markerVar)
              setMarkerCancelArray(mapCancelMarkers);
              setMouseAppearance(mouseState.NORMAL);
              setTempMarkerStorage(markerVar);
              setAddNoteAllLocation(selectedArea);
              setNoteMouseActive("Deactivate");
              setUpdateNotes("Update");
              let arrayofFault: any;
              arrayofFault = [];

              const response = await fetch(pathLiveFaults) as any;

              const json = await response.json();

              for (let i = 0; i < json.Faults.length; i++) {
                arrayofFault.push(json.Faults[i].reference);
              }

              setfaultArrayForSave(arrayofFault.sort());
              setOutsideOrInsideBoundry("Inside");
              isInsideBoundary = "Inside";
              setNoteLocationSelection(0);


            }
          }
          if (isInsideBoundary != "Inside") {
            setOutsideOrInsideBoundry("Outside");
            setNoteLocationSelection(0);
          }

        })
      } else if (selectedArea == "All") {
        outofbounds = 0;
        MapThing.once('click', async (e: any) => {
          const el = document.createElement('div');
          el.className = 'markerPublicNote';
          let point = turf.point([e.lngLat.lng, e.lngLat.lat]);

          let isInsideBoundary = "Outside";
          for (let i = 0; i < userDistrictIdArray.length; i++) {


            for (let j = 0; j < boundariesArray[i].geometry.coordinates[0].length; j++) {

              var polygon = turf.polygon([boundariesArray[i].geometry.coordinates[0][j][0]]);


              if (turf.booleanPointInPolygon(point, polygon)) {
                let markerVar: any;
                markerVar = new mapboxgl.Marker({
                  element: el,
                  offset: [3, -18],
                  anchor: 'bottom'

                })
                  .setLngLat(e.lngLat)
                  .addTo(MapThing);
                markerVar.getElement().addEventListener('click', () => {
                  setMarkerClickedLocation(markerVar.getLngLat());
                  setNoteMarkerType("User")
                  setNoteToHighlight(markerVar.getLngLat());
                  setmapOrSidebarClick("Map");
                  if (MapThing.getZoom() < boundsZoom) {
                    MapThing.flyTo({
                      center: [markerVar._lngLat.lng, markerVar._lngLat.lat],
                      duration: 1200,
                      essential: true,
                      zoom: boundsZoom
                    });
                  } else {
                    MapThing.flyTo({
                      center: [markerVar._lngLat.lng, markerVar._lngLat.lat],
                      duration: 1200,
                      essential: true,
                    })
                  };
                });
                modal_Postcode = document.getElementById('modal-Postcode');
                modal_Postcode.style.display = "block";

                if (window.innerWidth == 640 || window.innerWidth < 640) {
                  modal_Postcode.style.width = '80%'
                  visibilityButtonPosition = document.getElementById('visibility-button-column');
                  visibilityButtonPosition.style.flexDirection = 'column'
                  postcodeModalHeader = document.getElementById('modal-header-postcode');
                  postcodeModalHeader.style.width = '100%'
                  modal_Postcode = document.getElementById('modal-Postcode');
                  modal_Postcode.style.width = '80%'
                  modalPublicHeader = document.getElementById('modal-Header-Public');
                  modalPublicHeader.style.width = '100%'
                  modalPublic = document.getElementById('modal-Public');
                  modalPublic.style.width = '80%'
                  publicConfirmationButtons = document.getElementById('public-Confirmation-Buttons');
                  publicConfirmationButtons.style.flexDirection = 'column'
                }
                
                setLatLong(markerVar.getLngLat());
                modal_Container2 = document.getElementById('modal-Container2');
                modal_Container2.style.display = 'flex'
                mapCancelMarkers.push(markerVar)
                setMarkerCancelArray(mapCancelMarkers);
                setMouseAppearance(mouseState.NORMAL);
                setTempMarkerStorage(markerVar);
                setAddNoteAllLocation(userDistrictIdArray[i]);
                setReadableSelectedArea(userDistrictNameArray[i])
                setNoteMouseActive("Deactivate");
                setUpdateNotes("Update");
                let arrayofFault: any;
                arrayofFault = [];

                const response = await fetch(pathLiveFaults) as any;

                const json = await response.json();

                for (let k = 0; k < json.Faults.length; k++) {
                  arrayofFault.push(json.Faults[k].reference);
                }

                setfaultArrayForSave(arrayofFault.sort());
                setOutsideOrInsideBoundry("Inside");
                isInsideBoundary = "Inside";
                setNoteLocationSelection(0);
              }
            }
            if (outofbounds == userDistrictIdArray.length) {
              setOutsideOrInsideBoundry("Outside");
              setNoteLocationSelection(0);
            }

          }
          if (isInsideBoundary != "Inside") {
            setOutsideOrInsideBoundry("Outside");
            setNoteLocationSelection(0);
          }
        })

      }
    }
  }, [noteLocationSelection]);



  useEffect(() => {
    if (addMarkerToArray !== 0) {
      let tempMarker = markerArray;
      tempMarker.push(tempMarkerStorage);
      setMarkerArray(tempMarker);
      setAddMarkerToArray(0);
      setTempMarkerStorage([]);
    }
  }, [addMarkerToArray]);



  function handleClick(): void {
    markerCancelArray.forEach((marker: { remove: () => any; }) => marker.remove());
    setMarkerCancelArray(0);
    modal_Postcode = document.getElementById('modal-Postcode');
    modal_Postcode.style.display = "none";
    modal_Container2 = document.getElementById('modal-Container2');
    modal_Container2.style.display = 'none'
    setNoteLocationSelection(0);
    setEnableButtons("Enable");
    setNoteMouseActive("Inactive");
  }

  function saveLocation(notevisibility: string): void {

    if (notevisibility === "Public") {
      modal_Postcode = document.getElementById('modal-Public');
      modal_Postcode.style.display = "block";
      modal_Container2 = document.getElementById('modal-Container3');
      modal_Container2.style.display = 'flex'
      modal_Postcode = document.getElementById('modal-Postcode');
      modal_Postcode.style.display = "none";
      modal_Container2 = document.getElementById('modal-Container2');
      modal_Container2.style.display = 'none'
    } else if (notevisibility === "Public-Confirm") {
      setXCoordConfirmed(latLong.lng);
      setYCoordConfirmed(latLong.lat);
      setNewNoteVisibility("Public");
      setMouseAppearance(mouseState.NORMAL);

      modal_Postcode = document.getElementById('modal-Postcode');
      modal_Postcode.style.display = "none";
      modal_Container2 = document.getElementById('modal-Container2');
      modal_Container2.style.display = 'none'
      modal_Postcode = document.getElementById('modal-Public');
      modal_Postcode.style.display = "none";
      modal_Container2 = document.getElementById('modal-Container3');
      modal_Container2.style.display = 'none'

      setNoteLocationSelection(0);
    } else if (notevisibility === "Public-Denied") {

      modal_Postcode = document.getElementById('modal-Postcode');
      modal_Postcode.style.display = "block";
      modal_Container2 = document.getElementById('modal-Container2');
      modal_Container2.style.display = 'flex'
      modal_Postcode = document.getElementById('modal-Public');
      modal_Postcode.style.display = "none";
      modal_Container2 = document.getElementById('modal-Container3');
      modal_Container2.style.display = 'none'
    } else {
      setXCoordConfirmed(latLong.lng);
      setYCoordConfirmed(latLong.lat);
      setNewNoteVisibility(notevisibility);
      setMouseAppearance(mouseState.NORMAL);

      modal_Postcode = document.getElementById('modal-Postcode');
      modal_Postcode.style.display = "none";
      modal_Container2 = document.getElementById('modal-Container2');
      modal_Container2.style.display = 'none'


      setNoteLocationSelection(0);
    }
  }

  useEffect(() => {
    if (noteCancelled === "Cancelled") {
      markerCancelArray.forEach((marker: { remove: () => any; }) => marker.remove());
      setNoteCancelled("UNSET");
      setNoteLocationSelection(0);
      setMouseAppearance(mouseState.NORMAL);
      setNoteMouseActive("Inactive");
    }
  }, [noteCancelled]);





  return (
    <>
      <div id='modal-Container2' style={{ display: 'none', position: 'fixed', right: '0px', top: '0px', left: '0px', justifyContent: 'center', alignContent: 'center', height: '100%', flexWrap: 'wrap', zIndex: '20', alignItems: 'center' }}>
        <div id="modal-Postcode" className="modal-Postcode" style={{ display: "none" }}>
          <div className="modal_content">
            <div className='note-Input'>
              <IonCard style={{ background: '#214E71', borderRadius: '15px 15px 0 0', width: '740px', right: '10px', top: '-10px' }} id='modal-header-postcode'><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={create} style={{ color: "white" }} />  Confirm Note Location</h1></IonCard>
              <div style={{ fontSize: '20px', margin: '12px' }}>Do you want this note to be public, internal for other partners, or internal for SSEN staff members?</div>
              <div className='note-Layout-Input' style={{ display: 'flex', justifyContent: 'center' }}>
                <div className='note-Button-Placement'>
                  <div style={{ display: "flex", flexDirection: "column" }}>


                    <div id='visibility-button-column' style={{ display: "flex" }}>
                      <IonButton className='note-Button-Green' shape="round" onClick={() => saveLocation("Public")} >Public</IonButton>
                      <IonButton className='note-Button-Green' shape="round" onClick={() => saveLocation("Partners")} >Partners</IonButton>
                      <IonButton className='note-Button-Green' shape="round" onClick={() => saveLocation("SSEN")} >SSEN</IonButton>


                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <IonButton className='note-Button-Red' shape="round" onClick={() => handleClick()}>Cancel</IonButton>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='modal-Container3' style={{ display: 'none', position: 'fixed', right: '0px', top: '0px', left: '0px', justifyContent: 'center', alignContent: 'center', height: '100%', flexWrap: 'wrap', zIndex: '20', alignItems: 'center' }}>
        <div id="modal-Public" className="modal-Postcode" style={{ display: "none" }}>
          <div className="modal_content">
            <div className='note-Input'>
              <IonCard style={{ background: '#214E71', borderRadius: '15px 15px 0 0', width: '740px', right: '10px', top: '-10px' }} id='modal-Header-Public'><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={create} style={{ color: "white" }} />  Confirm Note Location</h1></IonCard>
              <div style={{ fontSize: '20px', margin: '12px' }}>Are you sure you want this note to be public?</div>
              <div className='note-Layout-Input'>
                <div className='note-Button-Placement' id='public-Confirmation-Buttons'>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <IonButton className='note-Button-Red' shape="round" onClick={() => saveLocation("Public-Denied")}>Cancel</IonButton>
                      <IonButton className='note-Button-Green' shape="round" onClick={() => saveLocation("Public-Confirm")}>Confirm</IonButton>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

}



export default AddNoteComponent
import { IonButton, IonCard, IonCardContent, IonCardTitle, IonIcon, IonSelect, IonSelectOption } from "@ionic/react";
import { BASE_URL, districtsPath } from '../mapContentComponent';
import { ACCESS_CREDENTIALS } from "../../../pages/Home";
import './ScenarioMapComponent.css';
import 'mapbox-gl/dist/mapbox-gl.css';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import * as turf from '@turf/turf'
import { useRef, useEffect } from 'react';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import _ from 'lodash';

let getScenarioDataPath = "/gridim/rotaload/partners?action=getScenarioCustomerData&district=";


type ScenarioMapProps = {


    selectedArea: any;



    userDistrictIdArray: any;



    MapThing: any;



    setOverlayArray: any;

    setCurrentBoundaries: any;

    scenarioStage: any;
    scenarioDay: any;
    scenarioTime: any;
    sendScenarioDataFlag: any;
    setLastScenarioTime: any;
    setTimeAndLevelButtons: any;

    setCustomerNumbersScenario: any;
    setpropertyNumbersScenario: any;
    setLastBlocks: any;
    lastBlocks: any;
    lastScenarioTime: any;
    blockColourArray: any;
    setSendScenarioDataFlag: any;

    setpropertyNumbers: any;
    setCustomerNumbers: any;

    clearScenario: any;
    setClearScenario: any;

}


const ScenarioMapComponent: React.FC<ScenarioMapProps> = ({


    selectedArea,


    userDistrictIdArray,



    MapThing,


    setOverlayArray,

    setCurrentBoundaries,

    scenarioStage,
    scenarioDay,
    scenarioTime,
    sendScenarioDataFlag,
    setLastScenarioTime,
    setTimeAndLevelButtons,

    setCustomerNumbersScenario,
    setpropertyNumbersScenario,
    setLastBlocks,
    lastBlocks,
    lastScenarioTime,
    blockColourArray,

    setSendScenarioDataFlag,

    setpropertyNumbers,
    setCustomerNumbers,
    clearScenario,
    setClearScenario
}) => {





    const llb = new mapboxgl.LngLatBounds();


    useEffect(() => {
        if (scenarioStage !== 0 && scenarioDay !== 0 && scenarioTime !== 0 && sendScenarioDataFlag !== 0) {
            let headers = new Headers();
            headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
            let pathForScenario: any;
            pathForScenario = BASE_URL;
            if (selectedArea !== "All") {
                pathForScenario = pathForScenario + getScenarioDataPath + selectedArea + "&stage=" + scenarioStage.replace(/\s/g, '') + "&day=" + scenarioDay.replace(/\s/g, '') + "&time=" + scenarioTime.replace(/\s/g, '');
                setLastScenarioTime(scenarioTime);
                fetch(pathForScenario, { headers: headers })
                    .then(function (response) { return response.json(); })
                    .then(function (json) {
                        let psrCustomers: any;
                        let properties: any;
                        setTimeAndLevelButtons("Disable");

                        if (json.psrCustomers.toString().length < 4) {
                            psrCustomers = json.psrCustomers.toString();
                        } else if (json.psrCustomers.toString().length === 4) {
                            const result = json.psrCustomers.toString().match(/.{1,1}/g) || [];
                            psrCustomers = result[0] + ',' + result[1] + result[2] + result[3];
                        } else if (json.psrCustomers.toString().length === 5) {
                            const result = json.psrCustomers.toString().match(/.{1,2}/g) || [];
                            psrCustomers = result[0] + ',' + result[1] + result[2];
                        } else if (json.psrCustomers.toString().length === 6) {
                            const result = json.psrCustomers.toString().match(/.{1,3}/g) || [];
                            psrCustomers = result[0] + ',' + result[1];
                        } else if (json.psrCustomers.toString().length === 7) {
                            const result = json.psrCustomers.toString().match(/.{1,1}/g) || [];
                            psrCustomers = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
                        }

                        if (json.properties.toString().length < 4) {
                            properties = json.properties.toString();
                        } else if (json.properties.toString().length === 4) {
                            const result = json.properties.toString().match(/.{1,1}/g) || [];
                            properties = result[0] + ',' + result[1] + result[2] + result[3];
                        } else if (json.properties.toString().length === 5) {
                            const result = json.properties.toString().match(/.{1,2}/g) || [];
                            properties = result[0] + ',' + result[1] + result[2];
                        } else if (json.properties.toString().length === 6) {
                            const result = json.properties.toString().match(/.{1,3}/g) || [];
                            properties = result[0] + ',' + result[1];
                        } else if (json.properties.toString().length === 7) {
                            const result = json.properties.toString().match(/.{1,1}/g) || [];
                            properties = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
                        }

                        setCustomerNumbersScenario(psrCustomers);
                        setpropertyNumbersScenario(properties);


                        MapThing.on('render', renderCheck);


                        let networks = ["ssen-scotland", "ssen-southern"];
                        let dnoTest: any;
                        for (let i = 0; i < networks.length; i++) {
                            dnoTest = networks[i];
                            MapThing.removeLayer(dnoTest + '-vector-tiles');
                            MapThing.removeSource(dnoTest + '-tiles');
                        }

                        let blocksArray = []

                        let colours: any = {};
                        let stops: Array<Array<string>> = [];
                        let colorProps = { property: 'block', type: "categorical", stops: stops };

                        let blockSet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'Z'];

                        for (let i = 0; i < json.blocks.length; i++) {
                            let index = blockSet.indexOf(json.blocks[i])
                            blocksArray.push(json.blocks[i])
                            blockSet.splice(index, 1);
                        }
                        setLastBlocks(blocksArray)

                        // let networks = ["murphypowerdistribution", "ssen-scotland","enw"];
                        let blocks = blockSet.length;

                        for (let i = 0; i < blocks; i++) {
                            let block: string = blockSet[i];
                            if (lastBlocks.length > 0) {
                                if (lastScenarioTime !== scenarioTime) {
                                    for (let j = 0; j < lastBlocks.length; j++) {
                                        if (blockSet[i] === lastBlocks[j]) {
                                            colours[block] = "hsl(0,0%,50%)";
                                            j = lastBlocks.length;

                                        } else {
                                            colours[block] = blockColourArray[block];
                                        }
                                    }
                                } else {
                                    colours[block] = blockColourArray[block];
                                }
                            } else {
                                colours[block] = blockColourArray[block];
                            }
                            stops.push([block, colours[block]]);
                        }
                        // Resize map to fit window

                        var dno: string;

                        for (let i = 0; i < networks.length; i++) {
                            dno = networks[i];

                            const loc = window.location;
                            let path;
                            if (loc.hostname == "localhost" || loc.hostname == "127.0.0.1") {
                                // This needs to explicitly point to S3
                                path = "https://ssen-myrotablock-test.opcld.com";
                            } else {
                                // Going to assume that it's always on default port for http/https as it should be in production
                                path = loc.protocol + "//" + loc.hostname;
                            }
                            path += "/partners/tiles/vector/";
                            MapThing.addSource(dno + '-tiles', {
                                'type': 'vector',
                                'tiles': [
                                    path + dno + '/{z}/{x}/{y}.pbf'
                                ],
                                'minzoom': 4,
                                'maxzoom': 12
                            });

                            let areaPaint: any = {
                                'fill-color': colorProps,
                                'fill-opacity': [
                                    'interpolate',
                                    // Set the exponential rate of change to 0.5
                                    ['exponential', 0.5],
                                    ['zoom'],
                                    // When zoom is 10, buildings will be 100% transparent.
                                    9,
                                    0.95,
                                    // When zoom is 18 or higher, buildings will be 100% opaque.
                                    17,
                                    0.25
                                ]
                            };

                            MapThing.addLayer(
                                {
                                    'id': dno + '-vector-tiles', // Layer ID
                                    'type': 'fill',
                                    'source': dno + '-tiles', // ID of the tile source created above
                                    // Source has several layers. We visualize the one with name 'sequence'.
                                    'source-layer': 'rld-block',
                                    'layout': {},
                                    'paint': areaPaint
                                }
                            );
                        }

                        if (selectedArea === "All") {
                            for (let i = 0; i < userDistrictIdArray.length; i++) {
                                MapThing.removeLayer('Layer-' + i);
                                MapThing.removeLayer('outline' + i);
                                MapThing.removeSource(userDistrictIdArray[i] + '-ID');

                            }
                        } else {
                            MapThing.removeLayer('Layer-' + selectedArea);
                            MapThing.removeLayer('outline');
                            MapThing.removeSource(selectedArea + '-ID');
                        }


                        let path2: any;

                        path2 = BASE_URL;

                        path2 += districtsPath + selectedArea;
                        MapThing.addSource(selectedArea + '-ID', {
                            'type': 'geojson',
                            'data': path2
                        });

                        let areaPaint: any = {
                            'fill-color': 'black',
                            'fill-opacity': [
                                'interpolate',
                                ['exponential', 0.5],
                                ['zoom'],
                                7,
                                0.25,
                                17,
                                0.25
                            ]
                        };

                        MapThing.addLayer(
                            {
                                'id': 'Layer-' + selectedArea,
                                'type': 'fill',
                                'source': selectedArea + '-ID',
                                'layout': {},
                                'paint': areaPaint
                            }
                        );

                        MapThing.addLayer({
                            'id': 'outline',
                            'type': 'line',
                            'source': selectedArea + '-ID',
                            'layout': {},
                            'paint': {
                                'line-color': 'white',
                                'line-width': 2
                            }
                        });
                        MapThing.on('render', afterChangeComplete); // warning: this fires many times per second!
                        let headers = new Headers();
                        headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
                        let tempBoundariesArray: any;
                        tempBoundariesArray = [];

                        fetch(path2, { headers: headers })
                            .then(function (response) { return response.json(); })
                            .then(function (json) {
                                const coordinates = json.features[0].geometry.coordinates;
                                setOverlayArray(coordinates);

                                let featureType = json.features[0].geometry.type.toLowerCase();

                                if (featureType == "polygon") {

                                    var polygon = turf.polygon([coordinates[0]]);

                                    setCurrentBoundaries(polygon);
                                } else if (featureType == "multipolygon") {
                                    for (let i = 0; i < coordinates.length; i++) {
                                        for (let j = 0; j < coordinates[i][0].length; j++) {
                                            llb.extend(coordinates[i][0][j]);
                                        }
                                    }
                                    MapThing.fitBounds(llb);
                                    var multipolygon = turf.multiPolygon([coordinates]);
                                    setCurrentBoundaries(multipolygon);
                                }
                            })
                    })

            } else {
                let customerNoAll: any;
                customerNoAll = 0;
                let propertyNoAll: any;
                propertyNoAll = 0;
                let fetchesComplete = 0
                let psrCustomers: any;
                let properties: any;
                for (let i = 0; i < userDistrictIdArray.length; i++) {
                    pathForScenario = BASE_URL + getScenarioDataPath + userDistrictIdArray[i] + "&stage=" + scenarioStage.replace(/\s/g, '') + "&day=" + scenarioDay.replace(/\s/g, '') + "&time=" + scenarioTime.replace(/\s/g, '');
                    setLastScenarioTime(scenarioTime);
                    fetch(pathForScenario, { headers: headers })
                        .then(function (response) { return response.json(); })
                        .then(function (json) {
                            customerNoAll = customerNoAll + json.psrCustomers;
                            propertyNoAll = propertyNoAll + json.properties;
                            setTimeAndLevelButtons("Disable");


                            fetchesComplete++;
                            if (fetchesComplete == (userDistrictIdArray.length)) {

                                if (customerNoAll.toString().length < 4) {
                                    psrCustomers = customerNoAll.toString();
                                } else if (customerNoAll.toString().length === 4) {
                                    const result = customerNoAll.toString().match(/.{1,1}/g) || [];
                                    psrCustomers = result[0] + ',' + result[1] + result[2] + result[3];
                                } else if (customerNoAll.toString().length === 5) {
                                    const result = customerNoAll.toString().match(/.{1,2}/g) || [];
                                    psrCustomers = result[0] + ',' + result[1] + result[2];
                                } else if (customerNoAll.toString().length === 6) {
                                    const result = customerNoAll.toString().match(/.{1,3}/g) || [];
                                    psrCustomers = result[0] + ',' + result[1];
                                } else if (customerNoAll.toString().length === 7) {
                                    const result = customerNoAll.toString().match(/.{1,1}/g) || [];
                                    psrCustomers = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
                                }

                                if (propertyNoAll.toString().length < 4) {
                                    properties = propertyNoAll.toString();
                                } else if (propertyNoAll.toString().length === 4) {
                                    const result = propertyNoAll.toString().match(/.{1,1}/g) || [];
                                    properties = result[0] + ',' + result[1] + result[2] + result[3];
                                } else if (propertyNoAll.toString().length === 5) {
                                    const result = propertyNoAll.toString().match(/.{1,2}/g) || [];
                                    properties = result[0] + ',' + result[1] + result[2];
                                } else if (propertyNoAll.toString().length === 6) {
                                    const result = propertyNoAll.toString().match(/.{1,3}/g) || [];
                                    properties = result[0] + ',' + result[1];
                                } else if (propertyNoAll.toString().length === 7) {
                                    const result = propertyNoAll.toString().match(/.{1,1}/g) || [];
                                    properties = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
                                }
                                setCustomerNumbersScenario(psrCustomers);
                                setpropertyNumbersScenario(properties);

                                MapThing.on('render', renderCheck);



                                let networks = ["ssen-scotland", "ssen-southern"];
                                let dnoTest: any;
                                for (let i = 0; i < networks.length; i++) {
                                    dnoTest = networks[i];
                                    MapThing.removeLayer(dnoTest + '-vector-tiles');
                                    MapThing.removeSource(dnoTest + '-tiles');
                                }


                                let blocksArray = []
                                let colours: any = {};

                                let stops: Array<Array<string>> = [];

                                let colorProps = { property: 'block', type: "categorical", stops: stops };

                                let blockSet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'Z'];

                                for (let i = 0; i < json.blocks.length; i++) {
                                    let index = blockSet.indexOf(json.blocks[i])
                                    blocksArray.push(json.blocks[i])
                                    blockSet.splice(index, 1);
                                }
                                setLastBlocks(blocksArray);


                                // let networks = ["murphypowerdistribution", "ssen-scotland","enw"];

                                let blocks = blockSet.length;

                                for (let i = 0; i < blocks; i++) {
                                    let block: string = blockSet[i];
                                    if (lastBlocks.length > 0) {
                                        if (lastScenarioTime !== scenarioTime) {
                                            for (let j = 0; j < lastBlocks.length; j++) {
                                                if (blockSet[i] === lastBlocks[j]) {
                                                    colours[block] = "hsl(0,0%,50%)";
                                                    j = lastBlocks.length;
                                                } else {
                                                    colours[block] = blockColourArray[block];
                                                }
                                            }
                                        } else {
                                            colours[block] = blockColourArray[block];
                                        }
                                    } else {
                                        colours[block] = blockColourArray[block];
                                    }
                                    stops.push([block, colours[block]]);

                                }
                                // Resize map to fit window


                                var dno: string;

                                for (let i = 0; i < networks.length; i++) {
                                    dno = networks[i];

                                    const loc = window.location;
                                    let path;
                                    if (loc.hostname == "localhost" || loc.hostname == "127.0.0.1") {
                                        // This needs to explicitly point to S3
                                        path = "https://ssen-myrotablock-test.opcld.com";
                                    } else {
                                        // Going to assume that it's always on default port for http/https as it should be in production
                                        path = loc.protocol + "//" + loc.hostname;
                                    }
                                    path += "/partners/tiles/vector/";
                                    MapThing.addSource(dno + '-tiles', {
                                        'type': 'vector',
                                        'tiles': [
                                            path + dno + '/{z}/{x}/{y}.pbf'
                                        ],
                                        'minzoom': 4,
                                        'maxzoom': 12
                                    });

                                    let areaPaint: any = {
                                        'fill-color': colorProps,
                                        'fill-opacity': [
                                            'interpolate',
                                            // Set the exponential rate of change to 0.5
                                            ['exponential', 0.5],
                                            ['zoom'],
                                            // When zoom is 10, buildings will be 100% transparent.
                                            9,
                                            0.95,
                                            // When zoom is 18 or higher, buildings will be 100% opaque.
                                            17,
                                            0.25
                                        ]
                                    };

                                    MapThing.addLayer(
                                        {
                                            'id': dno + '-vector-tiles', // Layer ID
                                            'type': 'fill',
                                            'source': dno + '-tiles', // ID of the tile source created above
                                            // Source has several layers. We visualize the one with name 'sequence'.
                                            'source-layer': 'rld-block',
                                            'layout': {},
                                            'paint': areaPaint
                                        }
                                    );
                                }


                                for (let i = 0; i < userDistrictIdArray.length; i++) {
                                    MapThing.removeLayer('Layer-' + i);
                                    MapThing.removeLayer('outline' + i);
                                    MapThing.removeSource(userDistrictIdArray[i] + '-ID');

                                }


                                let path2: any;

                                path2 = BASE_URL;

                                for (let i = 0; i < userDistrictIdArray.length; i++) {

                                    MapThing.addSource(userDistrictIdArray[i] + '-ID', {
                                        'type': 'geojson',
                                        'data': BASE_URL + districtsPath + userDistrictIdArray[i]
                                    });

                                    let areaPaint: any = {
                                        'fill-color': 'black',
                                        'fill-opacity': [
                                            'interpolate',
                                            ['exponential', 0.5],
                                            ['zoom'],
                                            7,
                                            0.25,
                                            17,
                                            0.25
                                        ]
                                    };

                                    MapThing.addLayer(
                                        {
                                            'id': 'Layer-' + i,
                                            'type': 'fill',
                                            'source': userDistrictIdArray[i] + '-ID',
                                            'layout': {},
                                            'paint': areaPaint
                                        }
                                    );

                                    MapThing.addLayer({
                                        'id': 'outline' + i,
                                        'type': 'line',
                                        'source': userDistrictIdArray[i] + '-ID',
                                        'layout': {},
                                        'paint': {
                                            'line-color': 'white',
                                            'line-width': 2
                                        }
                                    });


                                }


                                MapThing.on('render', afterChangeComplete); // warning: this fires many times per second!
                                let headers = new Headers();
                                headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
                                let tempBoundariesArray: any;
                                tempBoundariesArray = [];


                                const fetchData = async () => {
                                    for (let i = 0; i < userDistrictIdArray.length; i++) {
                                        let pathTest: any;
                                        pathTest = BASE_URL + districtsPath + userDistrictIdArray[i];
                                        const response = await fetch(pathTest, { headers: headers }) as any;
                                        const json = await response.json();
                                        const coordinates = json.features[0].geometry.coordinates;
                                        let featureType = json.features[0].geometry.type.toLowerCase();

                                        if (featureType == "polygon") {


                                            var polygon = turf.polygon([coordinates[0]]);

                                            tempBoundariesArray.push(polygon);

                                        } else if (featureType == "multipolygon") {
                                            for (let i = 0; i < coordinates.length; i++) {
                                                for (let j = 0; j < coordinates[i][0].length; j++) {
                                                    llb.extend(coordinates[i][0][j]);
                                                }
                                            }
                        
                                            var multipolygon = turf.multiPolygon([coordinates]);
                                            tempBoundariesArray.push(multipolygon);
                                        }
                                    }
                                    MapThing.fitBounds(llb);

                                }

                                fetchData();


                            }
                        })

                }

            }
            setSendScenarioDataFlag(0);
        }
    }, [sendScenarioDataFlag]);


    function afterChangeComplete() {
        if (MapThing.loaded()) { return } // still not loaded; bail out.

        let headers = new Headers();
        headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
        let path2: any;
        path2 = BASE_URL;
        if (selectedArea !== "All") {
            path2 = path2 + districtsPath + selectedArea;
            fetch(path2, { headers: headers })
                .then(function (response) { return response.json(); })
                .then(function (json) {

                    let psrCustomers: any;
                    let properties: any;


                    if (json.features[0].properties.psrCustomers.toString().length < 4) {
                        psrCustomers = json.features[0].properties.psrCustomers.toString();
                    } else if (json.features[0].properties.psrCustomers.toString().length === 4) {
                        const result = json.features[0].properties.psrCustomers.toString().match(/.{1,1}/g) || [];
                        psrCustomers = result[0] + ',' + result[1] + result[2] + result[3];
                    } else if (json.features[0].properties.psrCustomers.toString().length === 5) {
                        const result = json.features[0].properties.psrCustomers.toString().match(/.{1,2}/g) || [];
                        psrCustomers = result[0] + ',' + result[1] + result[2];
                    } else if (json.features[0].properties.psrCustomers.toString().length === 6) {
                        const result = json.features[0].properties.psrCustomers.toString().match(/.{1,3}/g) || [];
                        psrCustomers = result[0] + ',' + result[1];
                    } else if (json.features[0].properties.psrCustomers.toString().length === 7) {
                        const result = json.features[0].properties.psrCustomers.toString().match(/.{1,1}/g) || [];
                        psrCustomers = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
                    }

                    if (json.features[0].properties.properties.toString().length < 4) {
                        properties = json.features[0].properties.properties.toString();
                    } else if (json.features[0].properties.properties.toString().length === 4) {
                        const result = json.features[0].properties.properties.toString().match(/.{1,1}/g) || [];
                        properties = result[0] + ',' + result[1] + result[2] + result[3];
                    } else if (json.features[0].properties.properties.toString().length === 5) {
                        const result = json.features[0].properties.properties.toString().match(/.{1,2}/g) || [];
                        properties = result[0] + ',' + result[1] + result[2];
                    } else if (json.features[0].properties.properties.toString().length === 6) {
                        const result = json.features[0].properties.properties.toString().match(/.{1,3}/g) || [];
                        properties = result[0] + ',' + result[1];
                    } else if (json.features[0].properties.properties.toString().length === 7) {
                        const result = json.features[0].properties.properties.toString().match(/.{1,1}/g) || [];
                        properties = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
                    }

                    setpropertyNumbers(properties);
                    setCustomerNumbers(psrCustomers);

                })
        } else {
            let customerNoAll: any;
            customerNoAll = 0;
            let propertyNoAll: any;
            propertyNoAll = 0;
            let fetchesComplete = 0;
            for (let i = 0; i < userDistrictIdArray.length; i++) {
                let pathTest: any;
                pathTest = BASE_URL + districtsPath + userDistrictIdArray[i];
                fetch(pathTest, { headers: headers })
                    .then(function (response) { return response.json(); })
                    .then(function (json) {
                        customerNoAll = customerNoAll + json.features[0].properties.psrCustomers;
                        propertyNoAll = propertyNoAll + json.features[0].properties.properties;
                        fetchesComplete++;
                        if (fetchesComplete == (userDistrictIdArray.length)) {

                            let psrCustomers: any;
                            let properties: any;


                            if (customerNoAll.toString().length < 4) {
                                psrCustomers = customerNoAll.toString();
                            } else if (customerNoAll.toString().length === 4) {
                                const result = customerNoAll.toString().match(/.{1,1}/g) || [];
                                psrCustomers = result[0] + ',' + result[1] + result[2] + result[3];
                            } else if (customerNoAll.toString().length === 5) {
                                const result = customerNoAll.toString().match(/.{1,2}/g) || [];
                                psrCustomers = result[0] + ',' + result[1] + result[2];
                            } else if (customerNoAll.toString().length === 6) {
                                const result = customerNoAll.toString().match(/.{1,3}/g) || [];
                                psrCustomers = result[0] + ',' + result[1];
                            } else if (customerNoAll.toString().length === 7) {
                                const result = customerNoAll.toString().match(/.{1,1}/g) || [];
                                psrCustomers = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
                            }

                            if (propertyNoAll.toString().length < 4) {
                                properties = propertyNoAll.toString();
                            } else if (propertyNoAll.toString().length === 4) {
                                const result = propertyNoAll.toString().match(/.{1,1}/g) || [];
                                properties = result[0] + ',' + result[1] + result[2] + result[3];
                            } else if (propertyNoAll.toString().length === 5) {
                                const result = propertyNoAll.toString().match(/.{1,2}/g) || [];
                                properties = result[0] + ',' + result[1] + result[2];
                            } else if (propertyNoAll.toString().length === 6) {
                                const result = propertyNoAll.toString().match(/.{1,3}/g) || [];
                                properties = result[0] + ',' + result[1];
                            } else if (propertyNoAll.toString().length === 7) {
                                const result = propertyNoAll.toString().match(/.{1,1}/g) || [];
                                properties = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
                            }

                            setpropertyNumbers(properties);
                            setCustomerNumbers(psrCustomers);
                        }
                    })
            }
        }
        MapThing.off('render', afterChangeComplete); // remove this handler now that we're done.
    }



    function renderCheck() {
        if (!MapThing.loaded()) { return }

        setTimeAndLevelButtons("Enable");
    }

    useEffect(() => {
        if (clearScenario === "Clear") {
            setClearScenario("Disabled")
            let networks = ["ssen-scotland", "ssen-southern"];
            let dnoTest: any;
            for (let i = 0; i < networks.length; i++) {
                dnoTest = networks[i];
                MapThing.removeLayer(dnoTest + '-vector-tiles');
                MapThing.removeSource(dnoTest + '-tiles');
            }
            let colours: any = {};
            let darkColours: any = {};
            let stops: Array<Array<string>> = [];
            let darkStops: Array<Array<string>> = [];
            let colorProps = { property: 'block', type: "categorical", stops: stops };
            let path2: any;
            path2 = BASE_URL;

            let blockSet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'Z'];
            // let networks = ["murphypowerdistribution", "ssen-scotland","enw"];

            let blocks = blockSet.length;
            for (let i = 0; i < blocks; i++) {
                let block: string = blockSet[i];
                let hsl = "hsl(" + (256 * i / blocks).toFixed(0) + ",50%,50%)";
                let dhsl = "hsl(" + (256 * i / blocks).toFixed(0) + ",75%,25%)";
                colours[block] = hsl;
                darkColours[block] = dhsl;
                stops.push([block, hsl]);
                darkStops.push([block, dhsl]);
            }
            // Resize map to fit window


            var dno: string;

            for (let i = 0; i < networks.length; i++) {
                dno = networks[i];

                const loc = window.location;
                let path;
                if (loc.hostname == "localhost" || loc.hostname == "127.0.0.1") {
                    // This needs to explicitly point to S3
                    path = "https://ssen-myrotablock-test.opcld.com";
                } else {
                    // Going to assume that it's always on default port for http/https as it should be in production
                    path = loc.protocol + "//" + loc.hostname;
                }
                path += "/partners/tiles/vector/";
                MapThing.addSource(dno + '-tiles', {
                    'type': 'vector',
                    'tiles': [
                        path + dno + '/{z}/{x}/{y}.pbf'
                    ],
                    'minzoom': 4,
                    'maxzoom': 12
                });

                let areaPaint: any = {
                    'fill-color': colorProps,
                    'fill-opacity': [
                        'interpolate',
                        // Set the exponential rate of change to 0.5
                        ['exponential', 0.5],
                        ['zoom'],
                        // When zoom is 10, buildings will be 100% transparent.
                        9,
                        0.95,
                        // When zoom is 18 or higher, buildings will be 100% opaque.
                        17,
                        0.25
                    ]
                };

                MapThing.addLayer(
                    {
                        'id': dno + '-vector-tiles', // Layer ID
                        'type': 'fill',
                        'source': dno + '-tiles', // ID of the tile source created above
                        // Source has several layers. We visualize the one with name 'sequence'.
                        'source-layer': 'rld-block',
                        'layout': {},
                        'paint': areaPaint
                    }
                );
            }
            if (selectedArea === "All") {
                for (let i = 0; i < userDistrictIdArray.length; i++) {
                    MapThing.removeLayer('Layer-' + i);
                    MapThing.removeLayer('outline' + i);
                    MapThing.removeSource(userDistrictIdArray[i] + '-ID');

                }
            } else {
                MapThing.removeLayer('Layer-' + selectedArea);
                MapThing.removeLayer('outline');
                MapThing.removeSource(selectedArea + '-ID');
            }
            if (selectedArea == 'All') {
                for (let i = 0; i < userDistrictIdArray.length; i++) {

                    MapThing.addSource(userDistrictIdArray[i] + '-ID', {
                        'type': 'geojson',
                        'data': BASE_URL + districtsPath + userDistrictIdArray[i]
                    });

                    let areaPaint: any = {
                        'fill-color': 'black',
                        'fill-opacity': [
                            'interpolate',
                            ['exponential', 0.5],
                            ['zoom'],
                            7,
                            0.25,
                            17,
                            0.25
                        ]
                    };

                    MapThing.addLayer(
                        {
                            'id': 'Layer-' + i,
                            'type': 'fill',
                            'source': userDistrictIdArray[i] + '-ID',
                            'layout': {},
                            'paint': areaPaint
                        }
                    );

                    MapThing.addLayer({
                        'id': 'outline' + i,
                        'type': 'line',
                        'source': userDistrictIdArray[i] + '-ID',
                        'layout': {},
                        'paint': {
                            'line-color': 'white',
                            'line-width': 2
                        }
                    });


                }
            } else {
                path2 += districtsPath + selectedArea;
                MapThing.addSource(selectedArea + '-ID', {
                    'type': 'geojson',
                    'data': path2
                });

                let areaPaint: any = {
                    'fill-color': 'black',
                    'fill-opacity': [
                        'interpolate',
                        ['exponential', 0.5],
                        ['zoom'],
                        7,
                        0.25,
                        17,
                        0.25
                    ]
                };

                MapThing.addLayer(
                    {
                        'id': 'Layer-' + selectedArea,
                        'type': 'fill',
                        'source': selectedArea + '-ID',
                        'layout': {},
                        'paint': areaPaint
                    }
                );

                MapThing.addLayer({
                    'id': 'outline',
                    'type': 'line',
                    'source': selectedArea + '-ID',
                    'layout': {},
                    'paint': {
                        'line-color': 'white',
                        'line-width': 2
                    }
                });
            }
            MapThing.on('render', afterChangeComplete); // warning: this fires many times per second!
            let headers = new Headers();
            headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
            let tempBoundariesArray: any;
            tempBoundariesArray = [];
            if (selectedArea !== "All") {
                fetch(path2, { headers: headers })
                    .then(function (response) { return response.json(); })
                    .then(function (json) {
                        const coordinates = json.features[0].geometry.coordinates;
                        let featureType = json.features[0].geometry.type.toLowerCase();

                        if (featureType == "polygon") {



                            var polygon = turf.polygon([coordinates[0]]);

                            setCurrentBoundaries(polygon);
                        } else if (featureType == "multipolygon") {

                            for (let i = 0; i < coordinates.length; i++) {
                                for (let j = 0; j < coordinates[i][0].length; j++) {
                                    llb.extend(coordinates[i][0][j]);
                                }
                            }
                            MapThing.fitBounds(llb);
                            var multipolygon = turf.multiPolygon([coordinates]);
                            setCurrentBoundaries(multipolygon);
                        };
                    })
            } else {

                const fetchData = async () => {
                    for (let i = 0; i < userDistrictIdArray.length; i++) {
                        let pathTest: any;
                        pathTest = BASE_URL + districtsPath + userDistrictIdArray[i];
                        const response = await fetch(pathTest, { headers: headers }) as any;
                        const json = await response.json();
                        const coordinates = json.features[0].geometry.coordinates;
                        let featureType = json.features[0].geometry.type.toLowerCase();

                        if (featureType == "polygon") {


                            var polygon = turf.polygon([coordinates[0]]);

                            tempBoundariesArray.push(polygon);

                        } else if (featureType == "multipolygon") {
                            for (let i = 0; i < coordinates.length; i++) {
                                for (let j = 0; j < coordinates[i][0].length; j++) {
                                    llb.extend(coordinates[i][0][j]);
                                }
                            }
                            
                            var multipolygon = turf.multiPolygon([coordinates]);
                            tempBoundariesArray.push(multipolygon);
                        }
                    }
                    MapThing.fitBounds(llb);
                }

                fetchData();
            }
        }
    }, [clearScenario]);

    return (
        <>

        </>
    );

}



export default ScenarioMapComponent
import { IonCardContent, IonCardTitle, IonSelect, IonSelectOption } from "@ionic/react";
import { useEffect } from "react";
import { BASE_URL } from '../../mapContentComponent/mapContentComponent';
import { ACCESS_CREDENTIALS } from "../../../pages/Home";
import './LRDComponentStyle.css';


type LRDProps = {
    setResiliencePartner: any;


    propertyNumbers: any;
    customerNumbers: any

    userDistrictIdArray: any;
    setUserDistrictIdArray: any;


    userDistrictNameArray: any;
    setUserDistrictNameArray: any;
    setSelectedArea: any;
    selectedArea: any;
    setLastSelectedArea: any;
    noteToggle: any;

    setUpdateNotes: any;
    setTypeOfNoteAction: any;
    sidebarTimer: any;
    setSidebarTimer: any;
    setUpdateTimerNote: any;
    setReadableSelectedArea: any;

}


const LRDPicker: React.FC<LRDProps> = ({

   setResiliencePartner,
    setUserDistrictIdArray, setUserDistrictNameArray, userDistrictIdArray, userDistrictNameArray, propertyNumbers, customerNumbers, setSelectedArea, selectedArea, setLastSelectedArea, noteToggle, setUpdateNotes, setTypeOfNoteAction, sidebarTimer, setSidebarTimer, setUpdateTimerNote,   setReadableSelectedArea

}) => {

    let data_Cards_Style_Attempt: any;

   

    useEffect(() => {

        let pathLogin: any;
        let headersForLogin = new Headers();
        let tempDistrictIDArray: any;
        let tempDistrictNameArray: any;

        tempDistrictIDArray = [];
        tempDistrictNameArray = [];
        pathLogin = BASE_URL + "/gridim/rotaload/partners?action=getUserAccessDetails";


        headersForLogin.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);

        fetch(pathLogin, { headers: headersForLogin })
            .then(function (response) { return response.json(); })
            .then(async function (userDetailsJson) {


                setResiliencePartner(userDetailsJson.organisation);
                for (let i = 0; i < userDetailsJson.districts.length; i++) {
                    tempDistrictIDArray.push(userDetailsJson.districts[i].id);
                    tempDistrictNameArray.push(userDetailsJson.districts[i].name);
                }
                setUserDistrictIdArray(tempDistrictIDArray.sort());
                setUserDistrictNameArray(tempDistrictNameArray.sort());
            })


    }, []);

    
  useEffect(() => {
    if (userDistrictNameArray != 0) {
      if (userDistrictNameArray.length > 1) {
        let dropdownTest: any;
        dropdownTest = document.getElementById('dropdown-Card-Style');
        dropdownTest.style.display = 'block';
        data_Cards_Style_Attempt = document.getElementById('data-Cards-Style-Attempt');
        data_Cards_Style_Attempt.style.display = 'flex';
        let dropdownText: any;
        dropdownText = document.getElementById('ion-select-ID');
        dropdownText.value = 'All';
        setSelectedArea('All');
      } else {
        setSelectedArea(userDistrictIdArray[0]);
        data_Cards_Style_Attempt = document.getElementById('data-Cards-Style-Attempt');
        data_Cards_Style_Attempt.style.display = 'flex';
      }
    }
  }, [userDistrictNameArray])

  useEffect(() => { // useEffect that runs when selectedArea changes, used to be a lot more complex but was cut down when setupdatenotes and toggledmode was added. Simply sets last values to detect when slectedarea and toggle modes changes. Also updates notes + type of action. 
    if (selectedArea != 0) {
      setLastSelectedArea(selectedArea);
      if (noteToggle === "ON") {
        setUpdateNotes("Update");
        setTypeOfNoteAction("Show Notes");
      }
      userDistrictIdArray.map(function (districts: any, index: any) {
        if (districts == selectedArea) {
          setReadableSelectedArea(userDistrictNameArray[index])
        }
      })
    } 

  },
    [selectedArea]
  )




    return (
        <>
            <div className="dropdown-Card-Style" id='dropdown-Card-Style' style={{ display: 'none' }}>
                <IonSelect id="ion-select-ID" onIonChange={(e: any) => setSelectedArea(e.detail.value)}  interface="popover" placeholder="Select Local Resilience District">
                    <IonSelectOption value="All">All</IonSelectOption>
                    {
                        userDistrictIdArray.map(function (districts: any, index: any) {
                            return <IonSelectOption value={districts}>{userDistrictNameArray[index]}</IonSelectOption>
                        })
                    }
                </IonSelect>
            </div>

             <div className="data-Cards-Style-Attempt" id="data-Cards-Style-Attempt" style={{ display: 'none' }}>
                <div className="data-Card-Style">
                    <IonCardTitle class="explanation-Card-Title">Number of Properties</IonCardTitle>
                    <IonCardContent class="block-Letter-Text">
                        {propertyNumbers}
                    </IonCardContent>
                </div>
                <div className="data-Card-Style">
                    <IonCardTitle class="explanation-Card-Title">Priority Customers</IonCardTitle>
                    <IonCardContent class="block-Letter-Text">
                        {customerNumbers}
                    </IonCardContent>
                </div>
            </div> 
        </>
    );

}



export default LRDPicker
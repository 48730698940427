import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCheckbox, IonDatetime, IonDatetimeButton, IonIcon, IonLabel, IonModal, IonSelect, IonSelectOption, IonTextarea } from "@ionic/react";
import { useEffect } from "react";
import { BASE_URL } from '../../../mapContentComponent/mapContentComponent';
import { ACCESS_CREDENTIALS } from "../../../../pages/Home";
import './DeleteNoteComponent.css';
import { create } from "ionicons/icons";
import { SidebarState, modalState } from "../../../../enums/enums";



type DeleteNoteProps = {
    setUpdateNotes: any;

    setSidebarExpanded: any;

    setModalVisibility: any;
    setTypeOfNoteAction: any;
    resiliencePartner: any;
    publicUserNotes: any;
    partnersUserNotes: any;
    SSENUserNotes: any;
    publicNonUserNotes: any;
    partnersNonUserNotes: any;
    SSENNonUserNotes: any;
    organisationArray: any;

    setDeleteNoteReference: any;
    setDeleteDateReference: any;


    setDeleteDistrictReference: any;
    setDeleteIDReference: any;
    setDeleteLocationReferenceX: any;
    setDeleteLocationReferenceY: any;

    deleteIDReference: any;

    deleteDistrictReference: any;
    deleteLocationReferenceX: any;
    deleteLocationReferenceY: any;
    deleteDateReference: any;

    setDeleteButtonNumber: any;
    setDeleteMarker: any;

    deleteButtonNumber: any;

    deletePublicNoteButtonClick: any;
    setDeletePublicNoteButtonClick: any;

    deletePartnerNoteButtonClick: any;
    setDeletePartnerNoteButtonClick: any;

    deleteSSENNoteButtonClick: any;
    setDeleteSSENNoteButtonClick: any;

    readableSelectedArea: any;
    setReadableSelectedArea: any;
    userDistrictIdArray: any;
    userDistrictNameArray: any;

    extendedNotesArray: any;
    setExtendedNotesArray: any;

}


const DeleteNoteComponent: React.FC<DeleteNoteProps> = ({

    setUpdateNotes, setModalVisibility,
    setTypeOfNoteAction, resiliencePartner,
    publicUserNotes, partnersUserNotes,
    SSENUserNotes, publicNonUserNotes,
    partnersNonUserNotes, SSENNonUserNotes,
    organisationArray,
    setDeleteNoteReference, setDeleteDateReference,
    setDeleteDistrictReference, setDeleteIDReference,
    setDeleteLocationReferenceX, setDeleteLocationReferenceY,
    deleteIDReference, deleteDistrictReference,
    deleteLocationReferenceX, deleteLocationReferenceY,
    deleteDateReference, setDeleteButtonNumber,
    setDeleteMarker, deleteButtonNumber,

    deletePublicNoteButtonClick,
    setDeletePublicNoteButtonClick,

    deletePartnerNoteButtonClick,
    setDeletePartnerNoteButtonClick,

    deleteSSENNoteButtonClick,
    setDeleteSSENNoteButtonClick,
    readableSelectedArea,
    setReadableSelectedArea,
    userDistrictIdArray,
    userDistrictNameArray,
    setSidebarExpanded,

    extendedNotesArray,
    setExtendedNotesArray


}) => {

    let modal_Container: any;

    let modal_Delete: any;

    let buttonShow: any;
    let buttonEdit: any;
    let buttonDelete: any;


    let segmentActivation: any;
    let segmentActivation2: any;
    let segmentActivation3: any;
    let ionSelectActive: any;


    let noteExtendActivate: any;

    let modal_Delete_Line: any;



    useEffect(() => {
        if (deletePublicNoteButtonClick != 0) { // Use effect for note toggle coming on and affecting the sidebar by adding the notes
            deletePublicNote(deletePublicNoteButtonClick);
            setDeletePublicNoteButtonClick(0);
        }
    }, [deletePublicNoteButtonClick])


    useEffect(() => {
        if (deletePartnerNoteButtonClick != 0) { // Use effect for note toggle coming on and affecting the sidebar by adding the notes
            deletePartnerNote(deletePartnerNoteButtonClick);
            setDeletePartnerNoteButtonClick(0);
        }
    }, [deletePartnerNoteButtonClick])


    useEffect(() => {
        if (deleteSSENNoteButtonClick != 0) { // Use effect for note toggle coming on and affecting the sidebar by adding the notes
            deleteSSENNote(deleteSSENNoteButtonClick);
            setDeleteSSENNoteButtonClick(0);
        }
    }, [deleteSSENNoteButtonClick])


    function disableButtons() { //Disables all of the buttons on the sidebar, if we split up into smaller components this will need split up
        publicUserNotes.map((function (publicUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + publicUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + publicUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + publicUserNotes.index);
            buttonShow.disabled = true;
            buttonEdit.disabled = true;
            buttonDelete.disabled = true;
        }))

        partnersUserNotes.map((function (partnersUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + partnersUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + partnersUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + partnersUserNotes.index);
            buttonShow.disabled = true;
            buttonEdit.disabled = true;
            buttonDelete.disabled = true;
        }))

        SSENUserNotes.map((function (SSENUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + SSENUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + SSENUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + SSENUserNotes.index);
            buttonShow.disabled = true;
            buttonEdit.disabled = true;
            buttonDelete.disabled = true;
        }))



        publicNonUserNotes.map((function (publicNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + publicNonUserNotes.index);
            buttonShow.disabled = true;
        }))


        partnersNonUserNotes.map((function (partnersNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + partnersNonUserNotes.index);
            buttonShow.disabled = true;
        }))


        SSENNonUserNotes.map((function (SSENNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + SSENNonUserNotes.index);
            buttonShow.disabled = true;
        }))



        segmentActivation = document.getElementById('segment-Id-1');
        segmentActivation.disabled = true;

        segmentActivation2 = document.getElementById('segment-Id-2');
        segmentActivation2.disabled = true;

        segmentActivation3 = document.getElementById('segment-Id-3');
        segmentActivation3.disabled = true;


        ionSelectActive = document.getElementById('ion-select-ID');
        ionSelectActive.disabled = true;

        noteExtendActivate = document.getElementById('chevronDownCircle');
        noteExtendActivate.style.pointerEvents = 'none';

        organisationArray.map(function (organisation: any, index: number) {
            index++;
            noteExtendActivate = document.getElementById("chevronDownCircle" + index);
            noteExtendActivate.style.pointerEvents = 'none';
        })
    }



    function enableButtons() { // Enables all of the sidebar buttons for use
        publicUserNotes.map((function (publicUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + publicUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + publicUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + publicUserNotes.index);
            buttonShow.disabled = false;
            buttonEdit.disabled = false;
            buttonDelete.disabled = false;
        }))

        partnersUserNotes.map((function (partnersUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + partnersUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + partnersUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + partnersUserNotes.index);
            buttonShow.disabled = false;
            buttonEdit.disabled = false;
            buttonDelete.disabled = false;
        }))

        SSENUserNotes.map((function (SSENUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + SSENUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + SSENUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + SSENUserNotes.index);
            buttonShow.disabled = false;
            buttonEdit.disabled = false;
            buttonDelete.disabled = false;
        }))



        publicNonUserNotes.map((function (publicNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + publicNonUserNotes.index);
            buttonShow.disabled = false;
        }))


        partnersNonUserNotes.map((function (partnersNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + partnersNonUserNotes.index);
            buttonShow.disabled = false;
        }))


        SSENNonUserNotes.map((function (SSENNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + SSENNonUserNotes.index);
            buttonShow.disabled = false;
        }))


        segmentActivation = document.getElementById('segment-Id-1');
        segmentActivation.disabled = false;
        segmentActivation2 = document.getElementById('segment-Id-2');
        segmentActivation2.disabled = false;
        segmentActivation3 = document.getElementById('segment-Id-3');
        segmentActivation3.disabled = false;

        ionSelectActive = document.getElementById('ion-select-ID');
        ionSelectActive.disabled = false;

        noteExtendActivate = document.getElementById('chevronDownCircle');
        noteExtendActivate.style.pointerEvents = 'all';

        organisationArray.map(function (organisation: any, index: number) {
            index++;
            noteExtendActivate = document.getElementById("chevronDownCircle" + index);
            noteExtendActivate.style.pointerEvents = 'all';
        })
    }

    let noteMinimise: any;
    let addNoteStyleWidth: any;

    function deletePublicNote(deleteNumber: any) { // Function to display delete modal to see what data the note contains before getting rid of it, called by pressing the button on user organisation note.
        modal_Delete = document.getElementById('modal-Delete');
        modal_Delete_Line = document.getElementById('note-Delete-Line');
        modal_Container = document.getElementById('modal-Container-Delete');



        if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
            noteMinimise = document.getElementById('note-Test-Perm-Fix');
            noteMinimise.style.height = "72px";
        }

        if (window.innerWidth == 640 || window.innerWidth < 640) {
            addNoteStyleWidth = document.getElementById('modal-header-container-delete');
            addNoteStyleWidth.style.width = '100%'

        }


        setUpdateNotes("Update");
        setDeleteButtonNumber(deleteNumber);
        if (modal_Delete.style.display == "none") {
            modal_Delete.style.display = "flex";
            modal_Container.style.display = 'flex';

            setModalVisibility(modalState.SHOWN);
            disableButtons();

            // Runs through notes array to link note user wants to delete with data, sets the data in the note for easy deletion and viewing. 

            publicUserNotes.map((function (notesArrayDelete: any) {
                if (notesArrayDelete.index == deleteNumber) {
                    setDeleteNoteReference(notesArrayDelete.note_Content);
                    modal_Delete_Line.value = notesArrayDelete.note_Content;
                    setDeleteDateReference(notesArrayDelete.note_Creation);
                    const myArray = notesArrayDelete.note_Location.split(",");
                    setDeleteDistrictReference(notesArrayDelete.note_District);
                    userDistrictIdArray.map(function (districts: any, index: any) {
                        if (districts == notesArrayDelete.note_District) {
                            setReadableSelectedArea(userDistrictNameArray[index])
                        }
                    })
                    setDeleteIDReference(notesArrayDelete.note_ID)
                    setDeleteLocationReferenceX(myArray[0]);
                    setDeleteLocationReferenceY(myArray[1].trim())
                }
            }))
        } else if (modal_Delete.style.display = "block") {
            modal_Delete.style.display = "none";
            modal_Container.style.display = 'none';
            setModalVisibility(modalState.HIDDEN);
            enableButtons();

            if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                extendedNotesArray.map(function (noteExtender: any) {
                    noteMinimise = document.getElementById(noteExtender);
                    noteMinimise.style.height = "auto";
                })
    
            }
        }
    }

    function deletePartnerNote(deleteNumber: any) { // Function to display delete modal to see what data the note contains before getting rid of it, called by pressing the button on user organisation note.
        modal_Delete = document.getElementById('modal-Delete');
        modal_Delete_Line = document.getElementById('note-Delete-Line');
        modal_Container = document.getElementById('modal-Container-Delete');


        if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
            noteMinimise = document.getElementById('note-Test-Perm-Fix');
            noteMinimise.style.height = "72px";
        }

        if (window.innerWidth == 640 || window.innerWidth < 640) {
            addNoteStyleWidth = document.getElementById('modal-header-container-delete');
            addNoteStyleWidth.style.width = '100%'

        }


        setUpdateNotes("Update");
        setDeleteButtonNumber(deleteNumber);
        if (modal_Delete.style.display == "none") {
            modal_Delete.style.display = "flex";
            modal_Container.style.display = 'flex';

            setModalVisibility(modalState.SHOWN);
            disableButtons();

            // Runs through notes array to link note user wants to delete wiht data, stes the data in the note for easy deletion and viewing. 

            partnersUserNotes.map((function (notesArrayDelete: any) {
                if (notesArrayDelete.index == deleteNumber) {
                    setDeleteNoteReference(notesArrayDelete.note_Content);
                    modal_Delete_Line.value = notesArrayDelete.note_Content;
                    setDeleteDateReference(notesArrayDelete.note_Creation);
                    const myArray = notesArrayDelete.note_Location.split(",");
                    setDeleteDistrictReference(notesArrayDelete.note_District);
                    userDistrictIdArray.map(function (districts: any, index: any) {
                        if (districts == notesArrayDelete.note_District) {
                            setReadableSelectedArea(userDistrictNameArray[index])
                        }
                    })
                    setDeleteIDReference(notesArrayDelete.note_ID)
                    setDeleteLocationReferenceX(myArray[0]);
                    setDeleteLocationReferenceY(myArray[1].trim())
                }
            }))
        } else if (modal_Delete.style.display = "block") {
            modal_Delete.style.display = "none";
            modal_Container.style.display = 'none';
            setModalVisibility(modalState.HIDDEN);
            enableButtons();

            if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                extendedNotesArray.map(function (noteExtender: any) {
                    noteMinimise = document.getElementById(noteExtender);
                    noteMinimise.style.height = "auto";
                })
    
            }
        }
    }

    function deleteSSENNote(deleteNumber: any) { // Function to display delete modal to see what data the note contains before getting rid of it, called by pressing the button on user organisation note.
        modal_Delete = document.getElementById('modal-Delete');
        modal_Delete_Line = document.getElementById('note-Delete-Line');
        modal_Container = document.getElementById('modal-Container-Delete');


        if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
            noteMinimise = document.getElementById('note-Test-Perm-Fix');
            noteMinimise.style.height = "72px";
        }

        if (window.innerWidth == 640 || window.innerWidth < 640) {
            addNoteStyleWidth = document.getElementById('modal-header-container-delete');
            addNoteStyleWidth.style.width = '100%'

        }


        setUpdateNotes("Update");
        setDeleteButtonNumber(deleteNumber);
        if (modal_Delete.style.display == "none") {
            modal_Delete.style.display = "flex";
            modal_Container.style.display = 'flex';

            setModalVisibility(modalState.SHOWN);
            disableButtons();

            // Runs through notes array to link note user wants to delete wiht data, stes the data in the note for easy deletion and viewing. 

            SSENUserNotes.map((function (notesArrayDelete: any) {
                if (notesArrayDelete.index == deleteNumber) {
                    setDeleteNoteReference(notesArrayDelete.note_Content);
                    modal_Delete_Line.value = notesArrayDelete.note_Content;
                    setDeleteDateReference(notesArrayDelete.note_Creation);
                    const myArray = notesArrayDelete.note_Location.split(",");
                    setDeleteDistrictReference(notesArrayDelete.note_District);
                    userDistrictIdArray.map(function (districts: any, index: any) {
                        if (districts == notesArrayDelete.note_District) {
                            setReadableSelectedArea(userDistrictNameArray[index])
                        }
                    })
                    setDeleteIDReference(notesArrayDelete.note_ID)
                    setDeleteLocationReferenceX(myArray[0]);
                    setDeleteLocationReferenceY(myArray[1].trim())
                }
            }))
        } else if (modal_Delete.style.display = "block") {
            modal_Delete.style.display = "none";
            modal_Container.style.display = 'none';
            setModalVisibility(modalState.HIDDEN);
            enableButtons();

            if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                extendedNotesArray.map(function (noteExtender: any) {
                    noteMinimise = document.getElementById(noteExtender);
                    noteMinimise.style.height = "auto";
                })
    
            }
        }
    }


    function deleteNoteClick() { // Function for deleting note, called by user clicking delete button on "delete this note" modal. 
        modal_Delete = document.getElementById('modal-Delete');
        modal_Delete.style.display = "none";
        modal_Container = document.getElementById('modal-Container-Delete');
        modal_Container.style.display = 'none';

        setModalVisibility(modalState.HIDDEN);
        enableButtons();

        if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
            extendedNotesArray.map(function (noteExtender: any) {
                noteMinimise = document.getElementById(noteExtender);
                noteMinimise.style.height = "auto";
            })

        }

        // Creates path for where note should be deleted from as well as whcih note using note ID, also creates headings containing necessary auth 

        let pathNoteDeleteTest: any;
        let headersForDelete = new Headers();
        headersForDelete.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
        headersForDelete.append("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
        pathNoteDeleteTest = BASE_URL + "/gridim/rotaload/partners?action=deleteNote&id=" + deleteIDReference;

        // Fetch delete method, data to which note needs to be deleted is included in the path and no data is psoted in delete method itself, if fails an error is thrown, else notes need to be updated, type of note action needs to be set, and marker for that note needs to be deleted from the map.

        fetch(pathNoteDeleteTest, {
            method: 'DELETE',
            headers: headersForDelete,
        }).then(function (response) {
            if (!response.ok) {
                throw new Error("HTTP status " + response.status);
            } else {
                setUpdateNotes("Update");
                setTypeOfNoteAction("Delete Note");
                setDeleteMarker(deleteButtonNumber);
            }
        })
    }














    return (
        <>
            <div id='modal-Container-Delete' className='modal-Container' style={{ display: "none" }}>

                <div id="modal-Delete" className="modal-Delete" style={{ display: "none" }}>
                    <div className="modal_content">
                        <div className='note-Input'>
                            <IonCard className='add-Note-Style' id='modal-header-container-delete'><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={create} style={{ color: "white" }} />  Delete This Note</h1></IonCard>
                            <div className='note-Layout-Input'>
                                <div style={{ display: 'flex' }}>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Area:  {readableSelectedArea}</IonCardContent></IonCard>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Note Coordinates: {deleteLocationReferenceX}, {deleteLocationReferenceY} </IonCardContent></IonCard>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Partner: {resiliencePartner}</IonCardContent></IonCard>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Created:  {deleteDateReference}</IonCardContent></IonCard>
                                </div>
                                <IonTextarea placeholder="Enter text" className='note-Input-Line' id='note-Delete-Line' readonly={true}> {/*Review for RESILIENCE 142 */}
                                </ IonTextarea>
                                <div className='note-Button-Placement'>
                                    <IonButton className='note-Button-Red' shape="round" onClick={() => deletePublicNote(0)} >Cancel</IonButton>
                                    <IonButton className='note-Button-Green' shape="round" onClick={() => deleteNoteClick()} >Delete</IonButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}



export default DeleteNoteComponent
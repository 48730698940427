import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCheckbox, IonDatetime, IonDatetimeButton, IonIcon, IonLabel, IonModal, IonSelect, IonSelectOption, IonTextarea } from "@ionic/react";
import { useEffect } from "react";
import { BASE_URL } from '../../../mapContentComponent/mapContentComponent';
import { ACCESS_CREDENTIALS } from "../../../../pages/Home";
import './SaveNoteComponent.css';
import { closeCircleOutline, create } from "ionicons/icons";
import { SidebarState, modalState, mouseState } from "../../../../enums/enums";


type SaveNoteProps = {

    setUpdateNotes: any;


    noteMouseActive: any;
    setNoteMouseActive: any;
    setMouseAppearance: any;
    setModalVisibility: any;
    setNoteCancelled: any;
    setNoteLocationSelection: any;
    setOutsideOrInsideBoundry: any;
    outsideOrInsideBoundry: any;
    XCoordConfirmed: any;
    YCoordConfirmed: any;

    setLinkedToFault: any;
    linkedToFault: any;

    permNote: any;
    setPermNote: any;

    noteExpiryDate: any;
    setNoteExpiryDate: any;

    notesArray: any;
    setNotesArray: any;

    faultReferenceToBeSaved: any;
    setFaultReferenceToBeSaved: any;

    newNoteVisibility: any;
    selectedArea: any;
    lastSelectedArea: any;
    addNoteAllLocation: any;

    setTypeOfNoteAction: any;
    setNoteSaveCount: any;
    noteSaveCount: any;

    setSelectedArea: any;

    setAddMarkerToArray: any;

    resiliencePartner: any;

    faultArrayForSave: any;


    publicUserNotes: any;
    partnersUserNotes: any;
    SSENUserNotes: any;

    publicNonUserNotes: any;
    partnersNonUserNotes: any;
    SSENNonUserNotes: any;

    organisationArray: any;


    saveNoteButtonClick: any;
    setSaveNoteButtonClick: any;

    readableSelectedArea: any;
    setReadableSelectedArea: any;
    setSidebarExpanded: any;

}


const SaveNoteComponent: React.FC<SaveNoteProps> = ({
    setUpdateNotes, noteMouseActive, setNoteMouseActive, setMouseAppearance, setModalVisibility, setNoteCancelled, setNoteLocationSelection, setOutsideOrInsideBoundry,
    outsideOrInsideBoundry, XCoordConfirmed, YCoordConfirmed, setLinkedToFault, linkedToFault, permNote, setPermNote, noteExpiryDate, setNoteExpiryDate, notesArray,
    setNotesArray, faultReferenceToBeSaved, newNoteVisibility, selectedArea, lastSelectedArea, addNoteAllLocation, setTypeOfNoteAction, setNoteSaveCount, noteSaveCount,
    setSelectedArea, setAddMarkerToArray, resiliencePartner, setFaultReferenceToBeSaved, faultArrayForSave, publicUserNotes, partnersUserNotes, SSENUserNotes, publicNonUserNotes,
    partnersNonUserNotes, SSENNonUserNotes, organisationArray, saveNoteButtonClick, setSaveNoteButtonClick,
    readableSelectedArea,
    setReadableSelectedArea, setSidebarExpanded
}) => {

    let modal: any;

    let saved_Note_Text: any;
    let modal_OutOfBounds: any;


    let modal_Container: any;


    let buttonShow: any;
    let buttonEdit: any;
    let buttonDelete: any;


    let segmentActivation: any;
    let segmentActivation2: any;
    let segmentActivation3: any;
    let ionSelectActive: any;


    let noteExtendActivate: any;

    useEffect(() => {
        if (saveNoteButtonClick != 0) { // Use effect for note toggle coming on and affecting the sidebar by adding the notes
            handleClick();
            setSaveNoteButtonClick(0);
        }
    }, [saveNoteButtonClick])


    function enableButtons() { // Enables all of the sidebar buttons for use

        publicUserNotes.map((function (publicUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + publicUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + publicUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + publicUserNotes.index);
            buttonShow.disabled = false;
            buttonEdit.disabled = false;
            buttonDelete.disabled = false;
        }))

        partnersUserNotes.map((function (partnersUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + partnersUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + partnersUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + partnersUserNotes.index);
            buttonShow.disabled = false;
            buttonEdit.disabled = false;
            buttonDelete.disabled = false;
        }))

        SSENUserNotes.map((function (SSENUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + SSENUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + SSENUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + SSENUserNotes.index);
            buttonShow.disabled = false;
            buttonEdit.disabled = false;
            buttonDelete.disabled = false;
        }))



        publicNonUserNotes.map((function (publicNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + publicNonUserNotes.index);
            buttonShow.disabled = false;
        }))


        partnersNonUserNotes.map((function (partnersNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + partnersNonUserNotes.index);
            buttonShow.disabled = false;
        }))


        SSENNonUserNotes.map((function (SSENNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + SSENNonUserNotes.index);
            buttonShow.disabled = false;
        }))


        segmentActivation = document.getElementById('segment-Id-1');
        segmentActivation.disabled = false;
        segmentActivation2 = document.getElementById('segment-Id-2');
        segmentActivation2.disabled = false;
        segmentActivation3 = document.getElementById('segment-Id-3');
        segmentActivation3.disabled = false;

        ionSelectActive = document.getElementById('ion-select-ID');
        ionSelectActive.disabled = false;

        noteExtendActivate = document.getElementById('chevronDownCircle');
        noteExtendActivate.style.pointerEvents = 'all';

        organisationArray.map(function (organisation: any, index: number) {
            index++;
            noteExtendActivate = document.getElementById("chevronDownCircle" + index);
            noteExtendActivate.style.pointerEvents = 'all';
        })
    }



    function disableButtons() { //Disables all of the buttons on the sidebar, if we split up into smaller components this will need split up
        publicUserNotes.map((function (publicUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + publicUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + publicUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + publicUserNotes.index);
            buttonShow.disabled = true;
            buttonEdit.disabled = true;
            buttonDelete.disabled = true;
        }))

        partnersUserNotes.map((function (partnersUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + partnersUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + partnersUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + partnersUserNotes.index);
            buttonShow.disabled = true;
            buttonEdit.disabled = true;
            buttonDelete.disabled = true;
        }))

        SSENUserNotes.map((function (SSENUserNotes: any) {
            buttonShow = document.getElementById('Show-Button-' + SSENUserNotes.index);
            buttonEdit = document.getElementById('Edit-Button-' + SSENUserNotes.index);
            buttonDelete = document.getElementById('Delete-Button-' + SSENUserNotes.index);
            buttonShow.disabled = true;
            buttonEdit.disabled = true;
            buttonDelete.disabled = true;
        }))



        publicNonUserNotes.map((function (publicNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + publicNonUserNotes.index);
            buttonShow.disabled = true;
        }))


        partnersNonUserNotes.map((function (partnersNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + partnersNonUserNotes.index);
            buttonShow.disabled = true;
        }))


        SSENNonUserNotes.map((function (SSENNonUserNotes: any) {
            buttonShow = document.getElementById("Button-Show-NonUser-" + SSENNonUserNotes.index);
            buttonShow.disabled = true;
        }))



        segmentActivation = document.getElementById('segment-Id-1');
        segmentActivation.disabled = true;

        segmentActivation2 = document.getElementById('segment-Id-2');
        segmentActivation2.disabled = true;

        segmentActivation3 = document.getElementById('segment-Id-3');
        segmentActivation3.disabled = true;


        ionSelectActive = document.getElementById('ion-select-ID');
        ionSelectActive.disabled = true;

        noteExtendActivate = document.getElementById('chevronDownCircle');
        noteExtendActivate.style.pointerEvents = 'none';

        organisationArray.map(function (organisation: any, index: number) {
            index++;
            noteExtendActivate = document.getElementById("chevronDownCircle" + index);
            noteExtendActivate.style.pointerEvents = 'none';
        })
    }

    let clearFaultSelection: any;
    let CreateModalForPhone: any;
    let ntcrdbtncnter: any;
    let noteCardMiddle: any;
    let noteFaultWidth: any;
    let addNoteStyleWidth: any;
    let expireDateTimePosition: any;


    function handleClick() { // Is called when user confirms they want to add a note to their selected location. Brings up create note modal.
        modal = document.getElementById('modal');

        modal_Container = document.getElementById('modal-Container-Save');

        setUpdateNotes("Update");

        if (noteMouseActive === "Active") {
            setMouseAppearance(mouseState.NORMAL);
            setNoteMouseActive("Inactive");
            enableButtons();
        } else {
            if (modal.style.display == "flex") {
                setModalVisibility(modalState.HIDDEN);
                setMouseAppearance(mouseState.NORMAL);
                modal.style.display = "none"
                modal_Container.style.display = 'none';
                setNoteCancelled("Cancelled");
                enableButtons();
                clearFaultSelection = document.getElementById('reference-Select-ID');
                clearFaultSelection.el.value = ""
            } else {
                setNoteMouseActive("Active");
                setNoteLocationSelection("SET");
                setMouseAppearance(mouseState.MARKER);
                disableButtons();
                if (window.innerWidth == 640 || window.innerWidth < 640) {
                    setSidebarExpanded(SidebarState.COLLAPSED);
                    CreateModalForPhone = document.getElementById('create-Note-Bottom-Formatting');
                    CreateModalForPhone.style.flexDirection = 'column'
                    ntcrdbtncnter = document.getElementById('note-card-btn-container');
                    ntcrdbtncnter.style.width = '100%'
                    noteCardMiddle = document.getElementById('note-card-middle');
                    noteCardMiddle.style.width = '95%'
                    noteFaultWidth = document.getElementById('link-to-Fault-Reference');
                    noteFaultWidth.style.width = '94.5%'
                    addNoteStyleWidth = document.getElementById('modal-header-container');
                    addNoteStyleWidth.style.width = '100%'
                    expireDateTimePosition = document.getElementById('expire-DateTime-Position');
                    expireDateTimePosition.style.flexDirection = 'column'
                }
            }
        }


    }

    function OutOfBoundsClick() { // Is called when user tries to create a note outside of the boundries of their selected area, brings up modal informing user, closes with understood button in center. 
        modal_OutOfBounds = document.getElementById('modal-OutOfBounds');
        modal_OutOfBounds.style.display = "none";
        modal_Container = document.getElementById('modal-Container-Save');
        modal_Container.style.display = 'none';
        setModalVisibility(modalState.HIDDEN);
        setOutsideOrInsideBoundry("UNSET")
        setNoteMouseActive("Active");
        setNoteLocationSelection("SET");
        setMouseAppearance(mouseState.MARKER);
        disableButtons();
        setUpdateNotes("Update");

    }

    useEffect(() => { // useEffect that calls when outsideOrInsideBoundry changes, which happens if user clicks to add map outside their selected area. Brings up out of boundries modal to inform user.
        if (outsideOrInsideBoundry.toString() == "Outside") {
            modal_OutOfBounds = document.getElementById('modal-OutOfBounds');
            modal_OutOfBounds.style.display = "block";
            modal_Container = document.getElementById('modal-Container-Save');
            modal_Container.style.display = 'flex';
            setModalVisibility(modalState.SHOWN);
        }
    },
        [outsideOrInsideBoundry]
    )


    let permNoteCheckBox: any;
    let linkToFaultCheckBox: any;
    let textBoxClear: any;

    let defaultExpireTime: any;

    let noteMinimise: any;

    let modal_Container_Save: any; 

    useEffect(() => { // useEffect that runs when both XCoordConfirmed and YCoordConfirmed changes, which happens when user accepts clicked location for new note, brings up modal about creating a new note.  
        if (XCoordConfirmed != 0 && YCoordConfirmed != 0) {
            modal = document.getElementById('modal');
            modal_Container = document.getElementById('modal-Container-Save');
            modal.style.display = "flex";
            modal_Container.style.display = 'flex';

            if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                noteMinimise = document.getElementById('note-Test-Perm-Fix');
                noteMinimise.style.height = "72px";
            }

            setModalVisibility(modalState.SHOWN);
            const d = new Date();
            defaultExpireTime = (d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + "T" + d.getHours() + ":" + (d.getMinutes() + 5) + ":" + d.getSeconds() + "+01:00");
            time_Date_Selector = document.getElementById('datetime');
            time_Date_Selector.value = defaultExpireTime;
            //setUpdateNotes("Update");

            permNoteCheckBox = document.getElementById('perm-Checkbox');
            permNoteCheckBox.checked = false;

            linkToFaultCheckBox = document.getElementById('fault-Checkbox');
            linkToFaultCheckBox.checked = false;

            setPermNote(false);
            setLinkedToFault(false);

            textBoxClear = document.getElementById('note-Input-Line');
            textBoxClear.el.value = ""

            if (window.innerWidth == 640 || window.innerWidth < 640) {
                setSidebarExpanded(SidebarState.EXPANDED)
            }


            let portrait = window.matchMedia("(orientation: portrait)");

        
          
            portrait.addEventListener("change", function (e) {
              if (e.matches) {
                modal_Container_Save = document.getElementById('modal-Container-Save');
                modal_Container_Save.style.top = '0';
              } else {
                modal_Container_Save = document.getElementById('modal-Container-Save');
                modal_Container_Save.style.top = '-35px';
              }
            })
        }
    },
        [XCoordConfirmed, YCoordConfirmed]
    );



    let perm_Checkbox: any;
    let fault_Checkbox: any;
    let datetime_Button: any;
    let link_to_Fault_Reference: any;
    let note_Card_Button_Conatiner: any;

    useEffect(() => {
        if (linkedToFault === true) {
            perm_Checkbox = document.getElementById('perm-Checkbox');
            perm_Checkbox.disabled = 'true';
            datetime_Button = document.getElementById('datetime-Button');
            datetime_Button.style.display = 'none';
            link_to_Fault_Reference = document.getElementById('link-to-Fault-Reference');
            link_to_Fault_Reference.style.display = 'flex';
            note_Card_Button_Conatiner = document.getElementById('note-card-btn-container');
            note_Card_Button_Conatiner.style.display = 'none';
        } else {
            perm_Checkbox = document.getElementById('perm-Checkbox');
            perm_Checkbox.disabled = 'false';
            datetime_Button = document.getElementById('datetime-Button');
            datetime_Button.style.display = 'flex';
            link_to_Fault_Reference = document.getElementById('link-to-Fault-Reference');
            link_to_Fault_Reference.style.display = 'none';
            note_Card_Button_Conatiner = document.getElementById('note-card-btn-container');
            note_Card_Button_Conatiner.style.display = 'flex';
        }

    }, [linkedToFault])

    useEffect(() => {
        if (permNote === true) {
            fault_Checkbox = document.getElementById('fault-Checkbox');
            fault_Checkbox.disabled = 'true';
            datetime_Button = document.getElementById('datetime-Button');
            datetime_Button.disabled = 'true';
        } else {
            fault_Checkbox = document.getElementById('fault-Checkbox');
            fault_Checkbox.disabled = 'false';
            datetime_Button = document.getElementById('datetime-Button');
            datetime_Button.disabled = 'false';
        }

    }, [permNote])


    let refernce_Line: any;
    let refernce_Dropdown: any;
    let time_Date_Selector: any;
    let PR_Note_User_Extend: any;


    function saveNote() { // Is called when user clicks publish button on "Create Note" modal, sends data entered into the modal to the backend, then sets update which activates code further down.
        saved_Note_Text = document.getElementById('note-Input-Line');

        refernce_Line = document.getElementById('note-Input-Line-Fault-Ref');

        if ((noteExpiryDate == "0" || noteExpiryDate == "") && linkedToFault == false && permNote == false) {
            alert("Please select a valid expiry date for this note.");
        } else if (noteExpiryDate != "0" && noteExpiryDate != "") {

            const date = noteExpiryDate.split("T");
            let time: any;
            let dateTimeTemp = date[1];
            if (dateTimeTemp.includes("+")) {
                time = dateTimeTemp.split("+")

            } else if (dateTimeTemp.includes("-")) {
                time = dateTimeTemp.split("-")
            }


            const YMD = date[0].split("-")
            const HMS = time[0].split(":")


            let dateTest: any;
            dateTest = new Date();

            let dateToEdit: any;
            dateToEdit = new Date(dateTest.getFullYear(), dateTest.getMonth(), dateTest.getDate(), dateTest.getHours(), (dateTest.getMinutes() + 1), dateTest.getSeconds());

            let dText: any;
            dText = new Date(Number(YMD[0]), (Number(YMD[1]) - 1), Number(YMD[2]), Number(HMS[0]), Number(HMS[1]), Number(HMS[2]));

            console.log(dText);

            let epochTimeDate = new Date(dText);

            console.log(epochTimeDate.getTime());

            time_Date_Selector = document.getElementById('datetime');

            if (dText.getTime() < dateToEdit.getTime() && linkedToFault == false && permNote == false) {
                alert("Please select a valid expiry date for this note.");
            } else if (saved_Note_Text.value.trim().length === 0) {
                alert("The note must have some text content.");
            } else {


                let tempNotesArray = [...notesArray];

                modal = document.getElementById('modal');
                PR_Note_User_Extend = document.getElementById('note-Test-Perm-Fix');
                PR_Note_User_Extend.style.height = 'auto';
                let note_Expiration_Date: any;
                let note_Linked_Fault: any;
                let tempDateHolderPermFault: any;

                if (permNote || linkedToFault) {
                    tempDateHolderPermFault = new Date("2100-10-19T10:12:00+01:00");
                  
                    note_Expiration_Date = tempDateHolderPermFault.getTime();

                } else {
                    note_Expiration_Date = epochTimeDate.getTime();
                }

                if (permNote || !linkedToFault) {
                    note_Linked_Fault = "No_Fault";
                } else {
                    note_Linked_Fault = faultReferenceToBeSaved
                }


                // Data inputed into modal is formatted below
                let note_locationX = XCoordConfirmed.toFixed(3);
                let note_locationY = YCoordConfirmed.toFixed(3);
                let note_Content = saved_Note_Text.value
                let note_Visibility = newNoteVisibility;
                let selectedAreaLocal: any;

                if (selectedArea != "All") {
                    selectedAreaLocal = lastSelectedArea;
                } else {
                    selectedAreaLocal = addNoteAllLocation;
                }


                // Creates path for data to be posted to, also creates headings containing necessary auth 
                let pathNoteSaveTest: any;
                pathNoteSaveTest = BASE_URL + "/gridim/rotaload/partners";
                let headersForSave = new Headers();
                headersForSave.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
                headersForSave.append("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");

                if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                    noteMinimise = document.getElementById('note-Test-Perm-Fix');
                    noteMinimise.style.height = "auto";
                }




                // var of data to be posted, record then encoded into formbody.
                var details = {
                    'action': 'addNote',
                    'text': note_Content,
                    'district': selectedAreaLocal,
                    'latitude': note_locationY,
                    'longitude': note_locationX,
                    'Expiration': note_Expiration_Date,
                    'faultRef': note_Linked_Fault,
                    'noteVisibility': note_Visibility,
                    'noteExpirationFlag': 'Not Expired'
                };
                const formBody = Object.entries(details).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&');

                // fetch method of POST that effectivly saves data conatined in formbody to designated path. If the POST action fails an error is thrown, if it doesn't fail sets values which causes local notes to update and what type of note action happend to change note highlighting. 

                fetch(pathNoteSaveTest, {
                    method: 'POST',
                    headers: headersForSave,
                    body: formBody
                })
                    .then(function (response) {
                        if (!response.ok) {
                            throw new Error("HTTP status " + response.status);
                        } else {
                            setUpdateNotes("Update");
                            setTypeOfNoteAction("Add Note");
                        }
                    }
                    )

                // Clears modal entry fields the disposes of modal, adds 1 to amount of notes saved this session, sets addmarkertoarray causing code further down to run and make marker official rather than temporary.

                setNoteSaveCount(noteSaveCount + 1);
                modal_Container = document.getElementById('modal-Container-Save');
                modal_Container.style.display = 'none';
                setModalVisibility(modalState.HIDDEN);
                enableButtons();
                saved_Note_Text.value = ""
                modal.style.display = "none";
                setSelectedArea(lastSelectedArea);
                setNotesArray(tempNotesArray);
                setAddMarkerToArray("Add");
                fault_Checkbox = document.getElementById('fault-Checkbox');
                fault_Checkbox.checked = false;
                refernce_Dropdown = document.getElementById('reference-Select-ID');

                refernce_Dropdown.value = "";
                const d = new Date();


                time_Date_Selector = document.getElementById('datetime');

                time_Date_Selector.value = "";

            }
        } else if ((faultReferenceToBeSaved === 0 || faultReferenceToBeSaved === "") && linkedToFault === true) {
            alert("The note must have a fault reference for linking.");
        } else if (faultReferenceToBeSaved != 0 && linkedToFault === true) {
            if (saved_Note_Text.value.trim().length === 0) {
                alert("The note must have some text content.");
            } else {
                let tempNotesArray = [...notesArray];

                modal = document.getElementById('modal');
                PR_Note_User_Extend = document.getElementById('note-Test-Perm-Fix');
                PR_Note_User_Extend.style.height = 'auto';
                let note_Expiration_Date: any;
                let note_Linked_Fault: any;

                if (permNote || linkedToFault) {
                    note_Expiration_Date = "2100-10-19T10:12:00+01:00";
                } else {
                    note_Expiration_Date = noteExpiryDate
                }

                if (permNote || !linkedToFault) {
                    note_Linked_Fault = "No_Fault";
                } else {
                    note_Linked_Fault = faultReferenceToBeSaved
                }


                // Data inputed into modal is formatted below
                let note_locationX = XCoordConfirmed.toFixed(3);
                let note_locationY = YCoordConfirmed.toFixed(3);
                let note_Content = saved_Note_Text.value
                let note_Visibility = newNoteVisibility;
                let selectedAreaLocal: any;

                if (selectedArea != "All") {
                    selectedAreaLocal = lastSelectedArea;
                } else {
                    selectedAreaLocal = addNoteAllLocation;
                }


                // Creates path for data to be posted to, also creates headings containing necessary auth 
                let pathNoteSaveTest: any;
                pathNoteSaveTest = BASE_URL + "/gridim/rotaload/partners";
                let headersForSave = new Headers();
                headersForSave.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
                headersForSave.append("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");

                if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                    noteMinimise = document.getElementById('note-Test-Perm-Fix');
                    noteMinimise.style.height = "auto";
                }




                // var of data to be posted, record then encoded into formbody.
                var details = {
                    'action': 'addNote',
                    'text': note_Content,
                    'district': selectedAreaLocal,
                    'latitude': note_locationY,
                    'longitude': note_locationX,
                    'Expiration': note_Expiration_Date,
                    'faultRef': note_Linked_Fault,
                    'noteVisibility': note_Visibility,
                    'noteExpirationFlag': 'Not Expired'
                };
                const formBody = Object.entries(details).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&');

                // fetch method of POST that effectivly saves data conatined in formbody to designated path. If the POST action fails an error is thrown, if it doesn't fail sets values which causes local notes to update and what type of note action happend to change note highlighting. 

                fetch(pathNoteSaveTest, {
                    method: 'POST',
                    headers: headersForSave,
                    body: formBody
                })
                    .then(function (response) {
                        if (!response.ok) {
                            throw new Error("HTTP status " + response.status);
                        } else {
                            setUpdateNotes("Update");
                            setTypeOfNoteAction("Add Note");
                        }
                    }
                    )

                // Clears modal entry fields the disposes of modal, adds 1 to amount of notes saved this session, sets addmarkertoarray causing code further down to run and make marker official rather than temporary.

                setNoteSaveCount(noteSaveCount + 1);
                modal_Container = document.getElementById('modal-Container-Save');
                modal_Container.style.display = 'none';
                setModalVisibility(modalState.HIDDEN);
                enableButtons();
                saved_Note_Text.value = ""
                modal.style.display = "none";
                setSelectedArea(lastSelectedArea);
                setNotesArray(tempNotesArray);
                setAddMarkerToArray("Add");
                fault_Checkbox = document.getElementById('fault-Checkbox');
                fault_Checkbox.checked = false;
                refernce_Dropdown = document.getElementById('reference-Select-ID');

                refernce_Dropdown.value = "";
                const d = new Date();


                time_Date_Selector = document.getElementById('datetime');

                time_Date_Selector.value = "";
            }
        } else if (permNote == true) {
            if (saved_Note_Text.value.trim().length === 0) {
                alert("The note must have some text content.");
            } else {
                let tempNotesArray = [...notesArray];

                modal = document.getElementById('modal');
                PR_Note_User_Extend = document.getElementById('note-Test-Perm-Fix');
                PR_Note_User_Extend.style.height = 'auto';
                let note_Expiration_Date: any;
                let note_Linked_Fault: any;

                if (permNote || linkedToFault) {
                    note_Expiration_Date = "2100-10-19T10:12:00+01:00";
                } else {
                    note_Expiration_Date = noteExpiryDate
                }

                if (permNote || !linkedToFault) {
                    note_Linked_Fault = "No_Fault";
                } else {
                    note_Linked_Fault = faultReferenceToBeSaved
                }


                // Data inputed into modal is formatted below
                let note_locationX = XCoordConfirmed.toFixed(3);
                let note_locationY = YCoordConfirmed.toFixed(3);
                let note_Content = saved_Note_Text.value
                let note_Visibility = newNoteVisibility;
                let selectedAreaLocal: any;

                if (selectedArea != "All") {
                    selectedAreaLocal = lastSelectedArea;
                } else {
                    selectedAreaLocal = addNoteAllLocation;
                }


                // Creates path for data to be posted to, also creates headings containing necessary auth 
                let pathNoteSaveTest: any;
                pathNoteSaveTest = BASE_URL + "/gridim/rotaload/partners";
                let headersForSave = new Headers();
                headersForSave.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
                headersForSave.append("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");


                if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
                    noteMinimise = document.getElementById('note-Test-Perm-Fix');
                    noteMinimise.style.height = "auto";
                }

                // var of data to be posted, record then encoded into formbody.
                var details = {
                    'action': 'addNote',
                    'text': note_Content,
                    'district': selectedAreaLocal,
                    'latitude': note_locationY,
                    'longitude': note_locationX,
                    'Expiration': note_Expiration_Date,
                    'faultRef': note_Linked_Fault,
                    'noteVisibility': note_Visibility,
                    'noteExpirationFlag': 'Not Expired'
                };
                const formBody = Object.entries(details).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&');

                // fetch method of POST that effectivly saves data conatined in formbody to designated path. If the POST action fails an error is thrown, if it doesn't fail sets values which causes local notes to update and what type of note action happend to change note highlighting. 

                fetch(pathNoteSaveTest, {
                    method: 'POST',
                    headers: headersForSave,
                    body: formBody
                })
                    .then(function (response) {
                        if (!response.ok) {
                            throw new Error("HTTP status " + response.status);
                        } else {
                            setUpdateNotes("Update");
                            setTypeOfNoteAction("Add Note");
                        }
                    }
                    )

                // Clears modal entry fields the disposes of modal, adds 1 to amount of notes saved this session, sets addmarkertoarray causing code further down to run and make marker official rather than temporary.

                setNoteSaveCount(noteSaveCount + 1);
                modal_Container = document.getElementById('modal-Container-Save');
                modal_Container.style.display = 'none';
                setModalVisibility(modalState.HIDDEN);
                enableButtons();
                saved_Note_Text.value = ""
                modal.style.display = "none";
                setSelectedArea(lastSelectedArea);
                setNotesArray(tempNotesArray);
                setAddMarkerToArray("Add");
                fault_Checkbox = document.getElementById('fault-Checkbox');
                fault_Checkbox.checked = false;
                refernce_Dropdown = document.getElementById('reference-Select-ID');

                refernce_Dropdown.value = "";
                const d = new Date();


                time_Date_Selector = document.getElementById('datetime');

                time_Date_Selector.value = "";
            }
        }


    };









    return (
        <>
            <div id='modal-Container-Save' className='modal-Container-Save' style={{ display: "none", top: "0" }}>
                <div id="modal" className="modal-Create" style={{ display: "none" }}>
                    <div className="modal_content">
                        <div className='note-Input'>
                            <IonCard className='add-Note-Style' id='modal-header-container'><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={create} style={{ color: "white" }} />  Create Note</h1></IonCard>
                            <div className='note-Layout-Input'>
                                <div style={{ display: 'flex' }}>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Area: {readableSelectedArea}</IonCardContent></IonCard>
                                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Note Coordinates: {XCoordConfirmed.valueOf().toFixed(3)}, {YCoordConfirmed.valueOf().toFixed(3)} </IonCardContent></IonCard>
                                </div>

                                <div id="create-Note-Bottom-Formatting" style={{ display: 'flex', alignItems: 'center' }}>

                                    <IonCard className='note-Card-Style-Small' id="note-card-middle">
                                        <IonCardContent className='note-Card-Content'>Resilience Partner: {resiliencePartner}</IonCardContent>
                                    </IonCard>



                                    <div className='note-card-btn-container' id="note-card-btn-container">
                                        {/* <span className="tooltiptext">Expiry Date/Time</span> */}

                                        <IonCard className='note-Card-Style-Small-Datetime-Button' id="datetime-Button">

                                            <IonCardContent className='note-Card-Content' id='expire-DateTime-Position'>
                                                <label>Expiry Date/Time:</label>

                                                <IonDatetimeButton datetime="datetime"  >
                                                </IonDatetimeButton>

                                            </IonCardContent>
                                        </IonCard>
                                    </div>


                                    <IonModal keepContentsMounted={true} style={{ "--border-radius": '25px' }}>
                                        <IonDatetime showDefaultButtons={true} id="datetime" color={"light"} style={{ "--background": 'var(--Brand-Colour-Primary)' }} onIonChange={e => setNoteExpiryDate(e.target.value)} ></IonDatetime>
                                    </IonModal>

                                    <IonCard className='note-Card-Style-Small' id='link-to-Fault-Reference' style={{ display: 'none' }}  >
                                        <IonCardContent className='note-Card-Content'>
                                            <IonSelect id="reference-Select-ID" onIonChange={(e: any) => setFaultReferenceToBeSaved(e.detail.value)} interface="popover" placeholder="Please select a fault reference">
                                                {
                                                    faultArrayForSave.map(function (faults: any) {
                                                        return <IonSelectOption value={faults}>{faults}</IonSelectOption>
                                                    })
                                                }
                                            </IonSelect>



                                        </IonCardContent>
                                    </IonCard>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                                        <IonLabel className="perm-and-fault-Label">Link to Fault</IonLabel> {/*Review for RESILIENCE 137 */}
                                        <IonCheckbox id="fault-Checkbox" onIonChange={(e: any) => setLinkedToFault(e.detail.checked)}></IonCheckbox>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                                        <IonLabel className="perm-and-fault-Label">Make Permanent</IonLabel> {/*Review for RESILIENCE 137 */}
                                        <IonCheckbox id="perm-Checkbox" onIonChange={(e: any) => setPermNote(e.detail.checked)}></IonCheckbox>
                                    </div>

                                </div>
                                <IonTextarea placeholder="Enter note here..." required={true} className='note-Input-Line' id='note-Input-Line' ></ IonTextarea> {/*Review for RESILIENCE 142 */}
                                <div className='note-Button-Placement'>
                                    <IonButton className='note-Button-Red' shape="round" onClick={() => handleClick()}>Cancel</IonButton>
                                    <IonButton className='note-Button-Green' shape="round" onClick={() => saveNote()}>Publish</IonButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-OutOfBounds" className="modal-OutOfBounds" style={{ display: "none" }}> {/* Need to improve how this looks, is functional but looks a bit scruffy, maybe also move where the modal pops up? */}
                    <div className="modal_content">
                        <div className='note-Input'>
                            <IonCard className='Out_Of_Bounds_Card'><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={closeCircleOutline} style={{ color: "red" }} /> Out Of Bounds</h1></IonCard>

                        </div>
                        <div className='note-Delete-Edit-Button-Placement' style={{ justifyContent: 'center' }}>
                            <IonButton className='note-Button-Understood' shape="round" onClick={() => OutOfBoundsClick()}>Understood</IonButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}



export default SaveNoteComponent